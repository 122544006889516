import React from "react";

export const Location = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="20" viewBox="0 0 13 20" fill="none">
                <path d="M6.4823 0C2.92567 0 0.046875 2.88677 0.046875 6.43542C0.046875 9.99205 2.93365 16.4833 6.4823 16.4833C10.0309 16.4833 12.9177 9.99205 12.9177 6.43542C12.9177 2.88677 10.0389 0 6.4823 0ZM6.4823 9.77674C4.64019 9.77674 3.14897 8.28547 3.14897 6.44336C3.14897 4.60125 4.64019 3.11002 6.4823 3.11002C8.32441 3.11002 9.81563 4.60125 9.81563 6.44336C9.81563 8.28547 8.32441 9.77674 6.4823 9.77674Z" fill="#161A1D" />
                <path d="M6.4823 20.0005C10.0365 20.0005 12.9177 19.7256 12.9177 19.3865C12.9177 19.0474 10.0365 18.7725 6.4823 18.7725C2.92812 18.7725 0.046875 19.0474 0.046875 19.3865C0.046875 19.7256 2.92812 20.0005 6.4823 20.0005Z" fill="#161A1D" />
            </svg>
        </>
    );
};