import styled from "styled-components";


export const ProductDetail = styled.div`
    .product-intro{
        @media screen and (max-width:991px){
            text-align:center;
        }
        h1{
            color: #161A1D;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            @media screen and (max-width:1199px){
                font-size:30px;
            }
        }
        h2{
            color: #161A1D;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }
        a{
            color: #D71921;
            font-style: normal;
            transition:0.5s;
            display:block;
            margin-top:20px;
            cursor:pointer;
            &:hover{
                color:#D71921;
                text-decoration:underline;
            }
        }
    }
    .price{
        @media screen and (max-width:991px){
            margin-top:30px;
        }
        p {
            color: #161A1D;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: right;
            @media screen and (max-width:991px){
                text-align:center;
            }
            span {
                display: block;
                text-align: right;
                font-size: 16px;
                padding-top:10px;
                @media screen and (max-width:991px){
                    text-align:center;
                }
            }
        }
    }
    .get-offer {
        text-align: right;
        padding-top: 20px;
        @media screen and (max-width:991px){
            text-align:center;
        }
        a {
            border-radius: 5px;
            background: #D71921;
            padding: 14px 32px;
            color: #FFF;
            text-align: center;
            font-weight: 700;
            line-height: 20px;
            cursor:pointer;
            &:hover{
                background:#AC141A;
                color:#fff;
            }
        }
    }
`;