import styled from 'styled-components';
export const FooterStyle = styled.footer`
.back-top-top {
    text-align: center;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    background: #FFF;
    p {
        margin-bottom: 0;
        color: #161A1D;
        text-align: center;
        letter-spacing: 0.583px;
        padding: 10px 0;
        cursor:pointer;
    }
}
    .footer-main {
        padding: 40px 0;
        h4 {
            color: #161A1D;
            font-size: 13.667px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            margin-bottom: 10px;
        }
        ul{
            li {
                a{
                color: #161A1D;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                margin-bottom: 10px;
                text-decoration:none;
                }
            }
        }
        .social-icon{
            padding: 8px 0;
            & > a:not(:last-child) svg{
                margin-right: 10px;
            }
        }
    }
    .footer-bottom {
        border-top: 1px solid #DEDEDE;
        padding: 30px 0 60px;
        text-align: center;
        p {
            margin-bottom: 0;
            color: #161A1D;
            text-align: center;
            font-size: 10px;
            line-height: 16px;
            font-weight:600;
        }
        .footer-bottom-links{
            ul {
                display: flex;
                justify-content: center;
                padding-top:20px;
                li {
                    a{
                        color: #161A1D;
                        font-size: 10px;
                        line-height: 16px;
                        text-decoration:none;
                        padding:0 10px;
                        font-weight:600;
                    }
                }
                &>li:not(:last-child) a{
                    border-right:1px solid #161A1D;
                    padding:0 10px;
                }
            }
        }
    }
    .for-mob{
        display:none;
        @media screen and (max-width:991px){
            display:block;
        }
        .footer-links{
            border-bottom: 1px solid #DEDEDE;
            h4{
                padding: 15px 8px;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                cursor:pointer;
                span {
                    transform: rotate(90deg);
                }
                &:hover{
                    color:#D71921;
                    background: #ECEDED;
                    svg{
                        path{
                            fill:#D71921;
                        }
                    }
                }
            }
            .accordion-content {
                padding: 0 8px 15px;
            }
            &.active{
                h4{
                    color:#D71921;
                    span {
                        transform: rotate(270deg);
                        svg{
                            path{
                                fill:#D71921;
                            }
                        }
                    }
                }
            }
        }
    }
}
   
`;