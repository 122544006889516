import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import styled from "styled-components";
import DefaultConfig from "../../../Utils/Config";
import interior1 from "../../../Assets/car.png";
import interior2 from "../../../Assets/car.png";
import Popup from "../../../Components/Popup";
import Image360Viewer from "../Image360Viewer";
import RightArrow from "../../../Assets/Vector.svg";
import Image360Exterior from "../Image360Exterior";
import Image360Interior from "../Image360Interior";
import Light from "../../../Assets/light";
import CompareIcon from "../../../Assets/Compare.svg";
import ZoomIcon from "../../../Assets/zoom.svg";
import BeforeAfterSlider from "../BeforeAfter";
import ThumbnailSlider from "../ThumbnailSlider";
import NightMode from "../../../Assets/night-mode.png";
const ProductSlider = ( {
  product,
  variantImage,
  selectedVariant,
  hasConfiguration,
} ) =>
{
  const [ isOpen, setIsOpen ] = useState( false );
  const [ isOpenBeforeAfter, setOpenBeforeAfter ] = useState( false );
  const [ DetailView, setDetailView ] = useState( false );
  const [ ThreeSixtyView, setThreeSixtyView ] = useState( false );
  const [ isNight, setIsNight ] = useState( false );

  const openPopup = () =>
  {
    setIsOpen( true );
  };

  const handleDetailView = ( value ) =>
  {
    setDetailView( value );
  };

  const handleThreeSixtyView = ( value ) =>
  {
    setThreeSixtyView( value );
  };
  const openBeforeAfterPopup = () =>
  {
    setOpenBeforeAfter( !isOpenBeforeAfter );
  };

  const exteriorThumbnails = variantImage?.exterior
    ? variantImage.exterior
      .sort( ( a, b ) => parseInt( a.id ) - parseInt( b.id ) )
      .map( ( item ) => `${ DefaultConfig.StorageUrl }${ item.image }` )
    : [];

  const interiorThumbnails = variantImage?.interior
    ? variantImage.interior
      .sort( ( a, b ) => parseInt( a.id ) - parseInt( b.id ) )
      .map( ( item ) => `${ DefaultConfig.StorageUrl }${ item.image }` )
    : [];

  const allThumbnails = exteriorThumbnails.concat( interiorThumbnails );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    prevArrow: null, // Hide previous arrow
    nextArrow: null, // Hide next arrow
    slidesToShow: 1,
    slidesToScroll: 1,

    appendDots: ( dots ) =>
    {
      const exteriorDots = dots.slice( 0, exteriorThumbnails.length );
      const interiorDots = dots.slice( -interiorThumbnails.length );

      return (
        <div>
          <div className="custom-pagination">
            { exteriorThumbnails.length > 0 ? (
              <div className="exterior">
                <strong>Exterior</strong>
                <ul>{ exteriorDots }</ul>
              </div>
            ) : null }
            { interiorThumbnails.length > 0 ? (
              <div className="interior">
                <strong>Interior</strong>
                <ul>{ interiorDots }</ul>
              </div>
            ) : null }
            <CompareWrapper
              onClick={ () =>
              {
                openPopup();
                handleDetailView( true );
              } }
            >
              <img src={ ZoomIcon } alt="zoom" />
            </CompareWrapper>
            <Button360
              className="360-view"
              onClick={ () =>
              {
                openPopup();
                handleDetailView( false );
              } }
            >
              360° view
            </Button360>
            { hasConfiguration ? (
              <CompareWrapper onClick={ () => openBeforeAfterPopup() }>
                <img src={ CompareIcon } alt="compare" />
              </CompareWrapper>
            ) : null }
          </div>
        </div>
      );
    },
    customPaging: ( i ) => <span></span>,
  };
  const handleButtonClick = () =>
  {
    // Toggle the class on each button click
    setIsNight( !isNight );
  };

  return (
    <SliderWrapper>
      { isOpenBeforeAfter ? (
        <BeforeAfterSlider
          openPopup={ openPopup }
          openBeforeAfterPopup={ openBeforeAfterPopup }
          isOpenBeforeAfter={ isOpenBeforeAfter }
        />
      ) : (
        <Slider { ...settings }>
          { allThumbnails.map( ( thumbnail, index ) => (
            <ThumbnailWrapper key={ index } className="thumbnail-wrapper">
              <ThumbnailImage
                src={ thumbnail }
                alt={ `Thumbnail ${ index }` }
                className="thumbnail-image"
              />
            </ThumbnailWrapper>
          ) ) }
        </Slider>
      ) }

      { isOpen && (
        <Popup
          isOpen={ isOpen }
          onClose={ () => setIsOpen( false ) }
          popupContentStyle={ {
            width: "100%",
            height: "100vh",
            padding: "0 20px 20px",
            maxWidth: "100%",
            margin: "0 auto",
            borderRadius: "0",
          } }
          closeButtonStyle={ {
            padding: "5px 10px",
            border: "none",
            borderRadius: "3px",
            background: "transparent",
            zIndex: "9",
            /* float: right; */
            position: "relative",
            top: "30px",
            right: "0",
            textAlign: "right",
            width: "100%",
          } }
          customClass={ isNight ? "night-light" : "day-light" }
        >
          <h3 className="mb-4">Your Build</h3>

          <div className="detail-view">
            <span
              onClick={ () => handleDetailView( true ) }
              className={ DetailView ? "active" : null }
            >
              Detail Car
            </span>
            <span
              onClick={ () => handleDetailView( false ) }
              className={ !DetailView ? "active" : null }
            >
              360° view
            </span>
          </div>
          { DetailView ? (
            <ThumbnailSlider product={ product } variantImage={ variantImage } />
          ) : (
            <>
              { ThreeSixtyView ? (
                <Image360Exterior product={ product } />
              ) : (
                <Image360Interior product={ product } />
              ) }
              <BottomOptions>
                <span className="light-active" onClick={ handleButtonClick }>
                  <Light />
                </span>
                <div className="ext-int">
                  <span
                    onClick={ () => handleThreeSixtyView( true ) }
                    className={ ThreeSixtyView ? "active" : null }
                  >
                    Exterior
                  </span>
                  <span
                    onClick={ () => handleThreeSixtyView( false ) }
                    className={ !ThreeSixtyView ? "active" : null }
                  >
                    Interior
                  </span>
                </div>
              </BottomOptions>
            </>
          ) }
        </Popup>
      ) }
    </SliderWrapper>
  );
};

const ThumbnailWrapper = styled.div`
  text-align: center;
`;

const Button360 = styled.button`
  border-radius: 100px;
  border: 1px solid #d0d1d2;
  color: #161a1d;
  font-size: 14px;
  padding: 3px 10px;
  margin-left: 5px;
  background: transparent;
  &:hover {
    color: #d71921;
    border-color: #d71921;
  }
`;

const ThumbnailImage = styled.img`
  max-width: 100%;
  height: 400px;
  margin: 0 auto;
  object-fit: contain;
  @media screen and (max-width: 767px) {
    max-width: 75%;
    height: 200px;
  }
`;

const SliderWrapper = styled.div`
  .cDmcCT .carousel .slide img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .slick-arrow:before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url(${ RightArrow }) no-repeat center center;
    border: 1px solid #161a1d;
    padding: 10px;
    border-radius: 50%;
    @media screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
    }
  }
  .slick-arrow:hover:before {
    filter: invert(7%) sepia(65%) saturate(5943%) hue-rotate(354deg)
      brightness(107%) contrast(157%);
  }
  .slick-prev:before {
    background: url(${ RightArrow }) no-repeat center center;
    transform: rotate(180deg);
  }
  .slick-prev {
    left: -55px;
    @media screen and (max-width: 991px) {
      left: 0;
    }
  }
  .slick-next {
    @media screen and (max-width: 991px) {
      right: 25px;
    }
  }
  .slick-dots {
    position: relative;
    bottom: unset;
    text-align: center;
    .custom-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
      .interior,
      .exterior {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 10px;
        }
        strong {
          color: #161a1d;
          font-family: "ToyotaType";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 3px;
        }

        ul {
          padding: 0;
          list-style-type: none;
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            width: auto;
            height: auto;
            margin: 0 3px;
            span {
              display: inline-block;
              position: relative;
              padding: 0;
              cursor: pointer;
              width: 12px;
              height: 12px;
              flex-shrink: 0;
              border-radius: 100px;
              background: #d9dadb;
              border: 2px solid transparent;
              @media screen and (max-width: 575px) {
                width: 8px;
                height: 8px;
              }
            }
            &.slick-active span {
              border-radius: 100px;
              border: 2px solid #d71921;
            }
          }
        }
      }
    }
  }
  .detail-view {
    display: flex;
    justify-content: center;
    padding: 10px 5px;
    border-radius: 100px;
    background: #f8f8f8;
    width: 100%;
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
    span {
      border-radius: 100px;
      color: #161a1d;
      font-size: 16px;
      padding: 10px 20px;
      cursor: pointer;
      margin: 0 3px;
      background: #fafafa;
      &:hover {
        color: #d71921;
      }
      &.active {
        background: #161a1d;
        color: #fff;
      }
    }
  }
  .popup {
    .close-btn {
      svg {
        border-radius: 8px;
        background: #fff;
        width: 30px;
        height: 30px;
        padding: 9px;
      }
    }
  }
  .popup.night-light {
    .popup-content {
      height: 100%;
      //background: radial-gradient(94.28% 93.23% at 58.57% 41.88%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 65.1%), #FFF;
      background: url(${ NightMode });
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .light-active {
      svg {
        background: #000;
        border-radius: 50%;
        outline: 5px solid #fff;
        path {
          fill: #fff;
        }
      }
    }
    .popup-content-inner {
      & > h3 {
        color: #fff;
      }
    }
  }
`;

const BottomOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 30px;
  @media screen and (max-width: 767px) {
    padding: 20px 0 30px;
  }
  span {
    cursor: pointer;
    svg {
      &:hover {
        path {
          fill: #d71921;
        }
      }
    }
  }
  .ext-int {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    padding: 6px 5px;
    border-radius: 100px;
    background: #f8f8f8;
    text-align: center;
    span {
      padding: 10px 20px;
      color: #161a1d;
      font-size: 16px;
      border-radius: 100px;
      &:hover {
        color: #d71921;
      }
      &.active {
        color: #fff;
        background: #161a1d;
      }
    }
  }
`;

const CompareWrapper = styled.div`
  position: relative;
  border-radius: 100px;
  border: 1px solid #d0d1d2;
  margin-left: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  &.active {
    background: red !important; /* Use !important to increase specificity */
    border-radius: 100px;
    border: 1px solid #d0d1d2;
    margin-left: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 24px;
    img {
      filter: invert(1);
    }
  }
  &:hover {
    color: #d71921;
    border-color: #d71921;
    filter: invert(7%) sepia(65%) saturate(5943%) hue-rotate(354deg)
      brightness(107%) contrast(157%);
  }
`;

export default ProductSlider;
