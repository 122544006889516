import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import DefaultConfig from "../../../../Utils/Config";

const Colors = ({
  product,
  handleSelectColor,
  selectedColor,
  selectedVariant,
}) => {
  const [colorsData, setColorsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${DefaultConfig.API_URL}/color?product=${product?.id}&variant=${selectedVariant?.id}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );

        setColorsData(response.data.data);
        if (selectedColor == "") {
          handleSelectColor(
            response.data.data.length > 0 ? response.data.data[0] : []
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [product, selectedVariant]);

  return (
    <ColorsWrapper className="color-wrapper">
      <div className="color-info">
        <p>{selectedColor.name}</p>
      </div>
      <ul>
        {colorsData.map((color, index) => (
          <li
            className={selectedColor.id === color.id ? "active" : ""}
            key={index}
            onClick={() => handleSelectColor(color)}
          >
            <span style={{ background: color.color }}></span>
          </li>
        ))}
      </ul>
      <div className="color-content">
        <p>
          *Pilihan warna dapat sedikit berbeda dengan warna mobil sesungguhnya.
        </p>
        <p>
          *Waktu inden minimal 3 bulan (perkiraan waktu bisa berubah tergantung
          dengan keadaan pabrik).
        </p>
      </div>
    </ColorsWrapper>
  );
};
const ColorsWrapper = styled.div`
padding: 30px 0;
p {
    text-align: center;
    color: #161A1D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    span {
        color: #D71921;
    }
}
ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
        width: 100px;
        height: 100px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.60);
        margin: 10px 5px;
        &.active{
            border: 2px solid #D71921;
        }
        span {
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }
}
.color-content{\
  padding:40px 0;
  p{
  color: #161A1D;
  text-align: center;
  font-size: 14px; 
  margin-bottom:0;
  }
}

`;
export default Colors;
