import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import DefaultConfig from "../../../../Utils/Config";
import styled from "styled-components";
import { formatAmount } from "../../../../Utils/Global";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RightArrow from "../../../../Assets/Vector.svg";
import Popup from "../../../../Components/Popup";
import RightArrowWhite from "../../../../Assets/Vector-white.svg";

const Accessory = ({
  product,
  selectedVariant,
  selectedColor,
  selectedPackages,
  handleSelectPackage,
  selectedCustoms,
  handleSelectCustom,
}) => {
  const [accessoryData, setAccessoryData] = useState(null);
  const [packageData, setPackageData] = useState(null);
  // const [Packageselected, setPackageSelected] = useState([]);
  const [Customselected, setCustomSelected] = useState([]);
  const [popupAccessoryPackage, setPopupAccessoryPackage] = useState([]);

  const [selectedImageIndex, setSelectedImageIndex] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = (accessoryPackage) => {
    setIsOpen(accessoryPackage.id);
    setPopupAccessoryPackage(accessoryPackage);
  };

  const handleCarouselChange = (index, packageIndex) => {
    const newSelectedImageIndex = [...selectedImageIndex];
    newSelectedImageIndex[packageIndex] = index;
    setSelectedImageIndex(newSelectedImageIndex);
  };

  // const handleClick = (id) => {
  //     const index = Packageselected.indexOf(id);
  //     let updatedSelection = [];

  //     if (index !== -1) {
  //         // If the ID is already selected, remove it
  //         updatedSelection = Packageselected.filter(itemId => itemId !== id);
  //     } else {
  //         // If the ID is not selected, add it to the selection
  //         updatedSelection = [...Packageselected, id];
  //     }

  //     setPackageSelected(updatedSelection);
  // };

  // const handleCustomClick = (id) => {
  //     const index = Customselected.indexOf(id);
  //     let updatedSelection = [];

  //     if (index !== -1) {
  //         // If the ID is already selected, remove it
  //         updatedSelection = Customselected.filter(itemId => itemId !== id);
  //     } else {
  //         // If the ID is not selected, add it to the selection
  //         updatedSelection = [...Customselected, id];
  //     }

  //     setCustomSelected(updatedSelection);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${DefaultConfig.API_URL}/acc?product=${product?.id}&color=${selectedVariant?.id}&variant=${selectedColor?.id}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );
        setAccessoryData(response.data.data);
      } catch (error) {
        console.error("Error fetching accessory data:", error);
      }
    };

    const fetchPackageData = async () => {
      try {
        const response = await axios.get(
          `${DefaultConfig.API_URL}/package?product=${product?.id}&color=${selectedVariant?.id}&variant=${selectedColor?.id}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );
        setPackageData(response.data.data);
      } catch (error) {
        console.error("Error fetching accessory data:", error);
      }
    };

    fetchData();
    fetchPackageData();
  }, []);

  const isMobile = window.innerWidth <= 991;
  const settings = {
    showStatus: false, // hide dots
    showThumbs: false, // hide dots
    showArrows: true, // show arrows
    infiniteLoop: true, // enable infinite loop
    useKeyboardArrows: true, // enable keyboard navigation
    emulateTouch: true, // enable touch swipe
    swipeable: true, // enable swipe
    dynamicHeight: false, // maintain the height of the carousel items
    centerMode: false, // center the current item
    centerSlidePercentage: 100, // percentage width of the centered slide (if centerMode is true)
    interval: 5000, // time between automatic transitions (set to 0 to disable auto-play)
    transitionTime: 500, // time it takes to slide to the next item (in milliseconds)
  };

  return (
    <ConfigurationWrapper>
      {packageData?.length > 0 ? <h2>Package</h2> : ""}
      {isOpen && (
        <Popup
          isOpen={isOpen === popupAccessoryPackage.id}
          onClose={() => setIsOpen(false)}
          popupContentStyle={{
            width: "100%",
            height: "auto",
            padding: "0 20px 20px",
            maxWidth: "800px",
            borderRadius: "10px",
          }}
          closeButtonStyle={{
            padding: "5px 10px",
            border: "none",
            borderRadius: "3px",
            background: "transparent",
            zIndex: "9",
            /* float: right; */
            position: "relative",
            top: "30px",
            right: "0",
            textAlign: "right",
            width: "100%",
          }}
        >
          <div className="variant-popup">
            <h3>{popupAccessoryPackage.name} Detail</h3>
            <Row>
              <Col xs={12} sm={8}>
                <Carousel
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={3000}
                  showThumbs={false}
                  swipeable={true}
                  emulateTouch={true}
                  selectedItem={
                    selectedImageIndex[popupAccessoryPackage.id] || 0
                  }
                  onChange={(index) =>
                    handleCarouselChange(index, popupAccessoryPackage.id)
                  }
                >
                  {popupAccessoryPackage?.accesories.map(
                    (accessory, accessoryIndex) => (
                      <img
                        key={accessoryIndex}
                        src={accessory?.img_used_url}
                        alt="image"
                        className="w-100"
                      />
                    )
                  )}
                </Carousel>
              </Col>
              <Col xs={12} sm={4}>
                <p className="start-from">
                  Rp {formatAmount(popupAccessoryPackage.price)}{" "}
                  <span>msrp</span>{" "}
                </p>
              </Col>
            </Row>
            <div className="highlight-feature">
              <p className="name">{popupAccessoryPackage.name}</p>
              <ul className="accesory-list">
                {popupAccessoryPackage?.accesories.map(
                  (accessory, accessoryIndex) => (
                    <li key={accessoryIndex}>{accessory.name}</li>
                  )
                )}
              </ul>
              <button
                className={`select-btn ${
                  selectedPackages.some(
                    (item) => item.id === popupAccessoryPackage.id
                  )
                    ? "active"
                    : ""
                }`}
                onClick={handleSelectPackage(popupAccessoryPackage)}
              >
                {selectedPackages.some(
                  (item) => item.id === popupAccessoryPackage.id
                ) ? (
                  <>Remove -</>
                ) : (
                  "Add +"
                )}
              </button>
            </div>
          </div>
        </Popup>
      )}
      {isMobile ? (
        <>
          <Carousel {...settings} className="mb-carousel">
            {packageData?.length > 0
              ? packageData.map((accessoryPackage, packageIndex) => (
                  <div key={packageIndex} className="mb-4">
                    <div
                      className={`variant-inner ${
                        selectedPackages.some(
                          (item) => item.id === accessoryPackage.id
                        )
                          ? "selected   "
                          : ""
                      }`}
                    >
                      <div className="variant-top">
                        <p className="numbers">
                          {packageIndex + 1}/{packageData?.length}
                        </p>
                      </div>
                      <Carousel
                        className="card-carousel"
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={3000}
                        showThumbs={false}
                        swipeable={true}
                        emulateTouch={true}
                        selectedItem={selectedImageIndex[packageIndex] || 0}
                        onChange={(index) =>
                          handleCarouselChange(index, packageIndex)
                        }
                      >
                        {accessoryPackage?.accesories.map(
                          (accessory, accessoryIndex) => (
                            <img
                              key={accessoryIndex}
                              src={accessory?.img_used_url}
                              alt="image"
                              className="w-100"
                            />
                          )
                        )}
                      </Carousel>

                      <div className="variant-info">
                        <p className="name">{accessoryPackage.name}</p>
                        <ul className="accesory-list">
                          {accessoryPackage?.accesories.map(
                            (accessory, accessoryIndex) => (
                              <li key={accessoryIndex}>{accessory.name}</li>
                            )
                          )}
                        </ul>
                        <div className="detail-price">
                          <p
                            className="view"
                            onClick={() => openPopup(accessoryPackage)}
                          >
                            View Details
                          </p>
                          <p className="start-from">
                            Rp {formatAmount(accessoryPackage.price)}{" "}
                            <span>msrp</span>{" "}
                          </p>
                        </div>
                        <button
                          className={`select-btn ${
                            selectedPackages.some(
                              (item) => item.id === accessoryPackage.id
                            )
                              ? "active"
                              : ""
                          }`}
                          onClick={handleSelectPackage(accessoryPackage)}
                        >
                          {selectedPackages.some(
                            (item) => item.id === accessoryPackage.id
                          ) ? (
                            <>Remove -</>
                          ) : (
                            "Add +"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </Carousel>
        </>
      ) : (
        <Row>
          {packageData?.length > 0
            ? packageData.map((accessoryPackage, packageIndex) => (
                <Col lg={4} key={packageIndex} className="mb-4">
                  <div
                    className={`variant-inner ${
                      selectedPackages.some(
                        (item) => item.id === accessoryPackage.id
                      )
                        ? "selected   "
                        : ""
                    }`}
                  >
                    <div className="variant-top">
                      <p className="numbers">
                        {packageIndex + 1}/{packageData?.length}
                      </p>
                    </div>
                    <Carousel
                      className="card-carousel"
                      infiniteLoop={true}
                      autoPlay={true}
                      interval={3000}
                      showThumbs={false}
                      swipeable={true}
                      emulateTouch={true}
                      selectedItem={selectedImageIndex[packageIndex] || 0}
                      onChange={(index) =>
                        handleCarouselChange(index, packageIndex)
                      }
                    >
                      {accessoryPackage?.accesories.map(
                        (accessory, accessoryIndex) => (
                          <img
                            key={accessoryIndex}
                            src={accessory?.img_used_url}
                            alt="image"
                            className="w-100"
                          />
                        )
                      )}
                    </Carousel>

                    <div className="variant-info">
                      <p className="name">{accessoryPackage.name}</p>
                      <ul className="accesory-list">
                        {accessoryPackage?.accesories.map(
                          (accessory, accessoryIndex) => (
                            <li key={accessoryIndex}>{accessory.name}</li>
                          )
                        )}
                      </ul>
                      <div className="detail-price">
                        <p
                          className="view"
                          onClick={() => openPopup(accessoryPackage)}
                        >
                          View Details
                        </p>
                        <p className="start-from">
                          Rp {formatAmount(accessoryPackage.price)}{" "}
                          <span>msrp</span>{" "}
                        </p>
                      </div>
                      <button
                        className={`select-btn ${
                          selectedPackages.some(
                            (item) => item.id === accessoryPackage.id
                          )
                            ? "active"
                            : ""
                        }`}
                        onClick={handleSelectPackage(accessoryPackage)}
                      >
                        {selectedPackages.some(
                          (item) => item.id === accessoryPackage.id
                        ) ? (
                          <>Remove -</>
                        ) : (
                          "Add +"
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              ))
            : null}
        </Row>
      )}
      {accessoryData?.length > 0 ? <h2>Custom</h2> : ""}
      {isMobile ? (
        <Carousel {...settings} className="custom mb-carousel">
          {accessoryData?.length > 0
            ? accessoryData
                ?.filter((accessory) => accessory?.hide === 0)
                ?.map((accessory, index) => (
                  <div key={index} className="mb-4">
                    <div
                      className={`variant-inner ${
                        selectedCustoms.some((item) => item.id === accessory.id)
                          ? "selected   "
                          : ""
                      }`}
                    >
                      <div className="variant-top">
                        <p className="numbers">
                          {index + 1}/{accessoryData?.length}
                        </p>
                      </div>
                      <div className="variant-img">
                        <img
                          src={accessory.img_used_url}
                          alt="image"
                          className="w-100"
                        />
                      </div>
                      <div className="variant-info">
                        <p className="name">{accessory.name}</p>
                        <p className="content">{accessory.desc}</p>
                        <div className="detail-price">
                          <p className="start-from">
                            +Rp {formatAmount(accessory.price)}{" "}
                            <span>msrp</span>{" "}
                          </p>
                        </div>
                        <button
                          className={`select-btn ${
                            selectedCustoms.some(
                              (item) => item.id === accessory.id
                            )
                              ? "active"
                              : ""
                          }`}
                          onClick={handleSelectCustom(accessory)}
                        >
                          {selectedCustoms.some(
                            (item) => item.id === accessory.id
                          ) ? (
                            <>Remove -</>
                          ) : (
                            "Add +"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
            : null}
        </Carousel>
      ) : (
        <Row className="custom">
          {accessoryData?.length > 0 ? (
            accessoryData
              ?.filter((accessory) => accessory?.hide === 0)
              ?.map((accessory, index) => (
                <Col lg={4} key={index} className="mb-4">
                  <div
                    className={`variant-inner ${
                      selectedCustoms.some((item) => item.id === accessory.id)
                        ? "selected   "
                        : ""
                    }`}
                  >
                    <div className="variant-top">
                      <p className="numbers">
                        {index + 1}/{accessoryData?.length}
                      </p>
                    </div>
                    <div className="variant-img">
                      <img
                        src={accessory.img_used_url}
                        alt="image"
                        className="w-100"
                      />
                    </div>
                    <div className="variant-info">
                      <p className="name">{accessory.name}</p>
                      <p className="content">{accessory.desc}</p>
                      <div className="detail-price">
                        <p className="start-from">
                          +Rp {formatAmount(accessory.price)} <span>msrp</span>{" "}
                        </p>
                      </div>
                      <button
                        className={`select-btn ${
                          selectedCustoms.some(
                            (item) => item.id === accessory.id
                          )
                            ? "active"
                            : ""
                        }`}
                        onClick={handleSelectCustom(accessory)}
                      >
                        {selectedCustoms.some(
                          (item) => item.id === accessory.id
                        ) ? (
                          <>Remove -</>
                        ) : (
                          "Add +"
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
              ))
          ) : (
            <div className="py-5">No accessories found</div>
          )}
        </Row>
      )}
    </ConfigurationWrapper>
  );
};

const ConfigurationWrapper = styled.div`
  .mb-carousel {
    .carousel {
      .slide {
        padding: 0 42px;
        text-align: left;
      }
      .control-dots {
        display: none;
      }
      .control-prev,
      .control-next {
        font-size: 20px;
        color: #3498db;
      }
      .thumbs-wrapper {
        display: none;
      }
      .carousel-status {
        display: none;
      }
      .control-arrow {
        opacity: 1;
      }
      .control-prev:before,
      .control-next:before {
        display: none;
      }
      .control-arrow {
        display: inline-block;
        width: 35px;
        height: 35px;
        background: url(${RightArrowWhite}) no-repeat center center !important;
        padding: 10px;
        border-radius: 50%;
        background-color: #161a1d !important;
        border: none;
        background-size: 10px;
        background-position: center center;
        position: absolute;
        top: 40%;
        border: 1px solid #161a1d;
      }
      .control-prev.control-arrow {
        background: url(${RightArrowWhite}) no-repeat center center !important;
        transform: rotate(180deg);
        background-color: #161a1d !important;
        background-size: 10px;
        background-position: center center;
      }
      .control-disabled.control-arrow {
        cursor: inherit;
        display: block;
      }
      &.carousel-slider .control-arrow:hover {
        background-color: #161a1d;
      }
    }
  }
  h2 {
    color: #161a1d;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    padding: 40px 0;
  }
  .variant-inner {
    border-radius: 10px;
    border: 2px solid transparent;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.6);
    padding: 20px 20px 80px 20px;
    height: 100%;
    transition: all 0.4s;
    position: relative;
    @media screen and (max-width: 575px) {
      padding: 10px 10px 80px 10px;
    }
    &.selected {
      border: 2px solid #d71921;
    }
    .variant-top {
      p.numbers {
        color: #a2a3a5;
        font-size: 12px;
        text-align: right;
        margin-bottom: 10px;
      }
    }
    .variant-img img {
      height: 250px;
      object-fit: cover;
    }
    .variant-info {
      p.name {
        color: #161a1d;
        font-size: 24px;
        margin: 0;
        padding: 20px 0;
      }
      p.content {
        color: #161a1d;
        font-size: 16px;
      }
      .detail-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        @media screen and (max-width: 575px) {
          display: block;
        }
        p.start-from {
          color: #161a1d;
          font-size: 16px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          span {
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            display: inline-block;
            margin-left: 2px;
          }
        }
        p.view {
          color: #d71921;
          font-size: 16px;
          text-decoration: none;
          display: inline-block;
          text-transform: capitalize;
          border-bottom: 1px solid transparent;
          margin-bottom: 0;
          cursor: pointer;
          &:hover {
            border-bottom: 1px solid #d71921;
          }
        }
      }
      ul.accesory-list {
        li {
          color: #161a1d;
          font-size: 16px;
          padding: 0px 10px 0px 20px;
          position: relative;
          margin-bottom: 5px;
          &:before {
            content: "";
            color: #d71921;
            position: absolute;
            left: 4px;
            top: 10px;
            font-size: 24px;
            width: 5px;
            height: 5px;
            background: #d71921;
            border-radius: 50%;
          }
        }
      }
      .select-btn {
        border-radius: 4px;
        border: 1px solid #161a1d;
        background: #fff;
        padding: 12px 12px 12px 12px;
        display: block;
        text-transform: capitalize;
        width: 100px;
        transition: width ease 0.7s;
        position: relative;
        display: flex;
        align-items: center;
        bottom: 30px;
        position: absolute;
        justify-content: center;
        &.active {
          width: 120px;
          color: #fff;
          background: linear-gradient(0deg, #d71921 0%, #d71921 100%), #fff;
          border-color: #d71921;
          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .card-carousel .carousel {
    .slide {
      padding: 0;
    }
    &:hover .control-arrow {
      opacity: 1;
    }
    .carousel-status {
      display: none;
    }
    li {
      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
    .control-dots {
      display: block;
      .dot {
        background: #d9dadb;
        opacity: 1 !important;
        width: 9px;
        height: 9px;
        &:hover {
          background: #d71921;
        }
        &.selected {
          border: 1px solid #d71921;
          background: #fff;
        }
      }
    }
    .control-arrow {
      opacity: 0;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    .control-arrow:before {
      content: "";
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url(${RightArrow}) no-repeat center center;
      padding: 10px;
      border-radius: 50%;
      background-color: #fff;
      border: none;
      background-size: 10px;
      background-position: center center;
    }
    .control-prev {
      left: -10px;
    }
    .control-next {
      right: -10px;
    }
    .control-prev.control-arrow:before {
      background: url(${RightArrow}) no-repeat center center;
      transform: rotate(180deg);
      background-color: #fff;
      border: none;
      background-size: 10px;
      background-position: center center;
    }
    .control-disabled.control-arrow {
      cursor: inherit;
      display: block;
    }
    .control-arrow:hover {
      background: none;
    }
  }
  .variant-popup {
    h3 {
      color: #161a1d;
      font-size: 18px;
      border-bottom: 1px solid #161a1d;
      padding-bottom: 15px;
      display: block;
      margin-bottom: 30px;
    }
    p.start-from {
      padding: 0px 0 10px;
      color: #161a1d;
      font-size: 24px !important;
      margin-bottom: 0;
      text-align: right;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end !important;
      span {
        color: #161a1d !important;
        font-size: 16px !important;
        display: block;
        padding-top: 5px;
      }
    }
    .slide img {
      height: 240px;
      padding-bottom: 40px;
    }
    .highlight-feature {
      padding: 30px 0;
      h4 {
        color: #161a1d;
        font-size: 22px;
        margin-bottom: 0;
      }
      p.name {
        padding-bottom: 0;
        color: #161a1d;
        font-size: 24px;
        margin: 0;
      }
      ul {
        padding: 15px 0 15px;
        li {
          padding: 0px 10px 0px 20px;
          width: 50%;
          display: inline-block;
          position: relative;
          margin-bottom: 5px;
          &:before {
            content: "";
            color: #d71921;
            position: absolute;
            left: 4px;
            top: 10px;
            font-size: 24px;
            width: 5px;
            height: 5px;
            background: #d71921;
            border-radius: 50%;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
    .carousel {
      .slide {
        padding: 0;
      }
      &:hover .control-arrow {
        opacity: 1;
      }
      .carousel-status {
        display: none;
      }
      li {
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
      }
      .control-dots {
        display: block;
        .dot {
          background: #d9dadb;
          opacity: 1 !important;
          width: 9px;
          height: 9px;
          &:hover {
            background: #d71921;
          }
          &.selected {
            border: 1px solid #d71921;
            background: #fff;
          }
        }
      }
      .control-arrow {
        opacity: 0;
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      .control-arrow:before {
        content: "";
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url(${RightArrow}) no-repeat center center;
        padding: 10px;
        border-radius: 50%;
        background-color: #fff;
        border: none;
        background-size: 10px;
        background-position: center center;
      }
      .control-prev {
        left: -10px;
      }
      .control-next {
        right: -10px;
      }
      .control-prev.control-arrow:before {
        background: url(${RightArrow}) no-repeat center center;
        transform: rotate(180deg);
        background-color: #fff;
        border: none;
        background-size: 10px;
        background-position: center center;
      }
      .control-disabled.control-arrow {
        cursor: inherit;
        display: block;
      }
      .control-arrow:hover {
        background: none;
      }
    }
    .select-btn {
      border-radius: 4px;
      border: 1px solid #161a1d;
      background: #fff;
      padding: 12px 12px 12px 12px;
      display: block;
      text-transform: capitalize;
      width: 100px;
      transition: width ease 0.7s;
      position: relative;
      display: flex;
      align-items: center;
      bottom: 0;
      position: unset;
      justify-content: center;
      &.active {
        width: 120px;
        color: #fff;
        background: linear-gradient(0deg, #d71921 0%, #d71921 100%), #fff;
        border-color: #d71921;
        svg {
          margin-left: 8px;
        }
      }
    }
  }
  .custom .detail-price {
    justify-content: flex-end !important;
  }
`;
export default Accessory;
