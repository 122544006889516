import { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../Components/Layout";
import Banner from "./Banner";
import CategoryTabs from "./CategoryTabs";
import SelectBox from "./SelectBox";
import Popup from "../../Components/Popup";
import Logo from "../../Assets/logo.png";
import styled from "styled-components";
import DefaultConfig from "../../Utils/Config";
import { Location } from "../../Assets/location";
import { Dealer } from "../../Assets/dealer";
import { CaretDown } from "../../Assets/caret-down";
import { auto } from "@popperjs/core";
import { useSelectedProducts } from "../../Utils/Global";
import Select from "react-select";

export const Home = () =>
{
  const { CreateCookie, GetCookie } = useSelectedProducts();
  const CookieDealer = GetCookie( "dealer" );
  const CookieCity = GetCookie( "city" );
  const [ isOpen, setIsOpen ] = useState( false );
  const [ isLocationOpen, setIsLocationOpen ] = useState( false );
  const [ isDealerOpen, setIsDealerOpen ] = useState( false );
  const [ selectedDealer, setSelectedDealer ] = useState( CookieDealer );
  const [ selectedLocation, setSelectedLocation ] = useState( CookieCity );
  const [ dealerData, setDealerData ] = useState( [] );
  const [ cityData, setCityData ] = useState( [] );

  const openPopup = () =>
  {
    setIsOpen( true );
  };

  const toggleDropdown = () =>
  {
    setIsLocationOpen( !isLocationOpen );
    setIsDealerOpen( false );
  };

  const handleItemClick = ( item ) =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get(
          `${ DefaultConfig.API_URL }/city/${ item.id }`,
          {
            headers: {
              Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
            },
          }
        );
        if ( !CookieDealer || !CookieCity )
        {
          setIsOpen( true );
        }
        setDealerData( { data: response.data.data.dealers } );
        setSelectedDealer( null );
      } catch ( error )
      {
        console.error( error );
      }
    };
    fetchData();

    setSelectedLocation( item );
    CreateCookie( "city", item );
    setIsLocationOpen( false );
  };

  const toggleDealerDropdown = () =>
  {
    setIsDealerOpen( !isDealerOpen );
  };

  const handleDealerClick = ( item ) =>
  {
    setSelectedDealer( item );
    CreateCookie( "dealer", item );
    setIsDealerOpen( false );
    setIsOpen( false );
  };

  useEffect( () =>
  {
    openPopup();
  }, [] );

  useEffect( () =>
  {
    const fetchData = async () =>
    {
      try
      {
        const params = {};

        if ( selectedLocation !== null )
        {
          params.city = selectedLocation.id;
        }

        const response = await axios.get( `${ DefaultConfig.API_URL }/dealer`, {
          params: params,
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        } );
        if ( !CookieDealer || !CookieCity )
        {
          setIsOpen( true );
        }
        setDealerData( response.data );
      } catch ( error )
      {
        console.error( error );
      }
    };

    fetchData();
  }, [] );

  useEffect( () =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get( `${ DefaultConfig.API_URL }/city`, {
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        } );

        setCityData( response.data );
      } catch ( error )
      {
        console.error( error );
      }
    };

    fetchData();
  }, [] );

  return (
    <>
      <Layout>
        <Banner />
        <SelectBoxes>
          <div className="select-wrap" style={ {
            display: 'flex',
            justifyContent: 'center', // Adjusted to center for tighter fit
            alignItems: 'center'
          } }>
            <div className="select-city" style={ {
              flex: 1,
              marginRight: '5px', // Add right margin to the city select
              padding: '10px 5px' // Reduced horizontal padding for tighter fit
            } }>              { cityData?.data?.length > 0 && (
              <Select
                options={ cityData?.data }
                value={ selectedLocation }
                onChange={ handleItemClick }
                placeholder="Select City"
                getOptionLabel={ ( option ) => option.name }
                getOptionValue={ ( option ) => option.id }
              />
            ) }
            </div>
            <div className="select-dealer" style={ {
              flex: 1,
              marginLeft: '5px', // Add left margin to the dealer select
              padding: '10px 5px' // Reduced horizontal padding for tighter fit
            } }>
              { dealerData?.data?.length > 0 && (
                <Select
                  options={ dealerData?.data }
                  value={ selectedDealer }
                  onChange={ handleDealerClick }
                  placeholder="Select Dealer"
                  getOptionLabel={ ( option ) => option.name }
                  getOptionValue={ ( option ) => option.id }
                />
              ) }
            </div>
          </div>
        </SelectBoxes>

        <SelectedDealer>
          <div className="dealer-name" onClick={ openPopup }>
            <Location />
            <p>{ selectedDealer ? selectedDealer.name : "Toyota Dealer" }</p>
          </div>
        </SelectedDealer>
        <CategoryTabs selectedDealer={ selectedDealer } />
      </Layout>

      <HomePopup>
        { isOpen && (
          <Popup
            isOpen={ isOpen }
            onClose={ () => setIsOpen( false ) }
            popupContentStyle={ {
              width: "100%",
              height: "auto",
              padding: "0 20px 20px",
              maxWidth: "450px",
              borderRadius: "10px",
              overflow: "auto",
            } }
            closeButtonStyle={ {
              padding: "5px 10px",
              border: "none",
              borderRadius: "3px",
              background: "transparent",
              zIndex: "9",
              position: "relative",
              top: "0",
              right: "-15px",
              textAlign: "right",
              width: "100%",
            } }
          >
            <div className="home-popup">
              <img
                src={ Logo }
                alt="Logo"
                onClick={ () =>
                {
                  window.location.href = `${ DefaultConfig.BASE_URL }`;
                } }
              />
              <h2>SELECT YOUR NEAREST DEALER</h2>
              <SelectBoxes>
                <div className="select-wrap">
                  { cityData?.data?.length > 0 && (
                    <div className="select-city">
                      <Select
                        options={ cityData?.data }
                        value={ selectedLocation }
                        onChange={ handleItemClick }
                        placeholder="Select City"
                        getOptionLabel={ ( option ) => option.name }
                        getOptionValue={ ( option ) => option.id }
                      />
                    </div>
                  ) }
                  { dealerData?.data?.length > 0 && (
                    <div className="select-dealer">
                      <Select
                        options={ dealerData?.data }
                        value={ selectedDealer }
                        onChange={ handleDealerClick }
                        placeholder="Select Dealer"
                        getOptionLabel={ ( option ) => option.name }
                        getOptionValue={ ( option ) => option.id }
                      />
                    </div>
                  ) }
                </div>
              </SelectBoxes>

            </div>
          </Popup>
        ) }
      </HomePopup>
    </>
  );
};

// Styled components
const HomePopup = styled.div`
  text-align: center;
  h2 {
    color: #161a1d;
    font-size: 24px;
    margin: 40px 0;
    @media screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
  .popup {
    background: rgba(0, 0, 0, 0.8) !important;
    .popup-content {
      overflow: visible !important;
      @media screen and (max-width: 991px) {
        height: auto !important;
      }
      .popup-content-inner {
        overflow: visible !important;
      }
    }
  }
`;

const SelectBoxes = styled.div`
  .select-wrap {
    display: block;
    .select-city {
      margin-right: auto;
    }
    & > div {
      max-width: 320px;
      width: 100%;
      margin: 10px auto;
    }
    .custom-dropdown {
      position: relative;
    }
    .dropdown-header {
      border-radius: 10px;
      border: 1px solid #737677;
      background: #fff;
      padding: 10px 15px;
      width: 100%;
      position: relative;
      cursor: pointer;
      text-align: left;
      color: #a2a3a5;
      & > svg {
        margin-right: 10px;
        margin-bottom: 5px;
        width: 20px;
        path {
          fill: #a2a3a5;
        }
      }
      &.open {
        border-radius: 10px 10px 0 0;
        .select-arrow svg {
          transform: rotate(180deg);
        }
      }
      &:hover {
        color: #eb0a1e;
        border-color: #eb0a1e;
        svg path {
          fill: #eb0a1e;
        }
      }
      & .select-arrow {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        svg {
          path {
            fill: #a2a3a5;
          }
        }
      }
    }
    .dropdown-list {
      border: 0px solid #161a1d;
      border-radius: 0 0 10px 10px;
      padding: 10px 0;
      margin-top: 0;
      box-shadow: 0 0 5px 0px #ddd;
      position: absolute;
      width: 100%;
      z-index: 9;
      background: #fff;
      max-height: 180px;
      overflow-y: auto;
      min-height: auto;
      li {
        padding: 10px;
        cursor: pointer;
        text-align: left;
        &:hover {
          color: #d71921;
          background: #eceded;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #eceded;
      }
    }
  }
`;

const SelectedDealer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .dealer-name {
    display: inine-block;
    padding-top: 40px;
    cursor: pointer;
    svg {
      path {
        fill: #d71921;
      }
    }
    p {
      margin-bottom: 0;
      padding-left: 15px;
      display: inline-block;
    }
  }
`;
