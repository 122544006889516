import { useCookies } from 'react-cookie';

export const useSelectedProducts = () => {
  const [cookies, setCookie] = useCookies(['selectedProducts']);

  const getSelectedProducts = () => {
    return cookies.selectedProducts || [];
  };
  const CreateCookie = (cookieName, value) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    setCookie(cookieName, value, { path: '/', expires: expirationDate });
  }

  const GetCookie = (cookieName) => {
    return cookies[cookieName] || false;
  }

  const updateSelectedProducts = (selectedProducts) => {
    // Set expiration date to 7 days from now
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
  
    // Set the cookie with an expiration date
    setCookie('selectedProducts', selectedProducts, { path: '/', expires: expirationDate });
  };

  const addProduct = (product) => {
    const selectedProducts = getSelectedProducts();
    const productExists = selectedProducts.some(
      (existingProduct) => existingProduct?.id === product?.id
    );

    if (!productExists) {
      const updatedProducts = [...selectedProducts, product];
      updateSelectedProducts(updatedProducts);
    }
  };

  const removeProduct = (productId) => {
    const selectedProducts = getSelectedProducts();
    const updatedProducts = selectedProducts.filter(
      (product) => product?.id !== productId
    );
    updateSelectedProducts(updatedProducts);
  };

  const updateProduct = (productId, updatedProduct) => {
    const selectedProducts = getSelectedProducts();
    const updatedProducts = selectedProducts.map((product) => {
      if (product?.id === productId) {
        // Merge updated values into existing product data
        return { ...product, ...updatedProduct.productData };
      }
      return product;
    });
    updateSelectedProducts(updatedProducts);
  };

  const getProductById = (productID) => {
    const selectedProducts = getSelectedProducts();
    return selectedProducts.find((product) => product?.id === productID);
  };

  return {
    getSelectedProducts,
    addProduct,
    removeProduct,
    updateProduct,
    getProductById,
    CreateCookie,
    GetCookie
  };
};

export const formatAmount = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};