import React, { useState, useEffect } from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DefaultConfig from "../../../Utils/Config";
import { formatAmount } from "../../../Utils/Global";
import { RightArrow } from "../../../Assets/right-arrow";
import { Category } from "./style";

const CategoryTabs = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading1, setLoading1] = useState(false);

  // Fetch categories and set the first tab as active by default
  useEffect(() => {
    setLoading1(true);
    axios
      .get(`${DefaultConfig.API_URL}/product/category`, {
        headers: {
          Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
        },
      })
      .then((response) => {
        setCategories(response.data.data);
        // if (response.data.data.length > 0) {
        //   setLoading1(false);
        //   let categories = response.data.data;
        //   let filtered_categories = [];
        //   if( categories.length > 0 ){
        //     categories.map(function(item, index){
        //       axios.get(`${DefaultConfig.API_URL}/product?category=${item.id}`, {
        //         headers: {
        //           'Authorization': `Bearer ${DefaultConfig.API_TOKEN}`,
        //         },
        //       })
        //         .then((response) => {
        //           let products = response.data.data;
        //           products.map(function(product){
        //             if( product.show ) filtered_categories[index] = item;
        //           })
        //         })
        //         .catch((error) => { return false; });
        //     })
        //   }
        //   setCategories(filtered_categories);
        setSelectedCategory(response.data.data[0].id);
        // }
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  // Fetch products based on selected category
  useEffect(() => {
    setLoading1(true);
    if (selectedCategory !== null) {
      axios
        .get(`${DefaultConfig.API_URL}/product?category=${selectedCategory}`, {
          headers: {
            Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
          },
        })
        .then((response) => {
          setProducts(response.data.data);
          setLoading1(false);
        })
        .catch((error) => console.error("Error fetching products:", error));
    }
  }, [selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const handleCategoryNextClick = () => {
    const currentIndex = categories.findIndex(
      (category) => category.id === selectedCategory
    );

    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % categories.length;
      const nextCategory = categories[nextIndex];

      setSelectedCategory(nextCategory.id);

      // Scroll to the next category button while keeping the active tab visible
      const nextCategoryButton = document.getElementById(
        `categoryButton${nextCategory.id}`
      );
      const activeCategoryButton = document.getElementById(
        `categoryButton${selectedCategory}`
      );

      // Check if categoryTabs is available before accessing it
      const categoryTabs = document.querySelector(".category-tabs");

      if (nextCategoryButton && activeCategoryButton && categoryTabs) {
        // Calculate the scroll amount based on the button width and margin
        const buttonWidth = nextCategoryButton.offsetWidth;
        const buttonMargin = parseInt(
          window.getComputedStyle(nextCategoryButton).marginRight
        );

        // Calculate the scroll amount to keep the active tab visible
        const activeButtonWidth = activeCategoryButton.offsetWidth;
        const activeButtonMargin = parseInt(
          window.getComputedStyle(activeCategoryButton).marginRight
        );
        const activeButtonDistance =
          activeCategoryButton.getBoundingClientRect().left -
          categoryTabs.getBoundingClientRect().left;
        const scrollAmount =
          activeButtonDistance + activeButtonWidth + activeButtonMargin;

        // Scroll to the next category button while keeping the active tab visible
        categoryTabs.scrollLeft += scrollAmount;

        // If the next index is 0, scroll back to the first category
        if (nextIndex === 0) {
          categoryTabs.scrollLeft = 0;
        }
        // Hide the scrollbar
        categoryTabs.style.overflow = "hidden";
      }
    }
  };

  const isMobile = window.innerWidth <= 767;
  return (
    <Category className="product-categories-sec">
      <div className="container category-container">
        <div className="category-tabs">
          {categories.map((category) => (
            <button
              key={category.id}
              id={`categoryButton${category.id}`}
              onClick={() => handleCategoryClick(category.id)}
              className={selectedCategory === category.id ? "active" : ""}
            >
              {category.name}
            </button>
          ))}
        </div>
        {isMobile ? (
          <div className="next-button" onClick={handleCategoryNextClick}>
            <RightArrow />
          </div>
        ) : null}
      </div>

      <div className="category-card">
        <div className="container">
          {loading1 && <div className="loader"></div>}
          {!loading1 && (
            <Row>
              {products
                .filter((product) => product.show)
                .sort((a, b) => a.order - b.order)
                .map((product) => (
                  <Col key={product.id} sm={6} lg={4} xl={3}>
                    <div className="category-card-inner">
                      <a
                        href={`${DefaultConfig.BASE_URL}/product/${product.slug}`}
                      >
                        <img src={product.thumbnail_url} alt={product.name} />
                      </a>
                      <div className="category-card-detail">
                        <h3>
                          <a
                            href={`${DefaultConfig.BASE_URL}/product/${product.slug}`}
                          >
                            {product.name}
                          </a>
                        </h3>
                        <p>
                          Starting From{" "}
                          <b>
                            Rp{" "}
                            {product?.lowest_price_variant
                              ? formatAmount(
                                  product?.lowest_price_variant?.start_from
                                )
                              : null}
                          </b>
                        </p>
                        <a
                          href={`${DefaultConfig.BASE_URL}/product/${product.slug}`}
                        >
                          Build Your Vehicle <RightArrow />
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          )}
        </div>
      </div>
    </Category>
  );
};

export default CategoryTabs;
