import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import DefaultConfig from '../../../Utils/Config';

const Banner = () => {
    const [bannerData, setBannerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${DefaultConfig.API_URL}/banner`, {
                    headers: {
                        'Authorization': `Bearer ${DefaultConfig.API_TOKEN}`,
                    },
                });

                setBannerData(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);
    const isMobile = windowWidth <= 991;

    return (
        <BannerSection className='banner-sec'>
            {loading && <div className='loader'></div>}
            {!loading && (
                <Carousel>
                    {bannerData?.data?.map((item, index) => (
                        <Carousel.Item key={index}>
                            {isMobile ? (
                                <img src={item?.m_image_url} alt="Mobile Image" />
                            ) : (
                                <img src={item?.image_url} alt="Desktop Image" />
                            )}
                            {/*<Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>*/}
                        </Carousel.Item>
                    ))}
                </Carousel>
            )}
        </BannerSection>
    );
}

// Styled components
const BannerSection = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding-top:75px;
    @media screen and (max-width:767px){
        height:60vh;
    }
    .carousel{
        height:100%;
    }
    .carousel-inner{
        height:100%;
    }
    .carousel-item{
        height:100%;
    }
    img {
        width: 100%;
        height: auto;
        object-fit:cover;
    }
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        &::after {
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 3px solid #000;
            border-top: 3px solid transparent;
            animation: spin 1s linear infinite;
          }
      }
      
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
`;

export default Banner;