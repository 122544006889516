import React, { useEffect, useRef } from "react";
import DefaultConfig from "../../../Utils/Config";
import { useAxes, PanInput } from "@egjs/react-axes";
import styled from "styled-components";
import rotateIcon from "../../../Assets/360-arrow-black.svg";
const Image360Exterior = ({ product }) => {
    const images = product?.exterior
        ? product.exterior
            .sort((a, b) => parseInt(a.order) - parseInt(b.order))
            .map((item) => `${DefaultConfig.StorageUrl}${item.image}`)
        : [];

    const { connect, angle } = useAxes(
        {
            angle: {
                range: [0, 360],
                circular: true, 
            }
        },
        {
            deceleration: 0.01,
        },
    );

    const imgContainerRef = useRef(null);

    useEffect(() => {
        if (imgContainerRef.current) {
            connect("angle", new PanInput(imgContainerRef.current));
        }
    }, [connect]);

    return (
        <Image360ExteriorWrapper>
            <div className="car_spot">
                <div className="car_rotate" ref={imgContainerRef}>
                    <div className="img_cont">
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                alt={`Image ${i}`}
                                style={{ display: Math.floor((angle % 360) / (360 / images.length)) === i ? "inline-block" : "none" }}
                            />
                        ))}
                    </div>
                    <div className="rotate_bg"></div>
                </div>
            </div>
        </Image360ExteriorWrapper>
    );
};
const Image360ExteriorWrapper = styled.div`
background-size: cover;
background-repeat: no-repeat;
background-position: center;
.car_spot {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .img_cont {
    height: 500px;
    display: flex;
    align-items: center;
    @media screen and (max-width:991px){
        height:300px;
    }
    img {
        height: 440px;
        transform: translate3d(0,0,0);
        @media screen and (max-width:1024px){
            height: 100%;
            width: 100%;
            max-width: 95%;
            margin: 0 auto;
            object-fit: contain;
        }
      }
    }
  .rotate_bg {
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 100%;
    height: 100px;
    background:url(${rotateIcon}) no-repeat;
    background-size: 100% 50px;
    -webkit-background-size: 100% 100px;
    color: transparent;
    transform: translateX(-50%);
    @media screen and (max-width:991px){
        bottom:-20px;
    }
  }
`;
export default Image360Exterior;