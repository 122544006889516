import React, { useEffect, useRef, useState } from 'react';
import DefaultConfig from '../../../Utils/Config';
import { useAxes, PanInput } from "@egjs/react-axes";

const Image360Interior = ({ product }) => {
    const panoramaRef = useRef(null);
    const [cubemapUrls, setCubemapUrls] = useState([]);

    const imgContainerRef = useRef(null);

    const { connect } = useAxes({
        angle: {
            range: [0, 360],
            circular: true,
        }
    }, {
        deceleration: 0.01,
    });

    useEffect(() => {
        if (imgContainerRef.current) {
            connect("angle", new PanInput(imgContainerRef.current));
        }
    }, [connect]);

    useEffect(() => {
        const images = product?.interior
            ? product.interior
                .sort((a, b) => parseInt(a.position) - parseInt(b.position))
                .map(item => `${DefaultConfig.StorageUrl}${item.image}`)
            : [];

        if (images.length === 6) {
            setCubemapUrls(images);
        }
    }, [product.interior]); 

    useEffect(() => {
        let viewer;
        if (panoramaRef.current && cubemapUrls.length === 6) {
            viewer = window.pannellum.viewer(panoramaRef.current, {
                type: 'cubemap',
                cubeMap: cubemapUrls,
                autoLoad: true,
                yaw: 0,
                pitch: 0,
                hfov: 100,
            });
        }

        return () => {
            if (viewer) {
                viewer.destroy();
            }
        };
    }, [cubemapUrls]);

    return <div ref={panoramaRef} style={{ width: '100%', height: '500px' }} />;
};

export default Image360Interior;
