import React from "react";

export const Tiktok = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#161A1D" />
                <path d="M29.9762 14.5036C28.5829 14.5035 27.2467 13.95 26.2615 12.9648C25.2763 11.9796 24.7228 10.6433 24.7229 9.25H20.9695V23.9474C20.9688 24.6175 20.7572 25.2703 20.3648 25.8135C19.9723 26.3566 19.4188 26.7624 18.7828 26.9734C18.1468 27.1844 17.4605 27.1899 16.8212 26.9891C16.182 26.7882 15.6221 26.3913 15.221 25.8545C14.8199 25.3177 14.5979 24.6683 14.5865 23.9983C14.5751 23.3283 14.7749 22.6717 15.1575 22.1216C15.5401 21.5715 16.0862 21.1558 16.7183 20.9333C17.3504 20.7109 18.0364 20.693 18.6792 20.8823V17.2337C17.3132 17.0495 15.9234 17.2864 14.6955 17.9129C13.4677 18.5393 12.4601 19.5255 11.8074 20.7396C11.1548 21.9537 10.888 23.3381 11.0429 24.7078C11.1977 26.0775 11.7667 27.3674 12.6739 28.4052C13.5811 29.443 14.7834 30.1794 16.1201 30.516C17.4568 30.8525 18.8644 30.7733 20.1549 30.2888C21.4454 29.8043 22.5574 28.9376 23.3424 27.8045C24.1273 26.6714 24.5479 25.3258 24.548 23.9474L24.6575 16.5183C26.1997 17.6504 28.0635 18.2596 29.9766 18.2567L29.9762 14.5036Z" fill="#161A1D" />
            </svg>
        </>
    );
};