import React from "react";

export const CaretDown = () => {
    return (
        <>
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.57227 0H10.6074C11.2402 0 11.5566 0.773438 11.0996 1.23047L6.59961 5.73047C6.31836 6.01172 5.86133 6.01172 5.58008 5.73047L1.08008 1.23047C0.623047 0.773438 0.939453 0 1.57227 0Z" fill="#161A1D" />
            </svg>
        </>
    );
};