import React, { useState, useEffect } from "react";
import axios from 'axios';
import DefaultConfig from '../../../../Utils/Config';
import { Row, Col } from "react-bootstrap";
import Check from "../../../../Assets/check";
import styled from "styled-components";
import SampleImg from "../../../../Assets/car.png"
import Popup from "../../../../Components/Popup";
import { formatAmount } from "../../../../Utils/Global";
import { Carousel } from 'react-responsive-carousel';
import RightArrow  from "../../../../Assets/Vector-white.svg";
const Configuration = ({ product, selectedVariant, selectedConfig, handleSelectConfig }) => {
    const [configsData, setConfigsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [popupItem, setPopupItem] = useState(1);
    const openPopup = (item) => {
        setIsOpen(true);
        setPopupItem(item);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${DefaultConfig.API_URL}/configuration?product=${product?.id}&variant=${selectedVariant?.id}`, {
                    headers: {
                        'Authorization': `Bearer ${DefaultConfig.API_TOKEN}`,
                    },
                });

                setConfigsData(response.data.data);
                if (selectedConfig === null) {
                    handleSelectConfig(response.data.data.length > 0 ? response.data.data[0] : null);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [product, selectedVariant]);

    const isMobile = window.innerWidth <= 991;
    const settings = {
        showStatus: false, // hide dots
        showThumbs: false, // hide dots
        showArrows: true,  // show arrows
        infiniteLoop: true, // enable infinite loop
        useKeyboardArrows: true, // enable keyboard navigation
        emulateTouch: true, // enable touch swipe
        swipeable: true, // enable swipe
        dynamicHeight: false, // maintain the height of the carousel items
        centerMode: false, // center the current item
        centerSlidePercentage: 100, // percentage width of the centered slide (if centerMode is true)
        interval: 5000, // time between automatic transitions (set to 0 to disable auto-play)
        transitionTime: 500, // time it takes to slide to the next item (in milliseconds)
      };
    

    return (
        <ConfigurationWrapper>
            {isOpen && (
                <Popup
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    popupContentStyle={{ width: "100%", height: "auto", padding: "0 20px 20px", maxWidth: "720px", borderRadius: "10px" }}
                    closeButtonStyle={{
                        padding: "5px 10px",
                        border: "none",
                        borderRadius: "3px",
                        background: "transparent",
                        zIndex: "9",
                        position: "relative",
                        top: "30px",
                        right: "0",
                        textAlign: "right",
                        width: "100%"
                    }}
                >
                    <div className="config-popup">
                        <div className="variant-inner">
                            <h3>Conversion Dry Box Detail</h3>
                            <Row>
                                <Col xs={8}>
                                    <div className="variant-img">
                                        <img src={SampleImg} alt="image" className="w-100" />
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <p className="start-from">Rp {formatAmount(popupItem.price)} <span>msrp</span> </p>
                                </Col>
                            </Row>
                            <div className="variant-info">
                                <h4>Highlighted Features</h4>
                                <div className="usp-list" dangerouslySetInnerHTML={{ __html: popupItem.point }}>
                                </div>
                                <button
                                    className={`select-btn ${selectedConfig?.id === popupItem.id ? 'active' : ''}`}
                                    onClick={() => handleSelectConfig(popupItem)}
                                >
                                    {selectedConfig?.id === popupItem.id ? (
                                        <>
                                            Added <Check />
                                        </>
                                    ) : (
                                        'Add'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                </Popup>
            )}
            {isMobile ? (
                <div className="variant-card">
                    {loading
                        ? <div className='loader'></div>
                        : <Carousel {...settings}>
                            {configsData?.map((item, index) => (
                                <div className="mb-4" key={item.id}>
                                    <div className={`variant-inner ${selectedConfig?.id === item.id ? 'selected   ' : ''}`}>
                                        <div className="variant-top">
                                            <p className="numbers">{index + 1}/{configsData.length}</p>
                                        </div>
                                        <div className="variant-img">
                                            <img src={item.thumbnail_url} alt="image" className="w-100" />
                                        </div>
                                        <div className="variant-info">
                                            <p className="name">{item.name}</p>
                                            <div className="usp-list" dangerouslySetInnerHTML={{ __html: item.point }}>
                                            </div>
                                            <div className="detail-price">
                                                <p className="view" onClick={() => openPopup(item)}>View Details</p>
                                                <p className="start-from">+Rp {formatAmount(item.price)} <span>msrp</span> </p>
                                            </div>
                                            <button
                                                className={`select-btn ${selectedConfig?.id === item.id ? 'active' : ''}`}
                                                onClick={() => handleSelectConfig(item)}
                                            >
                                                {selectedConfig?.id === item.id ? (
                                                    <>
                                                        Added <Check />
                                                    </>
                                                ) : (
                                                    'Add'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    }
                </div>
            ) : (
                <div className="variant-card">
                    {loading
                        ? <div className='loader'></div>
                        : <Row>
                            {configsData?.map((item, index) => (
                                <Col lg={4} xl={3} className="mb-4" key={item.id}>
                                    <div className={`variant-inner ${selectedConfig?.id === item.id ? 'selected   ' : ''}`}>
                                        <div className="variant-top">
                                            <p className="numbers">{index + 1}/{configsData.length}</p>
                                        </div>
                                        <div className="variant-img">
                                            <img src={item.thumbnail_url} alt="image" className="w-100" />
                                        </div>
                                        <div className="variant-info">
                                            <p className="name">{item.name}</p>
                                            <div className="usp-list" dangerouslySetInnerHTML={{ __html: item.point }}>
                                            </div>
                                            <div className="detail-price">
                                                <p className="view" onClick={() => openPopup(item)}>View Details</p>
                                                <p className="start-from">+Rp {formatAmount(item.price)} <span>msrp</span> </p>
                                            </div>
                                            <button
                                                className={`select-btn ${selectedConfig?.id === item.id ? 'active' : ''}`}
                                                onClick={() => handleSelectConfig(item)}
                                            >
                                                {selectedConfig?.id === item.id ? (
                                                    <>
                                                        Added <Check />
                                                    </>
                                                ) : (
                                                    'Add'
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                </Col>

                            ))}
                        </Row>
                    }
                </div>
            )}
        </ConfigurationWrapper>
    );
};

const ConfigurationWrapper = styled.div`
.variant-card{
    position:relative;
    padding:40px 0;
    .row{
        justify-content:center;
    }
}
.variant-inner {
    border-radius: 10px;
    border: 2px solid transparent;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.60);
    padding: 20px 20px 80px 20px;
    height: 100%;
    transition: all 0.4s;
    position: relative;
    @media screen and (max-width:575px){
        padding:10px 10px 80px 10px;
    }
    &.selected{
        border: 2px solid #D71921; 
    }
    .variant-top {
        p.numbers {
            color: #A2A3A5;
            font-size: 12px;
            text-align:right;
            margin-bottom:10px;
        }
    }
    .variant-img img {
        height: 185px;
        object-fit: contain;
    }
    .variant-info {
        p.name {
            color: #161A1D;
            font-size: 28px;
            margin: 0;
            @media screen and (max-width:991px){
                font-size:24px;
            }
        }
        .detail-price{
            display:flex;
            justify-content:space-between;
            align-items:center;
            padding:20px 0;
            p.start-from {
                color: #161A1D;
                font-size: 16px;
                margin-bottom: 0;
                display:flex;
                align-items:center;
                span {
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 12px;
                    display:inline-block;
                    margin-left:2px;
                }
            }
            p.view {
                color: #D71921;
                font-size: 16px;
                text-decoration: none;
                display: inline-block;
                text-transform: capitalize;
                border-bottom:1px solid transparent;
                margin-bottom:0;
                cursor:pointer;
                &:hover{
                    border-bottom:1px solid #D71921;
                }
            }
        }
        .usp-list{
            ul{
                padding-top:20px;
                li{
                    color: #161A1D;
                    font-size: 16px;
                    padding: 0px 10px 0px 20px;
                    width: 50%;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 5px;
                    &:before {
                        content: "";
                        color: #D71921;
                        position: absolute;
                        left: 4px;
                        top: 10px;
                        font-size: 24px;
                        width: 5px;
                        height: 5px;
                        background: #D71921;
                        border-radius: 50%;
                    }
                }
            }
        }
        .select-btn {
            border-radius: 4px;
            border: 1px solid #161A1D;
            background: #FFF;
            padding: 12px 12px 12px 30px;
            display: block;
            text-transform: capitalize;
            width: 100px;
            transition: width ease 0.7s;
            position: relative;
            display: flex;
            align-items: center;
            bottom: 30px;
            position: absolute;
            &.active{
                width:120px;
                color:#fff;
                background: linear-gradient(0deg, #D71921 0%, #D71921 100%), #FFF;
                 border-color:#D71921;
                svg{
                    margin-left:8px;
                }
            }
        }
        .popup .select-btn{
            position:unset;
            bottom:0;
        }
    }
}
.config-popup{
    .variant-inner{
        padding:0;
        .variant-img {
            img{
                object-fit:cover;
            }
        }
        .variant-info{
            h4{
                font-size:24px;
                padding-top:30px;
            }
        }
    }
    .start-from{
        text-align:right;
        color: #161A1D;
        font-size: 22px !important;
        margin:0;
        display:block !important;
        span{
            display:block !important;
            font-size:16px !important;
        }
    }
    h3 {
        color: #161A1D;
        font-size: 18px;
        border-bottom: 1px solid #161A1D;
        padding-bottom: 15px;
        display: block;
        margin-bottom:30px;
    }
    .usp-list ul{
        margin-bottom:30px;
    }
    .select-btn {
        border-radius: 4px;
        border: 1px solid #161A1D;
        background: #FFF;
        padding: 12px 12px 12px 30px;
        display: block;
        text-transform: capitalize;
        width: 100px;
        transition: width ease 0.7s;
        position: relative;
        display: flex;
        align-items: center;
        bottom: 0 !important;
        position: initial !important;
        &.active{
            width:120px;
            color:#fff;
            background: linear-gradient(0deg, #D71921 0%, #D71921 100%), #FFF;
             border-color:#D71921;
            svg{
                margin-left:8px;
            }
        }
    }
}
.carousel {
    .slide{
        padding:0 42px;
        text-align:left;
    }
    .control-dots{
        display:none;
    }
    .control-prev, .control-next {
        font-size: 20px;
        color: #3498db;
      }
      .thumbs-wrapper {
        display: none; 
      }
      .carousel-status{
        display:none;
      }
      .control-arrow{
        opacity: 1;
      }
      .control-prev:before, .control-next:before{
        display:none;
      }
      .control-arrow {
        display: inline-block;
        width: 35px;
        height: 35px;
        background: url(${RightArrow}) no-repeat center center !important;
        padding: 10px;
        border-radius: 50%;
        background-color: #161A1D !important;
        border: none;
        background-size: 10px;
        background-position: center center;
        position:absolute;
        top:40%;
        border:1px solid #161A1D;
      }
      .control-prev.control-arrow {
        background: url(${RightArrow}) no-repeat center center !important;
        transform:rotate(180deg);
        background-color: #161A1D !important;
        background-size: 10px;
        background-position: center center;
      }
      .control-disabled.control-arrow {
        cursor: inherit;
        display: block;
      }
      &.carousel-slider .control-arrow:hover{
        background-color:#161A1D;
      }
}
`;

export default Configuration;
