import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import DefaultConfig from "../../../../Utils/Config";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { CaretDown } from "../../../../Assets/caret-down";
import { formatAmount } from "../../../../Utils/Global";
import Kinto from "../../../../Assets/kinto.png";
import Check from "../../../../Assets/check";
import { RightArrow } from "../../../../Assets/right-arrow";
const PriceCalculation = ({
  activeStepTab,
  product,
  selectedVariant,
  CookieCity,
  CookieDealer,
  selectedConfig,
  selectedTransmission,
  selectedColor,
  selectedPackages,
  selectedCustoms,
  triggerFormVerification,
  formData,
  setFormData,
}) => {
  const [formName, setFormName] = useState(
    formData?.formName ? formData.formName : ""
  );
  const [formAddress, setFormAddress] = useState(
    formData?.formAddress ? formData.formAddress : ""
  );
  const [formPhone, setFormPhone] = useState(
    formData?.formPhone ? formData.formPhone : ""
  );
  const [formEmail, setFormEmail] = useState(
    formData?.formEmail ? formData.formEmail : ""
  );
  const [formSalesmanName, setFormSalesmanName] = useState(
    formData?.formSalesmanName ? formData.formSalesmanName : ""
  );
  const [formSalesmanEmail, setFormSalesmanEmail] = useState(
    formData?.formSalesmanEmail ? formData.formSalesmanEmail : ""
  );
  const [detailAmount, setDetailAmount] = useState([]);
  const [calculatedTotalAmount, setCalculatedTotalAmount] = useState(0);

  const handleCheckboxClick = (type) => {
    formData["budgetSimulation"] = "";

    setCashChecked(type === "cash" ? !isCashChecked : false);
    if (!isCashChecked && type === "cash") {
      toggleInsuranceContent();
      setShowCashCalcContent(false);
      formData["budgetSimulation"] = "cash";
    }

    setCreditChecked(type === "credit" ? !isCreditChecked : false);
    if (!isCreditChecked && type === "credit") {
      formData["budgetSimulation"] = "credit";
    }

    setFormData(formData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let accesoriesUrl = "";
        for (let selectedCustom of selectedCustoms) {
          accesoriesUrl += `&accesories[]=${selectedCustom.id}`;
        }
        let packageUrl = "";
        for (let selectedPackage of selectedPackages) {
          packageUrl += `&package=${selectedPackage.id}`;
        }

        const response = await axios.get(
          `${DefaultConfig.API_URL}/calculation/total-amount?dealer=${CookieDealer?.id}&transmission=${selectedTransmission?.id}&color=${selectedColor?.id}${packageUrl}${accesoriesUrl}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );

        formData["calculatedTotalAmount"] = response.data.data;
        setDetailAmount(response.data.data);
        setFormData(formData);
        setCalculatedTotalAmount(response.data.data.total_amount);
        handleCheckboxClick();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [
    product,
    selectedVariant,
    selectedColor,
    selectedConfig,
    selectedTransmission,
    selectedCustoms,
    selectedPackages,
  ]);

  // Start Cash
  const [isCashChecked, setCashChecked] = useState(
    formData?.budgetSimulation === "cash" ? true : false
  );

  // Insurance
  const [showInsurance, setshowInsurance] = useState(
    formData?.insurance ? true : false
  );
  const toggleInsuranceContent = () => {
    setshowInsurance(!showInsurance);
    toggleInsuranceType();
    formData["insurance"] = !showInsurance;
    setFormData(formData);
  };

  // Insurance Type
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [insuranceType, setInsuranceType] = useState(
    formData?.insuranceType ? formData.insuranceType : []
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${DefaultConfig.API_URL}/calculation/insurance-type`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );
        setInsuranceTypes(response.data.data);
        if (!insuranceType?.name) {
          setInsuranceType(response.data.data[0]);
          formData["insuranceType"] = response.data.data[0];
          setFormData(formData);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [product, selectedVariant]);

  // Total Insurance
  const [totalInsurance, setTotalInsurance] = useState(0);
  const [showCashCalcContent, setShowCashCalcContent] = useState(false);
  const handleCashCalculate = () => {
    setShowCashCalcContent(true);
  };
  const [calculatedCashTotalAmount, setCalculatedCashTotalAmount] = useState(
    calculatedTotalAmount
  );
  const toggleInsuranceType = async () => {
    for (let type of insuranceTypes) {
      if (type.id !== insuranceType.id) {
        setInsuranceType(type);
        formData["insuranceType"] = type;
        setFormData(formData);

        try {
          let accesoriesUrl = "";
          for (let selectedCustom of selectedCustoms) {
            accesoriesUrl += `&accesories[]=${selectedCustom.id}`;
          }
          let packageUrl = "";
          for (let selectedPackage of selectedPackages) {
            packageUrl += `&package=${selectedPackage.id}`;
          }

          const response = await axios.get(
            `${DefaultConfig.API_URL}/calculation/insurance-rate?insurance_type=${type.id}&city=${CookieCity?.id}&price=${detailAmount?.price?.id}${packageUrl}${accesoriesUrl}`,
            {
              headers: {
                Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
              },
            }
          );

          await setTotalInsurance(response.data.data.total_insurance);
          await setCalculatedCashTotalAmount(
            calculatedTotalAmount + response.data.data.total_insurance
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  // End Cash

  // Start Credit
  const [isCreditChecked, setCreditChecked] = useState(
    formData?.budgetSimulation === "credit" ? true : false
  );
  const [isDownpaymentChecked, setDownpaymentChecked] = useState(
    formData?.simulation === "downpayment" ? true : false
  );
  const [isInstallmentChecked, setInstallmentChecked] = useState(
    formData?.simulation === "installment" ? true : false
  );
  const handleSimulation = (type) => {
    setDownpaymentChecked(
      type === "downpayment" ? !isDownpaymentChecked : false
    );
    setInstallmentChecked(
      type === "installment" ? !isInstallmentChecked : false
    );
    formData["simulation"] =
      !isDownpaymentChecked && type === "downpayment"
        ? "downpayment"
        : !isInstallmentChecked && type === "installment"
        ? "installment"
        : "";
    formData["selectedDpPackage"] = null;
    setFormData(formData);

    setShowCalcContent(false);
    setCalculatedCreditTotalAmount(calculatedTotalAmount);
  };

  // Package Type
  const [creditPackages, setCreditPackages] = useState([
    { id: 1, name: "Convensional" },
    { id: 2, name: "Syariah" },
  ]);
  const [isCreditPackageOpen, setCreditPackageOpen] = useState(false);
  const toggleCreditPackage = () => {
    setCreditPackageOpen(!isCreditPackageOpen);
  };
  const [selectedCreditPackage, setSelectedCreditPackage] = useState(
    creditPackages[0]
  );
  const handleCreditPackageClick = (item) => {
    setSelectedCreditPackage(item);
    setCreditPackageOpen(false);
  };

  // First Instalment
  const [firstInstallments, setFirstInstallments] = useState([
    { id: "AD", name: "Dibayar di Muka (ADDM)" },
    { id: "AR", name: "Dibayar di Belakang (ADDB)" },
  ]);
  const [isFInstallmentOpen, setFInstallmentOpen] = useState(false);
  const toggleFInstallment = () => {
    setFInstallmentOpen(!isFInstallmentOpen);
  };
  const [selectedFInstallment, setSelectedFInstallment] = useState(
    firstInstallments[0]
  );
  const handleFInstallmentClick = (item) => {
    setSelectedFInstallment(item);
    setFInstallmentOpen(false);
    formData["firstInstallment"] = item;
    setFormData(formData);
  };

  // Onload/Prepaid
  const [capitalizeds, setCapitalizeds] = useState([
    { id: 1, name: "Onloan" },
    { id: 0, name: "Prepaid" },
  ]);
  const [isCapitalizedOpen, setCapitalizedOpen] = useState(false);
  const toggleCapitalized = () => {
    setCapitalizedOpen(!isCapitalizedOpen);
  };
  const [selectedCapitalized, setSelectedCapitalized] = useState(
    capitalizeds[0]
  );
  const handleCapitalizedClick = (item) => {
    setSelectedCapitalized(item);
    setCapitalizedOpen(false);
    formData["capitalized"] = item;
    setFormData(formData);
  };

  // DP
  const [dpAmount, setDpAmount] = useState(0);
  const [isPrecentChecked, setPrecentChecked] = useState(false);
  const [isNominalChecked, setNominalChecked] = useState(false);
  const handleDP = (type) => {
    setPrecentChecked(type === "percent" ? !isPrecentChecked : false);
    setNominalChecked(type === "nominal" ? !isNominalChecked : false);
    setDpAmount(0);
    formData["dpAmount"] = 0;
    setFormData(formData);
  };
  const handleDpInput = async (data) => {
    data = Math.trunc(data);
    setDpAmount(data);

    formData["dpAmount"] = data;
    if (isPrecentChecked) {
      formData["dpAmount"] =
        (calculatedTotalAmount * (data > 100 ? 100 : data)) / 100;
    }
    await setFormData(formData);
  };

  // Instalment
  const [instalmentAmount, setInstalmentAmount] = useState(0);
  const handleInstalmentInput = async (data) => {
    data = Math.trunc(data);
    setInstalmentAmount(data);
    formData["instalmentAmount"] = data;
    setFormData(formData);
  };

  // Coverage
  const [coverages, setCoverages] = useState([
    { id: 5, name: "Full Comprehensive" },
    { id: 1, name: "Comprehensive 1 tahun + TLO 4 tahun" },
    { id: 2, name: "2 tahun Comprehensive" },
  ]);
  const [isCoverageOpen, setCoverageOpen] = useState(false);
  const toggleCoverage = () => {
    setCoverageOpen(!isCoverageOpen);
  };
  const [selectedCoverage, setSelectedCoverage] = useState(coverages[0]);
  const handleCoverageClick = (item) => {
    setSelectedCoverage(item);
    setCoverageOpen(false);
    formData["coverage"] = item;
    setFormData(formData);
  };

  // Show Calculator Content
  const [errorMessage, setErrorMessage] = useState("");
  const [showCalcContent, setShowCalcContent] = useState(false);
  const handleCalculateClick = () => {
    const fetchData = async () => {
      try {
        let url = `${DefaultConfig.API_URL}`;
        if (isDownpaymentChecked) {
          url += `/calculation/dp-simulation?price=${formData.calculatedTotalAmount?.price?.id}&dp=${formData.dpAmount}&coverage=${selectedCoverage.id}&first_instalment=${selectedFInstallment.id}&capitalized=${selectedCapitalized.id}`;
        }
        if (isInstallmentChecked) {
          url += `/calculation/instalment-simulation?price=${formData.calculatedTotalAmount?.price?.id}&budget=${formData.instalmentAmount}&coverage=${selectedCoverage.id}&first_instalment=${selectedFInstallment.id}&capitalized=${selectedCapitalized.id}`;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
          },
        });

        if (response.data.data?.credits.length > 0) {
          setDpPackages(response.data.data.credits);
          setInstallmentAmount(0);
          setSelectedDpPackage(null);
          setShowCalcContent(true);
        }
      } catch (error) {
        const message = "Paket credit tidak tersedia.";
        // const message =
        //   error.response.data.message ||
        //   "Terjadi kesalahan saat menghubungi server.";
        setErrorMessage(message);
        console.error(error);
      }
    };

    fetchData();
  };
  useEffect(() => {
    let timer;
    if (errorMessage) {
      timer = setTimeout(() => {
        setErrorMessage("");
      }, 5000); // 1000 milisecond = 1 second
    }

    return () => clearTimeout(timer);
  }, [errorMessage]);

  // DP Package
  const [dpPackages, setDpPackages] = useState([]);
  const [selectedDpPackage, setSelectedDpPackage] = useState([]);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [calculatedCreditTotalAmount, setCalculatedCreditTotalAmount] =
    useState(calculatedTotalAmount);
  const handleDpPackageButtonClick = (item) => {
    setSelectedDpPackage(item);
    setInstallmentAmount(item.INST_AMT);
    setCalculatedCreditTotalAmount(item.TOTAL_DOWN_PAYMENT_GROSS_AMT);
    formData["selectedDpPackage"] = item;
    setFormData(formData);
  };

  const list = ["one", "two", "three"];
  // End Credit

  const handleCalculatePdfClick = () => {};

  const [isProgramOpen, setProgramOpen] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState();
  const toggleProgram = () => {
    setProgramOpen(!isProgramOpen);
  };
  const handleProgramClick = (item) => {
    setSelectedProgram(item);
    setProgramOpen(false);
  };

  const [isPacketOpen, setPacketOpen] = useState(false);
  const [selectedPacket, setSelectedPacket] = useState();
  const togglePacket = () => {
    setPacketOpen(!isPacketOpen);
  };
  const handlePacketClick = (item) => {
    setSelectedPacket(item);
    setPacketOpen(false);
  };
  const [selectedRow, setSelectedRow] = useState(null);

  const handleButtonClick = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  const handleFormField = (data) => {
    formData[data.key] = data.value;
    setFormData(formData);
  };

  /*slider*/
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <div className="slick-prev">
        <RightArrow />
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <RightArrow />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024, // Adjust this breakpoint according to your design needs
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint according to your design needs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <PriceWrapper>
        <div className="container">
          <div className="price-inner">
            {activeStepTab === 7 ? (
              <React.Fragment>
                <FormWrapper>
                  <Row>
                    <Col>
                      <div className="input-field">
                        <h3 for="referral-code">Referral Code</h3>
                        <input
                          id="referral-code"
                          name="referral_code"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </FormWrapper>
              </React.Fragment>
            ) : null}
            <div className="option-wrapper">
              <h3>Payment Method</h3>
              <div className="options">
                <div
                  className="checkbox"
                  onClick={() => handleCheckboxClick("cash")}
                >
                  <span className={isCashChecked ? "checked" : ""}></span>
                  <label>Cash</label>
                </div>
                <div
                  className="checkbox"
                  onClick={() => handleCheckboxClick("credit")}
                >
                  <span className={isCreditChecked ? "checked" : ""}></span>
                  <label>Credit</label>
                </div>
              </div>
            </div>
            {isCashChecked ? (
              <div className="cash-wrapper">
                <div className="insurance">
                  <div className="insurance-selection">
                    <h3>Insurance</h3>
                    <p onClick={toggleInsuranceContent}>
                      {showInsurance ? "Yes" : "No"} <CaretDown />{" "}
                    </p>
                  </div>
                  {showInsurance && (
                    <div className="insurance-content">
                      <div className="insurance-head">
                        <Row>
                          <Col xs={12} className="pb-3">
                            <h3>Insurance Type</h3>
                            <p onClick={toggleInsuranceType}>
                              {insuranceType?.name} <CaretDown />
                            </p>
                          </Col>
                          <Col xs={12}>
                            <span>Length</span>
                            <span>1 Year</span>
                          </Col>
                        </Row>
                      </div>
                      <div className="total">
                        <p>TOTAL INSURANCE</p>
                        <p className="price">
                          Rp {formatAmount(totalInsurance)}
                        </p>
                      </div>
                      <div className="disclaimer">*Menggunakan Rate OJK</div>
                    </div>
                  )}
                </div>
                <div className="calc-btn mb-4">
                  <button onClick={handleCashCalculate}>Calculate</button>
                </div>
                {showCashCalcContent ? (
                  <div className="total red">
                    <p>FINAL AMOUNT</p>
                    <p className="price">
                      Rp {formatAmount(calculatedCashTotalAmount)}
                    </p>
                  </div>
                ) : null}
                {/* {activeStepTab === 8 ? (
                  <FormWrapper>
                    <h4>Personal Data</h4>
                    <Row>
                      <Col>
                        <div className="input-field">
                          <label>
                            Name<sup>*</sup>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Your Name"
                            value={formName}
                            onInput={(e) => {
                              setFormName(e.target.value);
                              handleFormField({
                                key: "formName",
                                value: e.target.value,
                              });
                            }}
                          />
                          {triggerFormVerification && formName === "" && (
                            <p style={{ color: "#e83b46" }}>
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="input-field">
                          <label>
                            No. Handphone<sup>*</sup>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Your Number"
                            value={formPhone}
                            onInput={(e) => {
                              setFormPhone(e.target.value);
                              handleFormField({
                                key: "formPhone",
                                value: e.target.value,
                              });
                            }}
                          />
                          {triggerFormVerification && formPhone === "" && (
                            <p style={{ color: "#e83b46" }}>
                              This field is required
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col>
                        <div className="input-field">
                          <label>
                            Address<sup>*</sup>{" "}
                          </label>
                          <input
                            type="text"
                            placeholder="Your Address"
                            value={formAddress}
                            onInput={(e) => {
                              setFormAddress(e.target.value);
                              handleFormField({
                                key: "formAddress",
                                value: e.target.value,
                              });
                            }}
                          />
                          {triggerFormVerification && formAddress === "" && (
                            <p style={{ color: "#e83b46" }}>
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="input-field">
                          <label>
                            Email<sup>*</sup>{" "}
                          </label>
                          <input
                            type="email"
                            placeholder="Your Email"
                            value={formEmail}
                            onInput={(e) => {
                              setFormEmail(e.target.value);
                              handleFormField({
                                key: "formEmail",
                                value: e.target.value,
                              });
                            }}
                          />
                          {triggerFormVerification && formEmail === "" && (
                            <p style={{ color: "#e83b46" }}>
                              This field is required
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <h4>Salesman Data</h4>
                    <Row>
                      <Col>
                        <div className="input-field">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Salesman Name"
                            value={formSalesmanName}
                            onInput={(e) => {
                              setFormSalesmanName(e.target.value);
                              handleFormField({
                                key: "formSalesmanName",
                                value: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="input-field">
                          <label>Sales Email</label>
                          <input
                            type="email"
                            placeholder="Salesman Email"
                            value={formSalesmanEmail}
                            onInput={(e) => {
                              setFormSalesmanEmail(e.target.value);
                              handleFormField({
                                key: "formSalesmanEmail",
                                value: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </FormWrapper>
                ) : null} */}
              </div>
            ) : (
              ""
            )}
            {isCreditChecked ? (
              <div className="credit-wrapper">
                <div className="option-wrapper">
                  <div className="options">
                    <div
                      className="checkbox"
                      onClick={() => handleSimulation("downpayment")}
                    >
                      <span
                        className={isDownpaymentChecked ? "checked" : ""}
                      ></span>
                      <label>Down Payment Simulation</label>
                    </div>
                    <div
                      className="checkbox"
                      onClick={() => handleSimulation("installment")}
                    >
                      <span
                        className={isInstallmentChecked ? "checked" : ""}
                      ></span>
                      <label>Installment Simulation</label>
                    </div>
                  </div>
                </div>
                <FormWrapper>
                  <Row>
                    <Col>
                      <div className="input-field">
                        <label>Package Type</label>
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${
                              isCreditPackageOpen ? "open" : ""
                            }`}
                            onClick={toggleCreditPackage}
                          >
                            {selectedCreditPackage
                              ? selectedCreditPackage.name
                              : ""}
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          {isCreditPackageOpen && (
                            <ul className="dropdown-list">
                              {creditPackages.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => handleCreditPackageClick(item)}
                                >
                                  {item.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="input-field">
                        <label>First Installment</label>
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${
                              isFInstallmentOpen ? "open" : ""
                            }`}
                            onClick={toggleFInstallment}
                          >
                            {selectedFInstallment
                              ? selectedFInstallment.name
                              : "Dibayar di Muka (ADDM)"}
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          {isFInstallmentOpen && (
                            <ul className="dropdown-list">
                              {firstInstallments.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => handleFInstallmentClick(item)}
                                >
                                  {item.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>

                      <div className="input-field">
                        <label>Onloan/Prepaid</label>
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${
                              isCapitalizedOpen ? "open" : ""
                            }`}
                            onClick={toggleCapitalized}
                          >
                            {selectedCapitalized
                              ? selectedCapitalized.name
                              : "Onloan"}
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          {isCapitalizedOpen && (
                            <ul className="dropdown-list">
                              {capitalizeds.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => handleCapitalizedClick(item)}
                                >
                                  {item.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      {isDownpaymentChecked ? (
                        <div className="dp-field">
                          <div
                            className={`input-field ${
                              isPrecentChecked ? "percentage" : ""
                            }`}
                          >
                            <label>DP</label>
                            <input
                              type="number"
                              value={dpAmount}
                              onInput={(e) => {
                                handleDpInput(e.target.value);
                              }}
                            />
                          </div>
                          <div className="option-wrapper">
                            <div className="options">
                              <div
                                className="checkbox"
                                onClick={() => handleDP("percent")}
                              >
                                <span
                                  className={isPrecentChecked ? "checked" : ""}
                                ></span>
                                <label>Percent</label>
                              </div>
                              <div
                                className="checkbox"
                                onClick={() => handleDP("nominal")}
                              >
                                <span
                                  className={isNominalChecked ? "checked" : ""}
                                ></span>
                                <label>Nominal</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {isInstallmentChecked ? (
                        <div className="input-field">
                          <label>Installment</label>
                          <input
                            type="number"
                            value={instalmentAmount}
                            onInput={(e) => {
                              handleInstalmentInput(e.target.value);
                            }}
                          />
                        </div>
                      ) : null}
                      <div className="input-field">
                        <label>Coverage</label>
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${
                              isCoverageOpen ? "open" : ""
                            }`}
                            onClick={toggleCoverage}
                          >
                            {selectedCoverage
                              ? selectedCoverage?.name
                              : "Full Comprehensive"}
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          {isCoverageOpen && (
                            <ul className="dropdown-list">
                              {coverages.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => handleCoverageClick(item)}
                                >
                                  {item.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </FormWrapper>
                {isDownpaymentChecked || isInstallmentChecked ? (
                  <div className="calc-btn">
                    <button onClick={handleCalculateClick}>Calculate</button>
                  </div>
                ) : null}
                {errorMessage ? (
                  <div className="mt-3 text-center text-red">
                    <p>{errorMessage}</p>
                  </div>
                ) : null}
                {showCalcContent && (
                  <>
                    {activeStepTab === 7 ? (
                      <SliderWrapper>
                        <Slider {...settings}>
                          <div className="slider-table">
                            <div className="table-logo">
                              <p>PAKET REGULAR</p>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>Tenor</th>
                                  <th>TDP</th>
                                  <th>Installment</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {dpPackages.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item?.TENOR} Bulan</td>
                                    <td>
                                      RP{" "}
                                      {formatAmount(
                                        item?.TOTAL_DOWN_PAYMENT_GROSS_AMT
                                      )}
                                    </td>
                                    <td>RP {formatAmount(item?.INST_AMT)}</td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          handleDpPackageButtonClick(item)
                                        }
                                        className={
                                          selectedDpPackage?.TOTAL_DOWN_PAYMENT_GROSS_AMT ===
                                            item?.TOTAL_DOWN_PAYMENT_GROSS_AMT &&
                                          selectedDpPackage?.INST_AMT ===
                                            item?.INST_AMT
                                            ? "selectedButton"
                                            : ""
                                        }
                                      >
                                        {selectedDpPackage?.TOTAL_DOWN_PAYMENT_GROSS_AMT ===
                                          item?.TOTAL_DOWN_PAYMENT_GROSS_AMT &&
                                        selectedDpPackage?.INST_AMT ===
                                          item?.INST_AMT ? (
                                          <>
                                            Added <Check />
                                          </>
                                        ) : (
                                          "Add"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Slider>
                      </SliderWrapper>
                    ) : null}
                    {/* {activeStepTab === 8 ? (
                      <FormWrapper>
                        <Row className="table-selection">
                          <Col>
                            <div className="input-field">
                              <label>Program</label>
                              <div className="custom-dropdown">
                                <div
                                  className={`dropdown-header ${
                                    isProgramOpen ? "open" : ""
                                  }`}
                                  onClick={toggleProgram}
                                >
                                  {selectedProgram
                                    ? selectedProgram?.name
                                    : "Full Comprehensive"}
                                  <span className="select-arrow">
                                    <CaretDown />
                                  </span>
                                </div>
                                {isProgramOpen && (
                                  <ul className="dropdown-list">
                                    {coverages.map((item) => (
                                      <li
                                        key={item.id}
                                        onClick={() => handleProgramClick(item)}
                                      >
                                        {item.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="input-field">
                              <label>Packet</label>
                              <div className="custom-dropdown">
                                <div
                                  className={`dropdown-header ${
                                    isPacketOpen ? "open" : ""
                                  }`}
                                  onClick={togglePacket}
                                >
                                  {selectedPacket
                                    ? selectedPacket?.name
                                    : "Full Comprehensive"}
                                  <span className="select-arrow">
                                    <CaretDown />
                                  </span>
                                </div>
                                {isPacketOpen && (
                                  <ul className="dropdown-list">
                                    {coverages.map((item) => (
                                      <li
                                        key={item.id}
                                        onClick={() => handlePacketClick(item)}
                                      >
                                        {item.name}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </FormWrapper>
                    ) : null} */}
                    {installmentAmount > 0 ? (
                      <>
                        <div className="total mt-4">
                          <p>Monthly Installment</p>
                          <p className="price">
                            Rp {formatAmount(installmentAmount)}
                          </p>
                        </div>
                        <div class="disclaimer">*Menggunakan Rate OJK</div>
                        <div className="border-here"></div>
                        <div className="total red my-4 border-top">
                          <p>FINAL AMOUNT</p>
                          <p className="price">
                            Rp {formatAmount(calculatedCreditTotalAmount)}
                          </p>
                        </div>
                        <div class="disclaimer pb-0">
                          *Harga sewaktu-waktu dapat berubah
                        </div>
                        <div class="disclaimer">
                          **Ini hanya berupa simulasi dan bersifat tidak
                          mengikat, silahkan hubungi dealer pilihan Anda untuk
                          informasi availability program lebih lanjut.
                        </div>
                      </>
                    ) : null}
                    {/* {activeStepTab === 8 ? (
                      <FormWrapper>
                        <h4>Personal Data</h4>
                        <Row>
                          <Col>
                            <div className="input-field">
                              <label>
                                Name<sup>*</sup>{" "}
                              </label>
                              <input type="text" placeholder="Your Name" />
                            </div>
                            <div className="input-field">
                              <label>
                                No. Handphone<sup>*</sup>{" "}
                              </label>
                              <input type="text" placeholder="Your Number" />
                            </div>
                          </Col>
                          <Col>
                            <div className="input-field">
                              <label>
                                Address<sup>*</sup>{" "}
                              </label>
                              <input type="text" placeholder="Your Address" />
                            </div>
                            <div className="input-field">
                              <label>
                                Email<sup>*</sup>{" "}
                              </label>
                              <input type="email" placeholder="Your Email" />
                            </div>
                          </Col>
                        </Row>
                        <h4>Salesman Data</h4>
                        <Row>
                          <Col>
                            <div className="input-field">
                              <label>Name</label>
                              <input type="text" placeholder="Salesman Name" />
                            </div>
                          </Col>
                          <Col>
                            <div className="input-field">
                              <label>Sales Email</label>
                              <input
                                type="email"
                                placeholder="Salesman Email"
                              />
                            </div>
                          </Col>
                        </Row>
                      </FormWrapper>
                    ) : null} */}
                  </>
                )}
              </div>
            ) : (
              ""
            )}
            {/* {activeStepTab === 8 ? (
              <React.Fragment>
                <div className="calc-btn">
                  <button onClick={handleCalculatePdfClick}>
                    Download Summary & Calculation
                  </button>
                </div>
              </React.Fragment>
            ) : null} */}
          </div>
        </div>
      </PriceWrapper>
    </>
  );
};

const PriceWrapper = styled.div`
  //custom-start
  p {
    margin-bottom: 0;
  }
  .option-wrapper {
    display: flex;
    padding-bottom: 30px;
    border-top: 1px solid #d0d1d2;
    padding-top: 30px;
    justify-content: space-between;
    @media screen and (max-width: 420px) {
      display: block;
    }
    .options {
      display: flex;

      .checkbox {
        display: flex;
        align-items: center;
        margin-right: 10px;
        span {
          width: 20px;
          height: 20px;
          display: inline-block;
          border-radius: 50%;
          border: 2px solid #d0d1d2;
          position: relative;
          cursor: pointer;
          &.checked {
            border-color: #d71921;
          }
          &.checked:before {
            content: "";
            width: 17px;
            height: 17px;
            position: absolute;
            background-color: #d71921;
            border-radius: 50%;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 2px solid #fff;
          }
        }
        label {
          padding-left: 10px;
          @media screen and (max-width: 575px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .credit-wrapper {
    form {
      .option-wrapper {
        justify-content: flex-end;
        border: none;
      }
    }
    & > .option-wrapper {
      padding-bottom: 30px;
      justify-content: center;
      border-bottom: 1px solid #d0d1d2;
      .options {
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
  //custom-end
  .price-inner {
    max-width: 92%;
    margin: 40px auto 60px;
    padding: 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.6);
    transition: min-height 0.3s ease-out;
    @media screen and (max-width: 991px) {
      max-width: 100%;
      padding: 30px 15px;
    }
    h3 {
      font-size: 18px;
      margin-right: 20px;
      margin-bottom: 0;
      @media screen and (max-width: 420px) {
        padding-bottom: 15px;
      }
    }
    .insurance-selection {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      p {
        margin-bottom: 0;
        cursor: pointer;
      }
    }
    .insurance-content {
      .insurance-head {
        padding: 20px 0;
        .row {
          .col-12 {
            display: flex;
            justify-content: space-between;
            p {
              cursor: pointer;
              transition: 0.3s;
            }
          }
        }
      }
    }
    .border-here {
      width: 100%;
      height: 1px;
      background: #d0d1d2;
      margin-top: 25px;
    }
    .total {
      border-radius: 10px;
      background: #e3e3e4;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 575px) {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      &.red {
        background: #d71921;
        p {
          color: #fff;
        }
      }
      p {
        font-size: 18px;
        font-weight: 700;
        @media screen and (max-width: 575px) {
          font-size: 16px;
        }
        &.price {
          font-size: 24px;
          @media screen and (max-width: 575px) {
            font-size: 18px;
          }
        }
      }
    }
    .disclaimer {
      font-size: 10px;
      padding: 10px 0 20px;
    }
    .calc-btn {
      text-align: center;
      button {
        border-radius: 5px;
        background: #161a1d;
        border: 1px solid #161a1d;
        padding: 12px 32px;
        color: #fff;
        font-size: 18px;
        @media screen and (max-width: 575px) {
          font-size: 16px;
        }
        &:hover {
          background: #d71921;
          border-color: #d71921;
        }
      }
    }
  }
`;

const FormWrapper = styled.form`
  .table-selection {
    border-top: 1px solid #d0d1d2;
    padding-top: 30px;
  }
  .row {
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  padding: 20px 0;
  h4 {
    font-size: 18px;
    margin: 10px 0 20px;
  }
  .input-field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    @media screen and (max-width: 575px) {
      display: block;
    }
    label {
      width: 25%;
      @media screen and (max-width: 1199px) {
        font-size: 14px;
      }
      @media screen and (max-width: 575px) {
        width: 100%;
        padding-bottom: 10px;
      }
    }
    input {
      width: 70%;
      border-radius: 100px;
      border: 2px solid #d0d1d2;
      padding: 10px 20px;
      @media screen and (max-width: 575px) {
        width: 100%;
      }
      &::placeholder {
        color: #161a1d;
        font-size: 16px;
        @media screen and (max-width: 1199px) {
          font-size: 14px;
        }
      }
      &#referral-code {
        margin-right: 0;
        width: 100%;
        max-width: 30%;
        margin-left: auto;
        @media screen and (max-width: 767px) {
          max-width: 100%;
          margin-top: 20px;
        }
      }
    }
    .custom-dropdown {
      width: 70%;
      position: relative;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .dropdown-header {
        width: 100%;
        border-radius: 100px;
        border: 2px solid #d0d1d2;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        @media screen and (max-width: 1199px) {
          font-size: 14px;
        }
        &.open {
          border-radius: 10px 10px 0 0;
        }
        svg {
          &:hover {
            path {
              fill: #d71921;
            }
          }
        }
      }
      .dropdown-list {
        border: 0px solid #161a1d;
        border-radius: 0 0 10px 10px;
        padding: 10px 0;
        margin-top: 0;
        box-shadow: 0 0 5px 0px #ddd;
        position: absolute;
        width: 100%;
        z-index: 9;
        background: #fff;
        max-height: 180px;
        overflow-y: auto;
        min-height: auto;
        li {
          padding: 5px 10px;
          cursor: pointer;
          text-align: left;
        }
      }
    }
  }
  .dp-field {
    .input-field.percentage {
      position: relative;
      &:after {
        content: "%";
        position: absolute;
        right: 20px;
        margin-top: 10px;
      }
    }
    label {
      text-align: left;
    }
    .option-wrapper {
      padding: 0;
    }
  }
`;

const SliderWrapper = styled.div`
  padding: 30px 0 50px;
  .slick-slide {
    padding: 0 10px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .slick-arrow {
    bottom: -70px;
    top: unset;
    margin: 0 10px;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    &:before {
      display: none;
    }
    &:hover {
      background: #161a1d;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    &.slick-disabled {
      opacity: 0.35;
    }
    &.slick-prev {
      left: 48%;
      svg {
        transform: rotate(180deg);
      }
    }
    &.slick-next {
      right: 43%;
    }
  }
  .slider-table {
    border-radius: 10px;
    background: #f8f8f8;
    overflow-x: auto;
    .table-logo {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: center;
    }
    table {
      width: 100%;
      white-space: nowrap;
      thead {
        background: #d9dadb;
      }
      tbody {
        tr {
          &:nth-child(even) {
            background: #eceded;
          }
        }
      }
      tr {
        th {
          width: 25%;
          text-align: center;
          padding: 13px 0;
          font-size: 14px;
          font-weight: 700;
        }
        td {
          width: 25%;
          text-align: center;
          padding: 15px 4px;
          font-size: 12px;
          button {
            border-radius: 5px;
            background: #161a1d;
            border: 0;
            padding: 5px 8px;
            color: #fff;
            max-width: 55%;
            width: 100%;
            transition: max-width 0.5s ease;
            text-transform: capitalize;
            @media screen and (max-width: 575px) {
              max-width: 98%;
            }
            &:hover {
              background: #d71921;
              color: #fff;
            }
            &.selectedButton {
              max-width: 90%;
              background: #d71921;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              @media screen and (max-width: 575px) {
                max-width: 98%;
              }
              svg {
                margin-left: 5px;
                width: 14px;
              }
            }
          }
        }
      }
    }
  }
`;
export default PriceCalculation;
