import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DefaultConfig from "../../../../Utils/Config";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import Check from "../../../../Assets/check";
import { formatAmount } from "../../../../Utils/Global";
import Popup from "../../../../Components/Popup";
import { Carousel } from 'react-responsive-carousel';
import RightArrow from "../../../../Assets/Vector-white.svg";
import Play from "../../../../Assets/play.svg";

const Variants = ({ product, handleSelectedVariant, selectedVariant, CookieDealer }) => {
    const [selectedEngine, setSelectedEngine] = useState(0);
    const [variants, setVariants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [popupVariant, setPopupVariant] = useState([]);

    const handleSetSelectedEngine = (id) => {
        setSelectedEngine(id);
    }

    const openPopup = (variant) => {
        setIsOpen(variant.id);
        setPopupVariant(variant);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${DefaultConfig.API_URL}/variant?product=${product?.id}&engine=${selectedEngine}&dealer=${CookieDealer?.id}`, {
                    headers: {
                        'Authorization': `Bearer ${DefaultConfig.API_TOKEN}`,
                    },
                });

                setVariants(response.data.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [product, selectedEngine]);

    const isMobile = window.innerWidth <= 991;
    const settings = {
        showStatus: false, // hide dots
        showThumbs: false, // hide dots
        showArrows: true,  // show arrows
        infiniteLoop: true, // enable infinite loop
        useKeyboardArrows: true, // enable keyboard navigation
        emulateTouch: true, // enable touch swipe
        swipeable: true, // enable swipe
        dynamicHeight: false, // maintain the height of the carousel items
        centerMode: false, // center the current item
        centerSlidePercentage: 100, // percentage width of the centered slide (if centerMode is true)
        interval: 5000, // time between automatic transitions (set to 0 to disable auto-play)
        transitionTime: 500, // time it takes to slide to the next item (in milliseconds)
    };


    return (
        <VariantWrapper>
            {product ? (
                <ul className="engine-name">
                    <li className={selectedEngine === 0 ? 'active' : ''} onClick={() => handleSetSelectedEngine(0)}>All</li>
                    {product?.engine_category?.map((engine, index) => (
                        <li key={index} className={selectedEngine === engine.id ? 'active' : ''} onClick={() => handleSetSelectedEngine(engine.id)}>
                            {engine.name}
                        </li>
                    ))}
                </ul>
            ) : null}
            {isOpen && (
                <Popup
                    isOpen={isOpen === popupVariant.id}
                    onClose={() => setIsOpen(false)}
                    popupContentStyle={{ width: "100%", height: "80%", padding: "0 20px 20px", maxWidth: "800px", borderRadius: "10px" }}
                    closeButtonStyle={{
                        padding: "5px 10px",
                        border: "none",
                        borderRadius: "3px",
                        background: "transparent",
                        zIndex: "9",
                        /* float: right; */
                        position: "relative",
                        top: "30px",
                        right: "0",
                        textAlign: "right",
                        width: "100%"
                    }}
                >
                    <div className="variant-popup">
                        <h3>{popupVariant.name} Detail</h3>
                        <Row>
                            <Col xs={12} sm={6}>
                                <img src={popupVariant.image_url} alt="image" className="w-100" />
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className="variant-info">
                                    <p className="type">{popupVariant.name}</p>
                                    <p className="start-from">Rp {formatAmount(popupVariant.start_from)} <span>Starting from</span></p>
                                </div>
                            </Col>
                        </Row>
                        <div className="highlight-feature">
                            {popupVariant && popupVariant.usp_category ? (
                                <div>
                                    {popupVariant.usp_category.map((uspCategory, index) => (
                                        <div key={index}>
                                            {uspCategory.name && <h4>{uspCategory.name}</h4>}
                                            <ul>
                                                {uspCategory?.data.map((usp, uspIndex) => (
                                                    <li className={usp.video != null ? 'has-video' : ''}
                                                        key={uspIndex} dangerouslySetInnerHTML={{ __html: usp.video != null ? `<a href="${usp.video}" target="_blank">${usp.name}</a>` : usp.name }} />
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            <button
                                key={popupVariant.id}
                                className={`select-btn ${selectedVariant?.id === popupVariant.id ? 'active' : ''}`}
                                onClick={handleSelectedVariant(popupVariant)}
                            >
                                {selectedVariant?.id === popupVariant.id ? (
                                    <>
                                        Added <Check />
                                    </>
                                ) : (
                                    'Add'
                                )}
                            </button>
                        </div>
                    </div>
                </Popup>
            )}
            {isMobile ? (
                <div className="variant-card">
                    {loading && <div className='loader'></div>}
                    {!loading && (
                        <Carousel {...settings}>
                            {variants?.map((variant, index) => (
                                <div key={index} className="mb-4">
                                    <div
                                        key={variant.id}
                                        className={`variant-inner ${selectedVariant?.id === variant.id ? 'selected' : ''}`}>
                                        <div className="variant-top">
                                            <p className="name">{variant?.engine_category?.name}</p>
                                            <p className="numbers">{index + 1}/{variants?.length}</p>
                                        </div>
                                        <div className="variant-img">
                                            <img src={variant.image_url} alt="image" className="w-100" />
                                        </div>
                                        <div className="variant-info">
                                            <p className="type">{variant.name}</p>
                                            <p className="start-from">Rp {formatAmount(variant.start_from)} <span>Base MSRP</span></p>
                                            <a onClick={() => openPopup(variant)}>view Details</a>

                                            <button
                                                key={variant.id}
                                                className={`select-btn ${selectedVariant?.id === variant.id ? 'active' : ''}`}
                                                onClick={handleSelectedVariant(variant)}
                                            >
                                                {selectedVariant?.id === variant.id ? (
                                                    <>
                                                        Added <Check />
                                                    </>
                                                ) : (
                                                    'Add'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    )}
                </div>
            ) : (
                <div className="variant-card">
                    {loading && <div className='loader'></div>}
                    {!loading && (<Row>
                        {variants?.map((variant, index) => (
                            <Col lg={4} xl={3} key={index} className="mb-4">
                                <div
                                    key={variant.id}
                                    className={`variant-inner ${selectedVariant?.id === variant.id ? 'selected' : ''}`}>
                                    <div className="variant-top">
                                        <p className="name">{variant?.engine_category?.name} </p>
                                        <p className="numbers">{index + 1}/{variants?.length}</p>
                                    </div>
                                    <div className="variant-img">
                                        <img src={variant.image_url} alt="image" className="w-100" />
                                    </div>
                                    <div className="variant-info">
                                        <p className="type">{variant.name}</p>
                                        <p className="start-from">Rp {formatAmount(variant.start_from)} <span>Starting from</span></p>
                                        <a onClick={() => openPopup(variant)}>view Details</a>

                                        <button
                                            key={variant.id}
                                            className={`select-btn ${selectedVariant?.id === variant.id ? 'active' : ''}`}
                                            onClick={handleSelectedVariant(variant)}
                                        >
                                            {selectedVariant?.id === variant.id ? (
                                                <>
                                                    Added <Check />
                                                </>
                                            ) : (
                                                'Add'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    )}
                </div>
            )}



        </VariantWrapper>
    );
}

const VariantWrapper = styled.div`
.variant-card{
    position:relative;
    min-height:250px;
}
.engine-name {
    display: flex;
    padding: 40px 20px;
    justify-content: center;
    align-items: center;
    li{
        color: #161A1D;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 1.429px;
        margin:0 15px;
        cursor:pointer;
        &.active{
            color:#D71921;
            border-bottom: 1px solid #D71921;
        }
    }
}
    .variant-inner {
        border-radius: 10px;
        border: 2px solid transparent;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.60);
        padding: 20px 20px 80px 20px;
        height: 100%;
        transition: all 0.4s;
        position: relative;
        @media screen and (max-width:575px){
            padding:10px 10px 80px 10px;
        }
        &.selected{
            border: 2px solid #D71921; 
        }
        .variant-top {
            display: flex;
            justify-content: space-between;
            p.name {
                color: #161A1D;
                font-size: 12px;
                font-weight: 700;
            }
            p.numbers {
                color: #A2A3A5;
                font-size: 12px;
            }
        }
        .variant-img img {
            height: 185px;
            object-fit: contain;
        }
        .variant-info {
            p.type {
                color: #161A1D;
                font-size: 28px;
                margin: 0;
                @media screen and (max-width:991px){
                    font-size:24px;
                }
            }
            p.start-from {
                padding: 20px 0 10px;
                color: #161A1D;
                font-size: 24px;
                margin-bottom: 0;
                span {
                    display: block;
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 14px;
                }
            }
            a {
                color: #D71921;
                font-size: 16px;
                text-decoration: none;
                display: inline-block;
                text-transform: capitalize;
                margin-bottom: 20px;
                border-bottom:1px solid transparent;
                cursor:pointer;
                &:hover{
                    border-bottom:1px solid #D71921;
                }
            }
            .select-btn {
                border-radius: 4px;
                border: 1px solid #161A1D;
                background: #FFF;
                padding: 12px 12px 12px 30px;
                display: block;
                text-transform: capitalize;
                width: 100px;
                transition: width ease 0.7s;
                position: relative;
                display: flex;
                align-items: center;
                bottom: 30px;
                position: absolute;
                &.active{
                    width:120px;
                    color:#fff;
                    background: linear-gradient(0deg, #D71921 0%, #D71921 100%), #FFF;
                     border-color:#D71921;
                    svg{
                        margin-left:8px;
                    }
                }
            }
            .popup .select-btn{
                position:unset;
                bottom:0;
            }
        }
    }
    .variant-popup {
        h3 {
            color: #161A1D;
            font-size: 18px;
            border-bottom: 1px solid #161A1D;
            padding-bottom: 15px;
            display: block;
        }
        .variant-info{
            text-align:right;
            @media screen and (max-width:576px){
                text-align:center;
            }
            p.start-from{
                padding:0;
                span{
                    color:#161A1D;
                }
            }
        }
        .variant-info {
            p.type {
                color: #161A1D;
                font-size: 28px;
                margin: 0;
                @media screen and (max-width:991px){
                    font-size:24px;
                }
            }
            p.start-from {
                color: #161A1D;
                font-size: 24px;
                margin-bottom: 0;
                span {
                    display: block;
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 14px;
                }
            }
            a {
                color: #D71921;
                font-size: 16px;
                text-decoration: none;
                display: inline-block;
                text-transform: capitalize;
                margin-bottom: 20px;
                border-bottom:1px solid transparent;
                cursor:pointer;
                &:hover{
                    border-bottom:1px solid #D71921;
                }
            }
        }
        .select-btn {
            border-radius: 4px;
            border: 1px solid #161A1D;
            background: #FFF;
            padding: 12px 12px 12px 30px;
            display: block;
            text-transform: capitalize;
            width: 100px;
            transition: width ease 0.7s;
            position: relative;
            display: flex;
            align-items: center;
            bottom: 0 ;
            position: unset ;
            &.active{
                width:120px;
                color:#fff;
                background: linear-gradient(0deg, #D71921 0%, #D71921 100%), #FFF;
                 border-color:#D71921;
                svg{
                    margin-left:8px;
                }
            }
        }
        .highlight-feature{
            padding:30px 0;
            h4 {
                color: #161A1D;
                font-size: 22px;
                margin-bottom:0;
            }
            ul{
                padding:15px 0 15px;
                li {
                    padding: 0px 10px 0px 20px;
                    width: 50%;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 5px;
                    @media screen and (max-width:575px){
                        width:100%;
                    }
                    &:before {
                        content: "";
                        color: #D71921;
                        position: absolute;
                        left: 4px;
                        top: 10px;
                        font-size: 24px;
                        width: 8px;
                        height: 8px;
                        background-color: #D71921 !important;
                        border-radius: 50%;
                    }
                    &.has-video:before{
                        background: url('${Play}') no-repeat center center; 
                        width: 12px;
                        height: 12px;
                        background-size: 5px;
                        line-height: 12px;
                        left: 2px;
                        text-align: center;
                        top:8px;
                    }
                    p{
                        margin-bottom:0;
                    }
                    a{
                        color:#161A1D;
                        font-size:16px;
                        text-decoration:none;
                        &:hover{
                            color:#D71921;
                        }
                    }
                }
                
            }
        }
    }
    .carousel {
        .slide{
            padding:0 42px;
            text-align:left;
        }
        .control-dots{
            display:none;
        }
        .control-prev, .control-next {
            font-size: 20px;
            color: #3498db;
          }
          .thumbs-wrapper {
            display: none; 
          }
          .carousel-status{
            display:none;
          }
          .control-arrow{
            opacity: 1;
          }
          .control-prev:before, .control-next:before{
            display:none;
          }
          .control-arrow {
            display: inline-block;
            width: 35px;
            height: 35px;
            background: url(${RightArrow}) no-repeat center center !important;
            padding: 10px;
            border-radius: 50%;
            background-color: #161A1D !important;
            border: none;
            background-size: 10px;
            background-position: center center;
            position:absolute;
            top:40%;
            border:1px solid #161A1D;
          }
          .control-prev.control-arrow {
            background: url(${RightArrow}) no-repeat center center !important;
            transform:rotate(180deg);
            background-color: #161A1D !important;
            background-size: 10px;
            background-position: center center;
          }
          .control-disabled.control-arrow {
            cursor: inherit;
            display: block;
          }
          &.carousel-slider .control-arrow:hover{
            background-color:#161A1D;
          }
    }
    
`;

export default Variants;