import React from "react";

export const Twitter = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#161A1D" />
                <path d="M25.5544 10.25H28.7775L21.7358 18.2982L30.0198 29.25H23.5335L18.4532 22.6078L12.6402 29.25H9.41508L16.9469 20.6415L9 10.25H15.651L20.2431 16.3212L25.5544 10.25ZM24.4231 27.3208H26.2091L14.6805 12.0779H12.7639L24.4231 27.3208Z" fill="#161A1D" />
            </svg>
        </>
    );
};