import React, { useState, useEffect } from "react";
import axios from "axios";
import DefaultConfig from "../../../../Utils/Config";
import { Row, Col } from "react-bootstrap";
import Check from "../../../../Assets/check";
import styled from "styled-components";
import SampleImg from "../../../../Assets/car.png";
import { formatAmount } from "../../../../Utils/Global";
import { Carousel } from "react-responsive-carousel";
import RightArrow from "../../../../Assets/Vector-white.svg";

const Transmission = ({
  product,
  selectedVariant,
  selectedTransmission,
  selectedConfig,
  handleSelectTransmission,
}) => {
  const [transmissionData, setTransmissionData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let configurationUrl = "";
        if (selectedConfig) {
          configurationUrl += `&configuration=${selectedConfig?.id}`;
        }
        const response = await axios.get(
          `${DefaultConfig.API_URL}/transmission?product=${product?.id}&variant=${selectedVariant?.id}${configurationUrl}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );

        setTransmissionData(response.data.data);
        if (selectedTransmission === null) {
          handleSelectTransmission(
            response.data.data.length > 0 ? response.data.data[0] : null
          );
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [product, selectedVariant]);

  const isMobile = window.innerWidth <= 991;
  const settings = {
    showStatus: false, // hide dots
    showThumbs: false, // hide dots
    showArrows: true, // show arrows
    infiniteLoop: true, // enable infinite loop
    useKeyboardArrows: true, // enable keyboard navigation
    emulateTouch: true, // enable touch swipe
    swipeable: true, // enable swipe
    dynamicHeight: false, // maintain the height of the carousel items
    centerMode: false, // center the current item
    centerSlidePercentage: 100, // percentage width of the centered slide (if centerMode is true)
    interval: 5000, // time between automatic transitions (set to 0 to disable auto-play)
    transitionTime: 500, // time it takes to slide to the next item (in milliseconds)
  };

  return (
    <TransmissionWrapper>
      {isMobile ? (
        <div className="variant-card">
          {loading ? (
            <div className="loader"></div>
          ) : (
            <Carousel {...settings}>
              {transmissionData.map((item, index) => (
                <div className="mb-4" key={item.id}>
                  <div
                    className={`variant-inner ${
                      selectedTransmission?.id === item.id ? "selected   " : ""
                    }`}
                  >
                    <div className="variant-top">
                      <p className="numbers">
                        {index + 1}/{transmissionData.length}
                      </p>
                    </div>
                    <div className="variant-img">
                      <img
                        src={item?.thumbnail_url}
                        alt="image"
                        className="w-100"
                      />
                    </div>
                    <div className="variant-info">
                      <p className="name">{item?.name}</p>
                      <p
                        className="content"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                      <div className="detail-price">
                        <p className="start-from">
                          +Rp {formatAmount(item.price)} <span>msrp</span>{" "}
                        </p>
                      </div>
                      <button
                        className={`select-btn ${
                          selectedTransmission?.id === item.id ? "active" : ""
                        }`}
                        onClick={() => handleSelectTransmission(item)}
                      >
                        {selectedTransmission?.id === item.id ? (
                          <>
                            Added <Check />
                          </>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          )}
        </div>
      ) : (
        <div className="variant-card">
          {loading ? (
            <div className="loader"></div>
          ) : (
            <Row>
              {transmissionData.length > 0
                ? transmissionData.map((item, index) => (
                    <Col lg={4} xl={3} className="mb-4" key={item.id}>
                      <div
                        className={`variant-inner ${
                          selectedTransmission?.id === item.id
                            ? "selected   "
                            : ""
                        }`}
                      >
                        <div className="variant-top">
                          <p className="numbers">
                            {index + 1}/{transmissionData.length}
                          </p>
                        </div>
                        <div className="variant-img">
                          <img
                            src={item?.thumbnail_url}
                            alt="image"
                            className="w-100"
                          />
                        </div>
                        <div className="variant-info">
                          <p className="name">{item?.name}</p>
                          <p
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></p>
                          <div className="detail-price">
                            <p className="start-from">
                              +Rp {formatAmount(item.price)} <span>msrp</span>{" "}
                            </p>
                          </div>
                          <button
                            className={`select-btn ${
                              selectedTransmission?.id === item.id
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleSelectTransmission(item)}
                          >
                            {selectedTransmission?.id === item.id ? (
                              <>
                                Added <Check />
                              </>
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </div>
                    </Col>
                  ))
                : "Not Transmission found."}
            </Row>
          )}
        </div>
      )}
    </TransmissionWrapper>
  );
};

const TransmissionWrapper = styled.div`
  .variant-card {
    position: relative;
    padding: 40px 0;
    .row {
      justify-content: center;
    }
  }
  .variant-inner {
    border-radius: 10px;
    border: 2px solid transparent;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.6);
    padding: 20px 20px 80px 20px;
    height: 100%;
    transition: all 0.4s;
    position: relative;
    @media screen and (max-width: 575px) {
      padding: 10px 10px 80px 10px;
    }
    &.selected {
      border: 2px solid #d71921;
    }
    .variant-top {
      p.numbers {
        color: #a2a3a5;
        font-size: 12px;
        text-align: right;
        margin-bottom: 10px;
      }
    }
    .variant-img img {
      height: 185px;
      object-fit: contain;
    }
    .variant-info {
      p.name {
        color: #161a1d;
        font-size: 24px;
        margin: 0;
        @media screen and (max-width: 991px) {
          font-size: 24px;
        }
      }
      p.content {
        color: #161a1d;
        font-size: 16px;
        margin-bottom: 0;
        padding-top: 20px;
      }
      .detail-price {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 0;
        p.start-from {
          color: #161a1d;
          font-size: 16px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          span {
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            display: inline-block;
            margin-left: 2px;
          }
        }
      }

      .select-btn {
        border-radius: 4px;
        border: 1px solid #161a1d;
        background: #fff;
        padding: 12px 12px 12px 30px;
        display: block;
        text-transform: capitalize;
        width: 115px;
        transition: width ease 0.7s;
        position: relative;
        display: flex;
        align-items: center;
        bottom: 30px;
        position: absolute;
        &.active {
          width: 150px;
          color: #fff;
          background: linear-gradient(0deg, #d71921 0%, #d71921 100%), #fff;
          border-color: #d71921;
          svg {
            margin-left: 8px;
          }
        }
      }
      .popup .select-btn {
        position: unset;
        bottom: 0;
      }
    }
  }
  .carousel {
    .slide {
      padding: 0 42px;
      text-align: left;
    }
    .control-dots {
      display: none;
    }
    .control-prev,
    .control-next {
      font-size: 20px;
      color: #3498db;
    }
    .thumbs-wrapper {
      display: none;
    }
    .carousel-status {
      display: none;
    }
    .control-arrow {
      opacity: 1;
    }
    .control-prev:before,
    .control-next:before {
      display: none;
    }
    .control-arrow {
      display: inline-block;
      width: 35px;
      height: 35px;
      background: url(${RightArrow}) no-repeat center center !important;
      padding: 10px;
      border-radius: 50%;
      background-color: #161a1d !important;
      border: none;
      background-size: 10px;
      background-position: center center;
      position: absolute;
      top: 40%;
      border: 1px solid #161a1d;
    }
    .control-prev.control-arrow {
      background: url(${RightArrow}) no-repeat center center !important;
      transform: rotate(180deg);
      background-color: #161a1d !important;
      background-size: 10px;
      background-position: center center;
    }
    .control-disabled.control-arrow {
      cursor: inherit;
      display: block;
    }
    &.carousel-slider .control-arrow:hover {
      background-color: #161a1d;
    }
  }
`;

export default Transmission;
