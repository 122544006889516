import { useState, useRef } from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from "styled-components";
import Times from "../../Assets/times";
import { Row, Col } from "react-bootstrap";
import { formatAmount } from "../../Utils/Global";
import Image1 from "../../Assets/car.png";
import Image2 from "../../Assets/before.png";
import { RightArrow } from "../../Assets/right-arrow";


const CookiePopup = () =>
{
  const [ isOpen, setIsOpen ] = useState( true );
  const [ activeIndex, setActiveIndex ] = useState( 0 );
  const [ isExpanded, setIsExpanded ] = useState( false );
  const images = [ Image1, Image2, Image1, Image2, Image1, Image2, Image1, Image2 ];
  const handleClose = () =>
  {
    setIsExpanded( false );
    setTimeout( () =>
    {
      setIsOpen( false );
    }, 500 ); // Add a delay to wait for the closing animation to complete
  };
  const handleExpand = () =>
  {
    setIsExpanded( !isExpanded );
  };
  const handleNextSlide = () =>
  {
    if ( activeIndex < images.length - 1 )
    {
      setActiveIndex( ( prevIndex ) => prevIndex + 1 );
    }
  };

  const handlePrevSlide = () =>
  {
    if ( activeIndex > 0 )
    {
      setActiveIndex( ( prevIndex ) => prevIndex - 1 );
    }
  };

  return (
    <>
      <CookiePopupwrapper>
        { isOpen && (
          <div className={ `container ${ isExpanded ? 'expanded' : 'not-expanded' }` }>
            <div className="cookie-popup">
              <div className="cookie-popup-content">
                <button className="cookie-close-btn" onClick={ handleExpand }>
                  <span><Times /></span>
                </button>
                <div className="cookie-popup-content-inner">
                  <h3>Pick Up Where You Left Off</h3>
                  <Row>
                    <Col lg={ 12 } xl={ 4 }>
                      <Carousel
                        selectedItem={ activeIndex }
                        infiniteLoop={ false }
                        autoPlay={ true }
                        interval={ 3000 }
                        showThumbs={ false }
                        swipeable={ true }
                        emulateTouch={ true }
                        showStatus={ false }
                        showArrows={ false }
                      >
                        { images.map( ( image, index ) => (
                          <div key={ index }>
                            <img src={ image } alt={ `Slide ${ index + 1 }` } />
                          </div>
                        ) ) }
                      </Carousel>
                    </Col>
                    <Col sm={ 12 } lg={ 6 } xl={ 6 } className="mt-xl-0 mt-3 cookie-col-2" style={ { display: "flex", justifyContent: "space-around" } }>
                      <div>
                        <p className="latest-update">LATEST BUILD <span>UPDATE 12/07/2023</span></p>
                        <div className="cookie-product-name">
                          <h4>All New Alphard</h4>
                          <h4>Hybrid Type</h4>
                          <div className="custom-carousel-arrows ">
                            <div className="control-arrow prev">
                              {/* Left arrow */ }
                              <button onClick={ handlePrevSlide } disabled={ activeIndex === 0 }>
                                <span><RightArrow /></span>
                              </button>
                            </div>
                            <div className="number">{ activeIndex + 1 } of { images.length }</div>
                            <div className="control-arrow next">
                              {/* Right arrow */ }
                              <button onClick={ handleNextSlide } disabled={ activeIndex === images.length - 1 }>
                                <span><RightArrow /></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cookie-amount">
                        <p>Total Amount</p>
                        <h5>Rp { formatAmount( 1356100000 ) }</h5>
                      </div>
                    </Col>
                    <Col sm={ 12 } lg={ 6 } xl={ 2 } style={ { display: "flex", alignItems: "flex-start", marginTop: "20px" } }>
                      <div className="cookie-build-btn">
                        <button>
                          Continue Build
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ) }
      </CookiePopupwrapper>
    </>
  );
}

const CookiePopupwrapper = styled.div`
  .not-expanded{
    .cookie-popup{
      max-width:400px;
      height:90px;
      transition: height 0.5s ease, max-width 0.5s ease;
      h3{
        font-size:16px;
      }
      button.cookie-close-btn span svg {
        transform: rotateZ(45deg);
      }
      @media screen and (max-width:575px){
        max-width:100%;
      }
    }
    .cookie-popup-content-inner > .row{
      display:none;
    }
  }
  .cookie-popup {
    border-radius: 10px 10px 0px 0px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(162, 163, 165, 0.40);
    width: 100%;
    max-width: 80%;
    margin-right:10%;
    margin-left:10%;
    right:0;
    left:unset;
    transform:none;
    position: fixed; 
    bottom: 0;
    padding: 0 20px 20px;
    height:320px;
    transition: height 0.5s ease, max-width 0.5s ease;
    z-index:99999;
    @media screen and (max-width:1199px){
      max-width: 100%;
      margin: 0;
      height:auto; 
    }
    .cookie-popup-content-inner {
      .row {
        padding: 20px 0 0;
        .cookie-col-2{
          @media screen and (max-width:767px){
            flex-wrap:wrap;
          }
          &>div{
            @media screen and (max-width:767px){
              width:100%;
            }
          }
        }
      }
    }

    .cookie-close-btn {
      padding: 5px 10px;
      border: none;
      border-radius: 3px;
      background: transparent;
      z-index: 9;
      position: relative;
      top: 30px;
      right: 0px;
      text-align: right;
      width: 100%;
      cursor:auto;
      & svg{cursor:pointer;}
      & svg:hover path{
        fill:#d71921;
      }
    }

    h3 {
      color: #161a1d;
      font-size: 24px;
      border-bottom: 1px solid #161a1d;
      padding-bottom: 15px;
      display: block;
      margin-bottom:0;
    }

    .latest-update {
      color: #161a1d;
      font-size: 16px;
      margin-bottom: 10px;
      span {
        color: #d71921;
      }
    }

    .cookie-product-name {
      h4 {
        color: #161a1d;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }

    .cookie-amount {
      position: relative;
      &:before {
        content: "";
        width: 2px;
        height: 60%;
        background: #d9dadb;
        position: absolute;
        left: -27px;
        top: 0;
      }
      p {
        margin-bottom: 10px;
      }
      h5 {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .cookie-build-btn {
      width: 100%;
      button {
        border-radius: 5px;
        background: #d71921;
        border: 1px solid #d71921;
        color: #fff;
        text-align: center;
        font-weight: 700;
        padding: 14px 10px;
        width: 100%;
        &:hover{
          background: #AC141A;
        }
      }
    }

    .carousel {
      img {
        height: 200px;
        object-fit: contain;
      }
      .carousel-status {
        display: none;
      }
      .thumbs-wrapper {
        display: none;
      }
      .control-dots{
        bottom:-15px;
      }
      .control-dots li {
        display: inline-block;
        position: relative;
        padding: 0;
        cursor: pointer;
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 100px;
        background: #d9dadb;
        border: 2px solid transparent;
        box-shadow: none;
        opacity:1 !important;
        &.selected {
          border: 1px solid #d71921;
        }
      }
    }
    .custom-carousel-arrows{
        display:flex;
        align-items:center;
        margin-top:30px;
        @media screen and (max-width:767px){
          display:none;
        }
        .control-arrow{
          button{
            background:none;
            border:0;
          }
          &.prev{
            transform:rotateY(180deg);
          }
          &:hover  svg >path{
            fill: #D71921;
          }
        }
        .number {
          padding: 0 20px;
        }
      } 
  }
`;


export default CookiePopup;