import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap'
import { TopArrow } from '../../Assets/top-arrow';
import { FooterStyle } from './style';
import { Whatsapp } from '../../Assets/whatsapp';
import { Instagram } from '../../Assets/instagram';
import { Facebook } from '../../Assets/facebook';
import { Twitter } from '../../Assets/twitter';
import { Youtube } from '../../Assets/youtube';
import { Tiktok } from '../../Assets/tiktok';
import customerCareImg from '../../Assets/customer-care.png';
import { RightArrow } from '../../Assets/right-arrow';

export const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const location = useLocation();
    const isLinkActive = (path) => {
        return location.pathname === path;
    };

    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: 'Beyond Solution',
            links: ['Toyota Spektakuler', 'Toyota EZ Deal', 'Kinto', 'Toyota Financial Service', 'Get Your Offer', 'Toyota Trade In', 'Form Inquiry', 'Pricelist'],
        },
        {
            title: 'Beyond Experience',
            links: ['E-brochure', 'Toyota Live Showroom', 'Toyota Interactive Virtual Assistant', 'Toyota Official Store Solution', 'Find Dealer', 'Booking Service', 'Aftersales', 'Toyota Mobile Service', 'T-Care', 'mToyota', 'Toyota Genuine Accessories', 'Special Service Campaign'],
        },
        {
            title: 'Beyond Technology',
            links: ['T-intouch', 'Toyota Safety Sense', 'TNGA', 'Engine', 'Android Auto'],
        },
        {
            title: 'Beyond Sustainability',
            links: ['ITFE', 'Toyota Berbagi'],
        },
        {
            title: 'More',
            links: ['Pressroom', 'Owners Manual', 'News & Information'],
        },
    ];
    return (
        <FooterStyle>
            <div className='py-2'>
                <div className='back-top-top'>
                    <p onClick={scrollToTop} >Back to top <TopArrow /></p>
                </div>
            </div>
            <div className='footer-main'>
                <div className='container'>
                    <Row>
                        <Col lg={9} className='d-none d-lg-block'>
                            <Row className='justify-content-between'>
                                <Col lg={2}>
                                    <div className='footer-links'>
                                        <h4>Beyond Solution</h4>
                                        <ul>
                                            <li><a href="" >Toyota Spektakuler</a></li>
                                            <li><a href="" >Toyota EZ Deal</a></li>
                                            <li><a href="" >Kinto</a></li>
                                            <li><a href="" >Toyota Financial Service</a></li>
                                            <li><a href="" >Get Your Offer</a></li>
                                            <li><a href="" >Toyota Trade In</a></li>
                                            <li><a href="" >Form Inquiry</a></li>
                                            <li><a href="" >Pricelist</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className='footer-links'>
                                        <h4>Beyond Experience</h4>
                                        <ul>
                                            <li><a href="" >E-brochure</a></li>
                                            <li><a href="" >Toyota Live Showroom</a></li>
                                            <li><a href="" >Toyota Interactive Virtual Assistant</a></li>
                                            <li><a href="" >Toyota Official Store Solution</a></li>
                                            <li><a href="" >Find Dealer</a></li>
                                            <li><a href="" >Booking Service</a></li>
                                            <li><a href="" >Aftersales</a></li>
                                            <li><a href="" >Toyota Mobile Service</a></li>
                                            <li><a href="" >T-Care</a></li>
                                            <li><a href="" >mToyota</a></li>
                                            <li><a href="" >Toyota Genuine Accessories</a></li>
                                            <li><a href="" >Special Service Campaign</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className='footer-links'>
                                        <h4>Beyond Technology</h4>
                                        <ul>
                                            <li><a href="" >T-intouch</a></li>
                                            <li><a href="" >Toyota Safety Sense</a></li>
                                            <li><a href="" >TNGA</a></li>
                                            <li><a href="" >Engine</a></li>
                                            <li><a href="" >Android Auto</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className='footer-links'>
                                        <h4>Beyond Sustainibility</h4>
                                        <ul>
                                            <li><a href="" >ITFE</a></li>
                                            <li><a href="" >Toyota Berbagi</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={2}>
                                    <div className='footer-links'>
                                        <h4>More</h4>
                                        <ul>
                                            <li><a href="" >Pressroom</a></li>
                                            <li><a href="" >Owners Manual</a></li>
                                            <li><a href="" >News & Information</a></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3}>
                            <div className='join-us'>
                                <h4>Join us on</h4>
                                <div className='social-icon'>
                                    <a href=""><Whatsapp /></a>
                                    <a href=""><Instagram /></a>
                                    <a href=""><Facebook /></a>
                                    <a href=""><Twitter /></a>
                                    <a href=""><Youtube /></a>
                                    <a href=""><Tiktok /></a>

                                </div>
                                <div className='customer-care'>
                                    <img src={customerCareImg} alt="customer care" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='for-mob'>
                        <Row className='justify-content-between'>
                            {accordionData.map((section, index) => (
                                <Col lg={12} key={index}>
                                    <div
                                        className={`footer-links ${activeIndex === index ? 'active' : ''}`}
                                        onClick={() => handleAccordionClick(index)}
                                    >
                                        <h4>
                                            {section.title} <span><RightArrow /></span>
                                        </h4>
                                        {activeIndex === index && (
                                            <ul className='accordion-content'>
                                                {section.links.map((link, linkIndex) => (
                                                    <li key={linkIndex}><a href=''>{link}</a></li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='container'>
                    <p>©2023 Toyota Astra Motor</p>
                    <p>Astra Motor All information applies to Indonesia vehicles only</p>
                    <div className='footer-bottom-links'>
                        <ul>
                            <li><a href='#'>Privacy Policy</a></li>
                            <li><a href='#'>Legal Cookie</a></li>
                            <li><a href='#'>Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </FooterStyle>
    );
};
