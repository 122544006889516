import React from "react";
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import Image360 from "../../../Assets/chess-world.jpg";
import styled from 'styled-components';


const Image360Viewer = () => {
  return (
    <>
    <ViewerContainer>
      <ReactPhotoSphereViewer
        src={Image360}
        config={{
          minFov: 1,
          maxFov: 5,
          defaultZoomLvl: 3,
          defaultYaw: 90,
          defaultPitch: 0,
        }}
        height={'100%'}
        width={'100%'}
      />
    </ViewerContainer>
    </>
  );
};

// Styled component for the container div
const ViewerContainer = styled.div`
  height: 500px;
  width: 100%;
  max-width:600px;
  margin:0 auto;
`;

export default Image360Viewer;