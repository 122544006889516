import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import logo from "../../Assets/logo.png";
export const Header = () => {

    return (
        <HeaderSection className='py-2'>
            <Container fluid>
                <div className="logoMain">
                    <a href={process.env.REACT_APP_BASE_URL}>
                        <img src={logo} alt="img" />
                    </a>
                </div>
            </Container>
        </HeaderSection>
    );
};

const HeaderSection = styled.header`
    position:fixed;
    z-index:10;
    background-color:#fff;
    width:100%;
    top:0;
    left:0;
`;