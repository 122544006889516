import React from "react";

export const RightArrow = () => {
    return (
        <>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.75 0.21875C0.90625 0.0625 1.125 0.0625 1.28125 0.21875L7.84375 6.75C7.96875 6.90625 7.96875 7.125 7.84375 7.28125L1.28125 13.8125C1.125 13.9688 0.90625 13.9688 0.75 13.8125L0.125 13.2188C0 13.0625 0 12.8125 0.125 12.6875L5.78125 7L0.125 1.34375C0 1.21875 0 0.96875 0.125 0.8125L0.75 0.21875Z" fill="#161A1D" />
            </svg>
        </>
    );
};