import React from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import Image1 from "../../../Assets/after.png";
import Image2 from "../../../Assets/before.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RightArrow from '../../../Assets/Vector.svg';
import styled from 'styled-components';
import CompareIcon from "../../../Assets/Compare.svg";
import StateIcon from "../../../Assets/State-icon.png";

const BeforeAfterSlider = ({ openPopup, openBeforeAfterPopup, isOpenBeforeAfter}) => {

  const BeforeAfterImages = [
    {
      FIRST_IMAGE: {imageUrl: Image1},
      SECOND_IMAGE: {imageUrl: Image2}
    },
    {
      FIRST_IMAGE: {imageUrl: Image1},
      SECOND_IMAGE: {imageUrl: Image2}
    },
    {
      FIRST_IMAGE: {imageUrl: Image1},
      SECOND_IMAGE: {imageUrl: Image2}
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    draggable: false,
    autoplaySpeed: 2000,
    prevArrow: null, // Hide previous arrow
    nextArrow: null, // Hide next arrow
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return (
        <div>
          <div className="custom-pagination">
            {dots.length > 0 ? <div className='exterior'>
            <strong>Compare</strong>
              <ul>{dots}</ul>
            </div> : null}
            <Button360 className='360-view' onClick={openPopup}>
              360° view
            </Button360>
            <CompareWrapper className={isOpenBeforeAfter && 'active' } onClick={openBeforeAfterPopup}>
              <img src={CompareIcon} alt="compare" />
            </CompareWrapper>
          </div>
        </div>
      );
    },
    customPaging: i => (
      <span></span>
    )
  };

  return (
    <BeforeAfterSliderWrapper>
      <Slider {...settings}>
        {BeforeAfterImages.map((thumbnail, index) => (
          <ReactBeforeSliderComponent
            firstImage={thumbnail.FIRST_IMAGE}
            secondImage={thumbnail.SECOND_IMAGE}
          />
        ))}
      </Slider>

    </BeforeAfterSliderWrapper>
  );
};
const BeforeAfterSliderWrapper = styled.div`
  .slick-arrow:before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url(${RightArrow}) no-repeat center center;
    border: 1px solid #161A1D;
    padding: 10px;
    border-radius: 50%;
  }
  .slick-arrow:hover:before{
    filter:invert(7%) sepia(65%) saturate(5943%) hue-rotate(354deg) brightness(107%) contrast(157%);
  }
  .slick-prev:before {
    background: url(${RightArrow}) no-repeat center center;
    transform:rotate(180deg);
  }
  .slick-prev {
    left: -55px;
  }
  .slick-dots {
    position: relative;
    bottom:unset;
    text-align: center;
    .custom-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      .interior,
      .exterior {
        display:flex;
        justify-content: center;
        fle-wrap:wrap;
        strong {
          color: #161A1D;
          font-family: 'ToyotaType';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0 5px;
        }

        ul {
          padding: 0;
          list-style-type: none;
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            width: auto;
            height: auto;
            span {
              display:inline-block;
              position: relative;
              padding: 0;
              cursor: pointer;
              width: 12px;
              height: 12px;
              flex-shrink: 0;
              border-radius: 100px;
              background: #D9DADB;
              border: 2px solid transparent;
              @media screen and (max-width:575px){
                width:8px;
                height:8px;
              }
            }
            &.slick-active span{
              border-radius: 100px;
              border: 2px solid #D71921;
            }
          }
        }
      }
    }
  }
  .before-after-slider__delimiter-icon {
    background: url(${StateIcon}) no-repeat center center;
    width: 45px;
    height: 45px;
    border: 1px solid #161a1d;
  }
  .before-after-slider__delimiter {
    width: 2px;
    background-color: #D9DADB !important;
}

`;

const Button360 = styled.button`
  border-radius: 100px;
  border: 1px solid #D0D1D2;
  color: #161A1D;
  font-size: 14px;
  padding:3px 10px;
  margin-left:5px;
  background:transparent;
  &:hover{
    color:#D71921;
    border-color:#D71921;
  }
`;

const CompareWrapper = styled.div`
  position:relative;
  border-radius: 100px;
  border: 1px solid #D0D1D2;
  margin-left: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  &.active {
    background: red !important; /* Use !important to increase specificity */
    border-radius: 100px;
    border: 1px solid #D0D1D2;
    margin-left: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 24px;
    img {
      filter: invert(1);
    }
  }
`;

export default BeforeAfterSlider;