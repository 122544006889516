import React from "react";

export const Youtube = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#161A1D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.18 10.612C32.4039 10.9415 33.3689 11.9065 33.6984 13.1304C34.3104 15.3664 34.2868 20.0268 34.2868 20.0268C34.2868 20.0268 34.2868 24.6636 33.6984 26.8996C33.3689 28.1235 32.4039 29.0885 31.18 29.418C28.9439 30.0065 19.9999 30.0065 19.9999 30.0065C19.9999 30.0065 11.0793 30.0065 8.81978 29.3945C7.59585 29.065 6.63083 28.1 6.30132 26.876C5.71289 24.6636 5.71289 20.0032 5.71289 20.0032C5.71289 20.0032 5.71289 15.3664 6.30132 13.1304C6.63083 11.9065 7.61939 10.9179 8.81978 10.5884C11.0558 10 19.9999 10 19.9999 10C19.9999 10 28.9439 10 31.18 10.612ZM24.59 20.0032L17.1523 24.287V15.7195L24.59 20.0032Z" fill="#161A1D" />
            </svg>
        </>
    );
};