import React from 'react';
import './App.css';
import './Assets/Fonts/stylesheet.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './Utils/Router.js';



function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;
