import React, { useState, useRef, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../Components/Layout";
import axios from "axios";
import DefaultConfig from "../../Utils/Config";
import { useParams } from "react-router-dom";
import ProductSlider from "./ProductSlider";
import MultiStepTabs from "./Steps";
import Image360Viewer from "./Image360Viewer";
import { ProductDetail } from "./style";
import { Helmet } from "react-helmet";
import { formatAmount } from "../../Utils/Global";
import Popup from "../../Components/Popup";
import styled from "styled-components";
import RightArrow from "../../Assets/Vector.svg";
import { useSelectedProducts } from "../../Utils/Global";
import Pen from "../../Assets/pen";
import { CaretDown } from "../../Assets/caret-down";
import { Location } from "../../Assets/location";
import Logo from "../../Assets/logo.png";
import { Dealer } from "../../Assets/dealer";

const Product = () =>
{
  const { slug } = useParams();

  const {
    getProductById,
    addProduct,
    removeProduct,
    updateProduct,
    CreateCookie,
    GetCookie,
  } = useSelectedProducts();
  const GetDealer = GetCookie( "dealer" );
  let CookieDealer = GetDealer ? GetDealer : 0;
  const CookieCity = GetCookie( "city" );

  const [ productIdData, setProductIdData ] = useState( [] );
  const [ popupOverviewloading, setPopupOverviewloading ] = useState( false );
  const [ OverviewCategory, setOverviewCategory ] = useState( [] );
  const [ activeStepTab, setActiveStepTab ] = useState( 1 );
  const [ selectedVariant, setSelectedVariant ] = useState( [] );
  const [ selectedConfig, setSelectedConfig ] = useState( null );
  const [ selectedTransmission, setSelectedTransmission ] = useState( null );
  const [ selectedColor, setSelectedColor ] = useState( [] );
  const [ selectedPackages, setSelectedPackages ] = useState( [] );
  const [ selectedCustoms, setSelectedCustoms ] = useState( [] );
  const [ selectedTCare, setSelectedTCare ] = useState( [] );
  const [ formData, setFormData ] = useState( [] );
  const [ SelectedOverviewCategory, setSelectedOverviewCategory ] = useState( [] );
  const [ selectedOverViewTab, setSelectedOverViewTab ] = useState( "engine" );
  const [ engineInfo, setEngineInfo ] = useState( [] );
  const [ isOpen, setIsOpen ] = useState( false );
  const [ OfferPopup, setIsOfferPopup ] = useState( false );
  const [ variantImage, setVariantImage ] = useState( [] );

  //city-dealer popup
  const [ isLocationOpen, setIsLocationOpen ] = useState( false );
  const [ isDealerOpen, setIsDealerOpen ] = useState( false );
  const [ selectedDealer, setSelectedDealer ] = useState( CookieDealer );
  const [ selectedLocation, setSelectedLocation ] = useState( CookieCity );
  const [ dealerData, setDealerData ] = useState( [] );
  const [ cityData, setCityData ] = useState( [] );
  const [ isDpopupOpen, setIsDpopupOpen ] = useState( false );
  const [ selectedImageIndex, setSelectedImageIndex ] = useState( 0 );
  const [ isButtonVisible, setButtonVisible ] = useState( true );
  const carouselRef = useRef( null );
  const CookieProduct = getProductById( productIdData.id );
  const currentTimestamp = new Date().toISOString();
  const [ isCaretDownClicked, setIsCaretDownClicked ] = useState( false );
  const [ isCaretDownClicked1, setIsCaretDownClicked1 ] = useState( false );
  const [ hasConfiguration, setHasConfiguration ] = useState( false );
  const [ hasAftersale, setHasAftersale ] = useState( false );
  const [ engineSpecs, setEngineSpecs ] = useState( [] );


  useEffect( () =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get(
          `${ DefaultConfig.API_URL }/configuration?product=${ productIdData?.id }&variant=${ selectedVariant?.id }`,
          {
            headers: {
              Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
            },
          }
        );

        if ( response.data.data.length > 0 )
        {
          setHasConfiguration( true );
        } else
        {
          setHasConfiguration( false );
        }
      } catch ( error )
      {
        console.error( error );
      }

      try
      {
        const response = await axios.get(
          `${ DefaultConfig.API_URL }/tcare?variant=${ selectedVariant.id }`,
          {
            headers: {
              Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
            },
          }
        );

        if ( response.data.data.length > 0 )
        {
          setHasAftersale( true );
        } else
        {
          setHasAftersale( false );
        }
      } catch ( error )
      {
        console.error( error );
      }
    };

    fetchData();
  }, [ productIdData, selectedVariant ] );

  useEffect( () =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get( `${ DefaultConfig.API_URL }/dealer`, {
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        } );
        // if (!CookieDealer || !CookieCity) {
        //     setIsOpen(true);
        // }
        setDealerData( response.data );
      } catch ( error )
      {
        console.error( error );
      }
    };

    fetchData();
  }, [] );

  useEffect( () =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get( `${ DefaultConfig.API_URL }/city`, {
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        } );

        setCityData( response.data );
      } catch ( error )
      {
        console.error( error );
      }
    };

    fetchData();
  }, [] );

  const handleStepTab = ( tabNumber ) =>
  {
    setActiveStepTab( tabNumber );
  };

  const handleSelectConfig = ( id ) =>
  {
    setSelectedConfig( id );
    fetchVariantImage( selectedVariant?.id, id );
  };

  const handleSelectTransmission = ( transmission ) =>
  {
    setSelectedTransmission( transmission );
    fetchEngine( selectedVariant?.id );
  };

  const handleCaretDownClick = () =>
  {
    setIsCaretDownClicked( ( prev ) => !prev );
  };

  const handleCaretDownClick1 = () =>
  {
    setIsCaretDownClicked1( ( prev ) => !prev );
  };

  const handleThumbnailClick = ( index ) =>
  {
    setSelectedImageIndex( index );
  };

  const handleCarouselChange = ( index ) =>
  {
    setSelectedImageIndex( index );
    setButtonVisible( true );
  };

  const handleCarouselClick = ( type ) =>
  {
    const totalImages = SelectedOverviewCategory.length;
    let newIndex = selectedImageIndex;

    if ( type === "next" )
    {
      newIndex = ( selectedImageIndex + 1 ) % totalImages;
    } else if ( type === "prev" )
    {
      newIndex = ( selectedImageIndex - 1 + totalImages ) % totalImages;
    }

    setSelectedImageIndex( newIndex );
  };

  const handleVideoPlay = () =>
  {
    const videoElement = document.getElementById( `video_${ selectedImageIndex }` );
    if ( videoElement )
    {
      videoElement.play();
      setButtonVisible( false ); // Hide the button after clicking
    }
  };

  useEffect( () =>
  {
    const carousel = carouselRef.current;
    if ( carousel )
    {
      carousel.controlPrev = () => handleCarouselClick( "prev" );
      carousel.controlNext = () => handleCarouselClick( "next" );
    }
  }, [ selectedImageIndex ] );

  const openPopup = () =>
  {
    setIsOpen( true );
  };

  const DpopupOpen = () =>
  {
    setIsDpopupOpen( true );
  };

  const getOfferPopup = () =>
  {
    setIsOfferPopup( true );
  };

  const handleSelectColor = ( color ) =>
  {
    setSelectedColor( color );
    fetchVariantImage( selectedVariant?.id, selectedConfig, color );
  };

  const handleSelectPackage = ( selectedPackage ) => () =>
  {
    const index = selectedPackages.indexOf( selectedPackage );
    let updatedSelection = [];

    if ( index !== -1 )
    {
      // If the ID is already selected, remove it
      updatedSelection = selectedPackages.filter(
        ( itemId ) => itemId !== selectedPackage
      );
    } else
    {
      // If the ID is not selected, add it to the selection
      updatedSelection = [ ...selectedPackages, selectedPackage ];
    }

    setSelectedPackages( updatedSelection );
  };

  const getPackagesTotalPrice = () =>
  {
    let totalPrice = 0;
    for ( let selectedPackage of selectedPackages )
    {
      totalPrice += parseInt( selectedPackage.price );
    }
    return totalPrice;
  };

  const handleSelectCustom = ( selectedCustom ) => () =>
  {
    const index = selectedCustoms.indexOf( selectedCustom );
    let updatedSelection = [];

    if ( index !== -1 )
    {
      // If the ID is already selected, remove it
      updatedSelection = selectedCustoms.filter(
        ( itemId ) => itemId !== selectedCustom
      );
    } else
    {
      // If the ID is not selected, add it to the selection
      updatedSelection = [ ...selectedCustoms, selectedCustom ];
    }

    setSelectedCustoms( updatedSelection );
  };

  const getCustomsTotalPrice = () =>
  {
    let totalPrice = 0;
    for ( let selectedCustom of selectedCustoms )
    {
      totalPrice += parseInt( selectedCustom.price );
    }
    return totalPrice;
  };

  const handleSelectedVariant = ( variant ) => () =>
  {
    setSelectedVariant( variant );
    fetchVariantImage( variant?.id );
    fetchEngine( variant?.id );
    if ( !CookieProduct || CookieProduct.length === 0 )
    {
    } else
    {
      // Create additional data including the selectedVariant and lastUpdated
      let additionalData = {
        selectedVariant: variant?.id,
        lastUpdated: currentTimestamp,
      };
      let oldData = CookieProduct;
      Object.assign( oldData, additionalData );
      updateProduct( productIdData.id, oldData );
    }

    // remove selected Configuration and Transmission
    handleSelectConfig( null );
    handleSelectTransmission( null );
  };

  const handleSelectAfterSales = ( selectedAfterSalesSingle ) =>
  {
    const index = selectedTCare.indexOf( selectedAfterSalesSingle );
    let updatedSelection = [];

    if ( index !== -1 )
    {
      // If the ID is already selected, remove it
      updatedSelection = selectedTCare.filter(
        ( itemId ) => itemId !== selectedAfterSalesSingle
      );
    } else
    {
      // If the ID is not selected, add it to the selection
      updatedSelection = [ ...selectedTCare, selectedAfterSalesSingle ];
    }

    setSelectedTCare( updatedSelection );
  };

  const fetchOverviewDataByCategory = async ( index ) =>
  {
    try
    {
      setPopupOverviewloading( true );
      const response = await axios.get(
        `${ DefaultConfig.API_URL }/feature?category=${ index }&variant=${ selectedVariant?.id }&transmission=${ selectedTransmission?.id }`,
        {
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        }
      );
      setSelectedOverviewCategory( response.data.data );
      setPopupOverviewloading( false );
    } catch ( error )
    {
      console.error( error );
      setPopupOverviewloading( false );
    }
  };

  const fetchVariantImage = async (
    variantID,
    configurationID = null,
    colorID = null
  ) =>
  {
    try
    {
      const params = {
        variant: variantID,
      };

      // Add configuration and color parameters only if they are not null
      if ( configurationID !== null )
      {
        params.configuration = configurationID;
      }

      if ( colorID !== null )
      {
        params.color = colorID;
      }

      const response = await axios.get(
        `${ DefaultConfig.API_URL }/product-image`,
        {
          params: params,
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        }
      );
      setVariantImage( response.data.data );
    } catch ( error )
    {
      console.error( error );
    }
  };

  const fetchEngine = async ( variant_id ) =>
  {
    try
    {
      const response = await axios.get(
        `${ DefaultConfig.API_URL }/engine?variant=${ variant_id }&transmission=${ selectedTransmission?.id }`,
        {
          headers: {
            Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
          },
        }
      );
      setEngineInfo( response.data.data );
      fetchEngineSpecs( response.data.data.id );

    } catch ( error )
    {
      console.error( error );
    }
  };

  const fetchEngineSpecs = async ( engine_id ) =>
  {
    // Check if engine_id is falsy and exit early if it is
    if ( !engine_id )
    {
      console.error( 'Invalid or missing engine ID' );
      return;
    }

    // Construct the URL with the proper endpoint and query parameters
    const url = `${ DefaultConfig.API_URL }/engine-spesification?engine_id=${ engine_id }`;

    try
    {
      const response = await axios.get( url, {
        headers: {
          Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
        },
      } );

      // Log the whole response object to debug
      console.log( 'Received response:', response.data );

      // Check if the data part of the response is as expected
      if ( response.data )
      {
        // Assuming the data needed is within `response.data.data`
        setEngineSpecs( response.data );
      } else
      {
        // Log error if the expected data is not found
        console.error( 'Unexpected response structure:', response );
        setEngineSpecs( [] );  // Set to an empty array or a default state if response is not as expected
      }
    } catch ( error )
    {
      console.error( 'Error fetching engine specifications:', error );
      setEngineSpecs( [] );  // Consider setting to an empty array in case of error
    }
  };



  const getTotalPrice = () =>
  {
    let totalPrice = 0;

    totalPrice += selectedVariant?.start_from
      ? parseInt( selectedVariant.start_from )
      : 0;
    totalPrice += selectedConfig?.price ? parseInt( selectedConfig.price ) : 0;
    totalPrice += selectedTransmission?.price
      ? parseInt( selectedTransmission.price )
      : 0;
    totalPrice += getPackagesTotalPrice();
    totalPrice += getCustomsTotalPrice();
    return formatAmount( totalPrice );
  };

  useEffect( () =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get(
          `${ DefaultConfig.API_URL }/product/${ slug }`,
          {
            headers: {
              Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
            },
          }
        );
        if ( !CookieProduct || CookieProduct.length === 0 )
        {
          setSelectedVariant( response.data.data.lowest_price_variant );
          fetchVariantImage( response.data.data.lowest_price_variant?.id );
          fetchEngine( response.data.data.lowest_price_variant?.id );
        }
      } catch ( error )
      {
        console.error( error );
      }
    };

    const fetchOverviewCategory = async () =>
    {
      try
      {
        const response = await axios.get(
          `${ DefaultConfig.API_URL }/feature/category`,
          {
            headers: {
              Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
            },
          }
        );

        setOverviewCategory( response.data.data );
      } catch ( error )
      {
        console.error( error );
      }
    };

    fetchData();
    fetchOverviewCategory();
  }, [] );

  const handleTabClick = ( index, name ) =>
  {
    setSelectedOverViewTab( name );
    if ( name !== "engine" )
    {
      fetchOverviewDataByCategory( index );
    }
  };

  const toggleDropdown = () =>
  {
    setIsLocationOpen( !isLocationOpen );
    setIsDealerOpen( false );
  };

  const handleItemClick = ( item ) =>
  {
    const fetchData = async () =>
    {
      try
      {
        const response = await axios.get(
          `${ DefaultConfig.API_URL }/city/${ item.id }`,
          {
            headers: {
              Authorization: `Bearer ${ DefaultConfig.API_TOKEN }`,
            },
          }
        );
        if ( !CookieDealer || !CookieCity )
        {
          setIsOpen( true );
        }
        setDealerData( { data: response.data.data.dealers } );
      } catch ( error )
      {
        console.error( error );
      }
    };
    fetchData();

    setSelectedLocation( item );
    CreateCookie( "city", item );
    setIsLocationOpen( false );
  };

  const toggleDealerDropdown = () =>
  {
    setIsDealerOpen( !isDealerOpen );
  };

  const handleDealerClick = ( item ) =>
  {
    setSelectedDealer( item );
    CreateCookie( "dealer", item );
    setIsDealerOpen( false );
  };

  const specificCategory = productIdData?.engine_category?.find(
    ( category ) => category.id === productIdData?.engine_category_id
  );

  return (
    <>
      <Helmet>
        <title>{ productIdData?.name }</title>
      </Helmet>
      <Layout>
        <div className="product-detail-top mt-5 pt-5">
          <div className="container">
            <ProductDetail>
              <Row>
                <Col lg={ 3 }>
                  <div className="product-intro">
                    <h1>{ productIdData?.name }</h1>
                    <h2>{ selectedVariant.name }</h2>
                    <a
                      onClick={
                        selectedVariant.length !== 0 ? () => openPopup() : null
                      }
                    >
                      Vehicle Overview
                    </a>
                  </div>
                </Col>
                <Col lg={ 6 }>
                  <ProductSlider
                    product={ productIdData }
                    variantImage={ variantImage }
                    selectedVariant={ selectedVariant }
                    hasConfiguration={ hasConfiguration }
                  />
                </Col>
                <Col lg={ 3 }>
                  { selectedVariant?.start_from ? (
                    <div className="price">
                      <p>
                        Rp { formatAmount( selectedVariant?.start_from ) }
                        {/* <span>Base MSRP</span> */ }
                      </p>
                    </div>
                  ) : null }
                  <div className="get-offer">
                    <a onClick={ () => getOfferPopup() }>Get Your Offer</a>
                  </div>
                </Col>
              </Row>
            </ProductDetail>
          </div>
        </div>
        <SelectedDealer>
          <div className="dealer-name" onClick={ DpopupOpen }>
            <Location />
            <p>{ selectedDealer ? selectedDealer.name : "Toyota Dealer" }</p>
            {/* <p>{"Toyota Dealer"}</p> */ }
          </div>
        </SelectedDealer>
        <div className="mt-5 pt-5">
          <div className="container">
            <MultiStepTabs
              product={ productIdData }
              activeStepTab={ activeStepTab }
              handleStepTab={ handleStepTab }
              selectedVariant={ selectedVariant }
              handleSelectedVariant={ handleSelectedVariant }
              selectedConfig={ selectedConfig }
              handleSelectConfig={ handleSelectConfig }
              selectedTransmission={ selectedTransmission }
              handleSelectTransmission={ handleSelectTransmission }
              handleSelectColor={ handleSelectColor }
              selectedColor={ selectedColor }
              selectedPackages={ selectedPackages }
              handleSelectPackage={ handleSelectPackage }
              selectedCustoms={ selectedCustoms }
              handleSelectCustom={ handleSelectCustom }
              selectedTCare={ selectedTCare }
              handleSelectAfterSales={ handleSelectAfterSales }
              CookieCity={ CookieCity }
              CookieDealer={ CookieDealer }
              formData={ formData }
              setFormData={ setFormData }
              hasConfiguration={ hasConfiguration }
              hasAftersale={ hasAftersale }
            />
          </div>
        </div>
        <GoToOffer>
          <Popup
            isOpen={ OfferPopup }
            onClose={ () => setIsOfferPopup( false ) }
            popupContentStyle={ {
              width: "100%",
              height: "700px",
              padding: "0 30px 30px",
              maxWidth: "800px",
              borderRadius: "10px",
            } }
            closeButtonStyle={ {
              padding: "5px 10px",
              border: "none",
              borderRadius: "3px",
              background: "transparent",
              zIndex: "9",
              position: "relative",
              top: "30px",
              right: "0",
              textAlign: "right",
              width: "100%",
            } }
          >
            <OverviewTabWrapper>
              <div className="get-offer-popup">
                <h3>Your Configuration</h3>
                <h4 className="overview-product-name">{ productIdData?.name }</h4>
                <div className="variant-image">
                  <img src={ selectedVariant.image_url } />
                </div>
                <div className="variant-list">
                  <div className="selected-variant">
                    <strong className="type-heading">
                      Variant{ " " }
                      <span
                        onClick={ () =>
                        {
                          setIsOfferPopup( false );
                          handleStepTab( 1 );
                        } }
                      >
                        <Pen />
                      </span>{ " " }
                    </strong>
                    <div className="description">
                      <p>{ selectedVariant.name }</p>
                      <p>
                        Rp{ " " }
                        { selectedVariant?.start_from
                          ? formatAmount( selectedVariant?.start_from )
                          : null }
                      </p>
                    </div>
                  </div>
                  { selectedConfig ? (
                    <div className="selected-transmission">
                      <strong className="type-heading">
                        Configuration{ " " }
                        <span
                          onClick={ () =>
                          {
                            setIsOfferPopup( false );
                            handleStepTab( 2 );
                          } }
                        >
                          <Pen />
                        </span>{ " " }
                      </strong>
                      <div className="description">
                        <p>{ selectedConfig?.name }</p>
                        <p>
                          Rp{ " " }
                          { selectedConfig?.price
                            ? formatAmount( selectedConfig?.price )
                            : 0 }
                        </p>
                      </div>
                    </div>
                  ) : null }
                  <div className="selected-transmission">
                    <strong className="type-heading">
                      Transmission{ " " }
                      <span
                        onClick={ () =>
                        {
                          setIsOfferPopup( false );
                          handleStepTab( 3 );
                        } }
                      >
                        <Pen />
                      </span>{ " " }
                    </strong>
                    <div className="description">
                      <p>{ selectedTransmission?.name }</p>
                      <p>
                        Rp{ " " }
                        { selectedTransmission?.price
                          ? formatAmount( selectedTransmission?.price )
                          : 0 }
                      </p>
                    </div>
                  </div>
                  { selectedColor.length !== 0 && (
                    <div className="selected-color">
                      <strong className="type-heading">
                        Colors{ " " }
                        <span
                          onClick={ () =>
                          {
                            setIsOfferPopup( false );
                            handleStepTab( 4 );
                          } }
                        >
                          <Pen />
                        </span>{ " " }
                      </strong>
                      <div className="description">
                        <p>{ selectedColor.name }</p>
                        <p>Rp 0</p>
                      </div>
                    </div>
                  ) }
                  <div className="selected-accessories">
                    <strong className="type-heading">
                      Accessories{ " " }
                      <span
                        onClick={ () =>
                        {
                          setIsOfferPopup( false );
                          handleStepTab( 5 );
                        } }
                      >
                        <Pen />
                      </span>{ " " }
                    </strong>
                    <div
                      className={ `description package ${ isCaretDownClicked ? "open" : ""
                        }` }
                    >
                      <p>
                        Package :
                        <div className="package-detail">
                          { selectedPackages?.map( ( selectedPackage, i ) => (
                            <p key={ i + 1 }>
                              { selectedPackage.name }
                              { i + 1 != selectedPackages.length ? "," : "" }
                              <ul>
                                { selectedPackage.accesories
                                  ?.filter( ( accessory ) => accessory?.hide === 0 )
                                  ?.map( ( accesory, j ) => (
                                    <li key={ j + 1 }>
                                      { accesory.name }
                                      { j + 1 !=
                                        selectedPackage.accesories.length
                                        ? ","
                                        : "" }
                                    </li>
                                  ) ) }
                              </ul>
                            </p>
                          ) ) }
                        </div>
                      </p>
                      <p>
                        Rp { formatAmount( getPackagesTotalPrice() ) }{ " " }
                        <span onClick={ handleCaretDownClick }>
                          <CaretDown />
                        </span>
                        <div className="package-detail price">
                          { selectedPackages?.map( ( selectedPackage, i ) => (
                            <React.Fragment key={ i + 1 }>
                              <p>Rp { formatAmount( selectedPackage.price ) }</p>
                              <ul>
                                { selectedPackage.accesories
                                  ?.filter( ( accessory ) => accessory?.hide === 0 )
                                  ?.map( ( accesory, j ) => (
                                    <li key={ j + 1 }>
                                      Rp { formatAmount( accesory.price ) }
                                    </li>
                                  ) ) }
                              </ul>
                            </React.Fragment>
                          ) ) }
                        </div>
                      </p>
                    </div>
                    <div
                      className={ `description custom ${ isCaretDownClicked1 ? "open" : ""
                        }` }
                    >
                      <p>
                        Custom :
                        <div className="package-detail">
                          { selectedCustoms?.map( ( selectedCustom, index ) => (
                            <p key={ index + 1 }>
                              { selectedCustom.name }
                              { index + 1 != selectedCustoms.length ? "," : "" }
                            </p>
                          ) ) }
                        </div>
                      </p>
                      <p>
                        Rp { formatAmount( getCustomsTotalPrice() ) }{ " " }
                        <span onClick={ handleCaretDownClick1 }>
                          <CaretDown />
                        </span>
                        <div className="package-detail price">
                          { selectedCustoms?.map( ( selectedCustom, index ) => (
                            <p key={ index + 1 }>
                              Rp { formatAmount( selectedCustom.price ) }
                            </p>
                          ) ) }
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="total-amount">
                    <p>Total Amount*</p>
                    <p>Rp { getTotalPrice() }</p>
                  </div>
                  <div className="disclaimer">
                    <p>
                      * Total Amount as Built is an estimate that is based on
                      manufacturer suggested retail pricing and excludes taxes
                      and fees. Dealer sets actual price. See your local
                      participating dealer for actual pricing and for product
                      availability.
                    </p>
                  </div>
                </div>
                <div className="go-to-summary">
                  <button
                    onClick={ () =>
                    {
                      setIsOfferPopup( false );
                      handleStepTab( 7 );
                    } }
                  >
                    Go to Summary
                  </button>
                </div>
              </div>
            </OverviewTabWrapper>
          </Popup>
        </GoToOffer>
        <Popup
          isOpen={ isOpen }
          onClose={ () =>
          {
            setIsOpen( false );
            handleTabClick( "engine", "engine" );
          } }
          popupContentStyle={ {
            width: "100%",
            height: "90%",
            padding: "0 30px 30px",
            maxWidth: "1200px",
            borderRadius: "10px",
          } }
          closeButtonStyle={ {
            padding: "5px 10px",
            border: "none",
            borderRadius: "3px",
            background: "transparent",
            zIndex: "9",
            /* float: right; */
            position: "relative",
            top: "30px",
            right: "0",
            textAlign: "right",
            width: "100%",
          } }
        >
          <OverviewTabWrapper>
            <h3>Vehicle Overview</h3>
            <h4 className="overview-product-name">{ productIdData?.name }</h4>
            <div className="tab-list">
              <div
                className={ `tab ${ "engine" === selectedOverViewTab ? "active-tab" : ""
                  }` }
                onClick={ () => handleTabClick( "engine", "engine" ) }
              >
                ENGINE
              </div>
              { OverviewCategory?.map( ( category, index ) => (
                <div
                  key={ index }
                  className={ `tab ${ category.name.toLowerCase() === selectedOverViewTab
                    ? "active-tab"
                    : ""
                    }` }
                  onClick={ () =>
                    handleTabClick( category.id, category.name.toLowerCase() )
                  }
                >
                  { category?.name }
                </div>
              ) ) }
            </div>
            <div className="tab-content">
              { selectedOverViewTab === "engine" ? (
                <div className="engine-specifications">
                  { engineSpecs && engineSpecs.length > 0 ? (
                    engineSpecs.map( ( spec, index ) => (
                      <div key={ spec.id } className="engine-spec-detail">
                        <table className="table mt-3">
                          <tr>
                            <td style={ { width: "15%" } }>{ spec.title }</td>
                            <td style={ { width: "1%" } }> : </td>
                            <td style={ { width: "30%" } }>{ spec.description }</td>
                          </tr>
                        </table>
                      </div>
                    ) )
                  ) : (
                    <p>No engine specifications available.</p>
                  ) }
                </div>
              ) : (
                <div className="overview-list-slider">
                  <ThumbnailWrapper>
                    { popupOverviewloading ? (
                      <div className="loader"></div>
                    ) : SelectedOverviewCategory.length !== 0 ? (
                      <Row>
                        <Col md={ 6 }>
                          <div className="thumbnail-container">
                            { SelectedOverviewCategory.map( ( item, index ) => (
                              <li
                                key={ index }
                                className={
                                  selectedImageIndex === index
                                    ? "thumbnail selected"
                                    : "thumbnail"
                                }
                                onClick={ () => handleThumbnailClick( index ) }
                              >
                                { item.name }
                              </li>
                            ) ) }
                          </div>
                        </Col>
                        <Col md={ 6 }>
                          <div className="main-image-container">
                            <Carousel
                              infiniteLoop={ true }
                              autoPlay={ true }
                              interval={ 2000 }
                              showThumbs={ false }
                              selectedItem={ selectedImageIndex }
                              onChange={ handleCarouselChange }
                              ref={ carouselRef }
                            >
                              { SelectedOverviewCategory.map( ( item, index ) => (
                                <div key={ index }>
                                  { item.video ? (
                                    <div>
                                      <video
                                        id={ `video_${ index }` }
                                        poster={ item.image_url }
                                      >
                                        <source
                                          src={ item.video_url }
                                          type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                      { isButtonVisible && (
                                        <button onClick={ handleVideoPlay }>
                                          Play Video
                                        </button>
                                      ) }
                                    </div>
                                  ) : (
                                    <img
                                      src={ item.image_url }
                                      alt={ `Image ${ index }` }
                                    />
                                  ) }
                                </div>
                              ) ) }
                            </Carousel>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <p>No { selectedOverViewTab } Found!</p>
                    ) }
                  </ThumbnailWrapper>
                </div>
              ) }
            </div>
          </OverviewTabWrapper>
        </Popup>
        <DealerPopup>
          { isDpopupOpen && (
            <Popup
              isOpen={ isDpopupOpen }
              onClose={ () => setIsDpopupOpen( false ) }
              popupContentStyle={ {
                width: "100%",
                height: "auto",
                padding: "0 20px 20px",
                maxWidth: "450px",
                borderRadius: "10px",
                overflow: "auto",
              } }
              closeButtonStyle={ {
                padding: "5px 10px",
                border: "none",
                borderRadius: "3px",
                background: "transparent",
                zIndex: "9",
                position: "relative",
                top: "0",
                right: "-15px",
                textAlign: "right",
                width: "100%",
              } }
            >
              <div className="home-popup">
                <img
                  src={ Logo }
                  alt="Logo"
                  onClick={ () =>
                  {
                    window.location.href = `${ DefaultConfig.BASE_URL }`;
                  } }
                />
                <h2>SELECT YOUR NEAREST DEALER</h2>
                <SelectBoxes>
                  <div className="select-wrap">
                    { cityData?.data?.length > 0 ? (
                      <div className="select-city">
                        <div className="custom-dropdown">
                          <div
                            className={ `dropdown-header ${ isLocationOpen ? "open" : ""
                              }` }
                            onClick={ toggleDropdown }
                          >
                            <Location />{ " " }
                            { selectedLocation?.name || "Selected City" }
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          { isLocationOpen && (
                            <ul className="dropdown-list">
                              { cityData?.data?.map( ( item, index ) => (
                                <li
                                  key={ index }
                                  onClick={ () => handleItemClick( item ) }
                                >
                                  { item?.name }
                                </li>
                              ) ) }
                            </ul>
                          ) }
                        </div>
                      </div>
                    ) : null }
                    { dealerData?.data?.length > 0 ? (
                      <div className="select-dealer">
                        <div className="custom-dropdown">
                          <div
                            className={ `dropdown-header ${ isDealerOpen ? "open" : ""
                              }` }
                            onClick={ toggleDealerDropdown }
                          >
                            <Dealer />{ " " }
                            { selectedDealer?.name || "Selected Dealer" }
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          { isDealerOpen && (
                            <ul className="dropdown-list">
                              { dealerData?.data?.map( ( item, index ) => (
                                <li
                                  key={ index }
                                  onClick={ () => handleDealerClick( item ) }
                                >
                                  { item?.name }
                                </li>
                              ) ) }
                            </ul>
                          ) }
                        </div>
                      </div>
                    ) : null }
                  </div>
                </SelectBoxes>
              </div>
            </Popup>
          ) }
        </DealerPopup>
      </Layout>
    </>
  );
};

const OverviewTabWrapper = styled.div`
  h3 {
    color: #161a1d;
    font-size: 18px;
    border-bottom: 1px solid #161a1d;
    padding-bottom: 15px;
    display: block;
  }
  .overview-product-name {
    padding: 30px 0 20px;
  }
  .tab-list {
    display: flex;
    align-items: flex-start;
    overflow-x: auto;
    & > .tab:last-child {
      border-right: 1px solid #d0d1d2;
    }
  }

  .tab {
    flex: 1;
    padding: 14px 32px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #d0d1d2;
    border-right: 1px solid transparent;
    &:hover {
      border: 1px solid #d71921 !important;
    }
    &.active-tab {
      background-color: #161a1d;
      color: #fff;
      border: 1px solid #161a1d;
      &:hover {
        border-color: transparent;
      }
    }
  }
  .tab-content {
    .engine-detail {
      border: 1px solid #d0d1d2;
      margin-top: 30px;
      .engine-detail-row {
        display: flex;
        justify-content: space-between;
        padding: 14px 20px;
        p {
          margin-bottom: 0;
          color: #161a1d;
          text-align: center;
          font-size: 16px;
        }
        & > p:last-child {
          font-weight: 700;
        }
      }
      & > .engine-detail-row:not(:last-child) {
        border-bottom: 1px solid #d0d1d2;
      }
    }
    .disclaimer {
      color: #161a1d;
      font-size: 10px;
      margin-bottom: 0;
      padding-top: 20px;
      font-weight: 500;
    }

    .overview-list-slider {
      margin-top: 30px;
    }
    .carousel .slide {
      height: 400px;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  min-height: 60px;
  .thumbnail-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-image-container {
    max-width: 100%;
  }

  .thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .thumbnail {
    width: 100%;
    margin: 0 5px;
    cursor: pointer;
    border: 2px solid transparent;
    position: relative;
    &:hover {
      color: #d71921;
    }
  }

  .thumbnail.selected {
    color: #d71921;
  }

  .carousel-root {
    max-width: 100%;
  }
  .carousel {
    padding-bottom: 40px;
    ul.control-dots {
      li {
        display: inline-block;
        position: relative;
        padding: 0;
        cursor: pointer;
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 100px;
        background: #d9dadb;
        border: 2px solid transparent;
        opacity: 1;
        box-shadow: unset;
        margin: 0 5px;
        &:hover {
          background: #d71921;
        }
        &.selected {
          border-radius: 100px;
          background: #fff;
          border: 2px solid #d71921;
        }
      }
    }
  }
  .carousel .control-prev,
  .carousel .control-next {
    font-size: 24px;
    color: #3498db;
  }
  .carousel .thumbs-wrapper {
    display: none;
  }
  .carousel .carousel-status {
    display: none;
  }
  .carousel .control-arrow {
    opacity: 1;
  }
  .carousel .control-arrow:before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url(${ RightArrow }) no-repeat center center;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff;
    border: none;
    background-size: 10px;
    background-position: center center;
  }
  .carousel .control-arrow:hover:before {
  }
  .carousel .control-prev.control-arrow:before {
    background: url(${ RightArrow }) no-repeat center center;
    transform: rotate(180deg);
    background-color: #fff;
    border: none;
    background-size: 10px;
    background-position: center center;
  }
  .carousel .control-disabled.control-arrow {
    cursor: inherit;
    display: block;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
`;

const GoToOffer = styled.div`
  .popup {
    .popup-content {
      overflow: hidden;
      @media screen and (max-width: 575px) {
        height: 98% !important;
      }
    }
    .popup-content-inner {
      position: unset;
    }
    .get-offer-popup {
      height: 550px;
      overflow-y: auto;
      .variant-image {
        text-align: center;
        img {
          width: 100%;
          max-width: 450px;
          margin: 0 auto;
        }
      }
      .go-to-summary {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 30px 10px;
        display: block;
        width: 100%;
        text-align: center;
        background: #fff;
        button {
          border-radius: 4px;
          background: #161a1d;
          color: #fff;
          padding: 14px 30px;
          border: 0;
          &:hover {
            background: #d71921;
          }
        }
      }
      .variant-list {
        .type-heading {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
          display: block;
          & svg:hover {
            cursor: pointer;
            path {
              fill: #d71921;
            }
          }
        }
        .description {
          display: flex;
          justify-content: space-between;
          & > p:first-child {
            width: 80%;
          }
          & > p:last-child {
            width: 40%;
            text-align: right;
            svg:hover {
              cursor: pointer;
              path {
                fill: #d71921;
              }
            }
          }
          .package-detail {
            display: inline;
            &.price {
              display: none;
            }
            p {
              margin-bottom: 0;
              display: inline;
            }
            ul {
              display: none;
              li {
                color: #737677;
                font-size: 12px;
              }
            }
          }
        }
        .description.open {
          p {
            svg {
              transform: rotateX(180deg);
            }
          }
          .package-detail {
            display: inline;
            &.price {
              display: block;
            }
            p {
              display: block;
            }
            ul {
              display: block;
            }
          }
        }
        .total-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          background: #d71921;
          padding: 20px;
          @media screen and (max-width: 767px) {
            flex-wrap: wrap;
          }
          p {
            margin-bottom: 0;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
          }
          & > p:last-child {
            font-size: 24px;
          }
        }
        .disclaimer {
          padding: 10px 0 20px;
          p {
            color: #161a1d;
            font-size: 10px;
          }
        }
      }
    }
  }
  /* width */
  .get-offer-popup::-webkit-scrollbar {
    width: 10px;
  }
  .get-offer-popup::-webkit-scrollbar-track {
    background: transparent;
  }
  .get-offer-popup::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .get-offer-popup::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`;

const SelectedDealer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .dealer-name {
    display: inine-block;
    padding-top: 40px;
    cursor: pointer;
    svg {
      path {
        fill: #d71921;
      }
    }
    p {
      margin-bottom: 0;
      padding-left: 15px;
      display: inline-block;
    }
  }
`;

const DealerPopup = styled.div`
  text-align: center;
  h2 {
    color: #161a1d;
    font-size: 24px;
    margin: 40px 0;
    @media screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
  .popup {
    background: rgba(0, 0, 0, 0.8) !important;
    .popup-content {
      overflow: visible !important;
      @media screen and (max-width: 991px) {
        height: auto !important;
      }
      .popup-content-inner {
        overflow: visible !important;
      }
    }
  }
`;

const SelectBoxes = styled.div`
  .select-wrap {
    display: block;
    .select-city {
      margin-right: auto;
    }
    & > div {
      max-width: 320px;
      width: 100%;
      margin: 10px auto;
    }
    .custom-dropdown {
      position: relative;
    }
    .dropdown-header {
      border-radius: 10px;
      border: 1px solid #737677;
      background: #fff;
      padding: 10px 15px;
      width: 100%;
      position: relative;
      cursor: pointer;
      text-align: left;
      color: #a2a3a5;
      & > svg {
        margin-right: 10px;
        margin-bottom: 5px;
        width: 20px;
        path {
          fill: #a2a3a5;
        }
      }
      &.open {
        border-radius: 10px 10px 0 0;
        .select-arrow svg {
          transform: rotate(180deg);
        }
      }
      &:hover {
        color: #eb0a1e;
        border-color: #eb0a1e;
        svg path {
          fill: #eb0a1e;
        }
      }
      & .select-arrow {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        svg {
          path {
            fill: #a2a3a5;
          }
        }
      }
    }
    .dropdown-list {
      border: 0px solid #161a1d;
      border-radius: 0 0 10px 10px;
      padding: 10px 0;
      margin-top: 0;
      box-shadow: 0 0 5px 0px #ddd;
      position: absolute;
      width: 100%;
      z-index: 9;
      background: #fff;
      max-height: 180px;
      overflow-y: auto;
      min-height: auto;
      li {
        padding: 10px;
        cursor: pointer;
        text-align: left;
        &:hover {
          color: #d71921;
          background: #eceded;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #eceded;
      }
    }
  }
`;

export default Product;
