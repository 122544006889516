import React, { useState } from "react";
import styled from "styled-components";
import { formatAmount } from "../../../../Utils/Global";
import { CaretDown } from "../../../../Assets/caret-down";
import Accessory from "../Accessory";
import PriceCalculation from "../PriceCalculation";

const Summary = ({ activeStepTab }) => {
    const variant = ['Hybrid Type', 'Gasoline G Type', 'Gasoline X Type'];
    const transmission = ['M/T', 'A/T', 'CVT'];
    const color = ['Platinum White Pearl', 'Black'];
    const accesorries = ['Modellista Package', 'Front Spoiler (Modellista)', 'Side Skirt (Modellista)', 'Side Skirt (Modellista)'];
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => {
        setIsExpanded((prev) => !prev);
    };
    const [isVariantOpen, setVariantOpen] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState(variant[0]);
    const [isTransmissionOpen, setTransmissionOpen] = useState(false);
    const [selectedTransmission, setSelectedTransmission] = useState(transmission[0]);
    const [isColorOpen, setColorOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(color[0]);
    const [isAccesorriesOpen, setAccesorriesOpen] = useState(false);
    const [selectedAccesorries, setSelectedAccesorries] = useState(accesorries[0]);
    const [isCaretDownClicked, setIsCaretDownClicked] = useState(false);
    const [isCaretDownClicked1, setIsCaretDownClicked1] = useState(false);

    const handleCaretDownClick = () => {
        setIsCaretDownClicked((prev) => !prev);
    };
    const handleCaretDownClick1 = () => {
        setIsCaretDownClicked1((prev) => !prev);
    };

    const toggleVariant = () => {
        setVariantOpen(!isVariantOpen);
    }
    const handleVariantClick = (item) => {
        setSelectedVariant(item);
        setVariantOpen(false);
    };
    const toggleTransmission = () => {
        setTransmissionOpen(!isTransmissionOpen);
    }
    const handleTransmissionClick = (item) => {
        setSelectedTransmission(item);
        setTransmissionOpen(false);
    };
    const toggleColor = () => {
        setColorOpen(!isColorOpen);
    }
    const handleColorClick = (item) => {
        setSelectedColor(item);
        setColorOpen(false);
    };
    const toggleAccesorries = () => {
        setAccesorriesOpen(!isAccesorriesOpen);
    }
    const handleAccesorriesClick = (item) => {
        setSelectedAccesorries(item);
        setAccesorriesOpen(false);
    };



    return (
        <>
            <SummaryWrapper>
                <div className="container">
                    <div className="summary-inner">
                        <div className="step-selection">
                            <div className="select-wrap">
                                <h3>Variant</h3>
                                <div className="select-type">
                                    <div className="custom-dropdown">
                                        <div className={`dropdown-header ${isVariantOpen ? 'open' : ''}`}>
                                            {selectedVariant}
                                            <span className='select-arrow' onClick={toggleVariant}><CaretDown /></span>
                                        </div>
                                        {isVariantOpen && (
                                            <ul className="dropdown-list">
                                                {variant.map((item, index) => (
                                                    <li key={index} onClick={() => handleVariantClick(item)}>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <p>Rp {formatAmount(1356100000)}</p>
                                </div>
                            </div>
                            <div className="select-wrap">
                                <h3>Transmission</h3>
                                <div className="select-type">
                                    <div className="custom-dropdown">
                                        <div className={`dropdown-header ${isTransmissionOpen ? 'open' : ''}`}>
                                            {selectedTransmission}
                                            <span className='select-arrow' onClick={toggleTransmission}><CaretDown /></span>
                                        </div>
                                        {isTransmissionOpen && (
                                            <ul className="dropdown-list">
                                                {transmission.map((item, index) => (
                                                    <li key={index} onClick={() => handleTransmissionClick(item)}>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <p>Rp {formatAmount(1900000)}</p>
                                </div>
                            </div>
                            <div className="select-wrap">
                                <h3>Colors</h3>
                                <div className="select-type">
                                    <div className="custom-dropdown">
                                        <div className={`dropdown-header ${isColorOpen ? 'open' : ''}`}>
                                            {selectedColor}
                                            <span className='select-arrow' onClick={toggleColor}><CaretDown /></span>
                                        </div>
                                        {isColorOpen && (
                                            <ul className="dropdown-list">
                                                {color.map((item, index) => (
                                                    <li key={index} onClick={() => handleColorClick(item)}>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <p>Rp {formatAmount(0)}</p>
                                </div>
                            </div>
                            <div className="select-wrap">

                                <div className="select-type">
                                    <div className="custom-dropdown">
                                        <div className={`dropdown-header ${isAccesorriesOpen ? 'open' : ''}`}>
                                            <h3>Accessories</h3>
                                            <span className='select-arrow' onClick={toggleAccesorries}> Add +</span>
                                        </div>
                                        {isAccesorriesOpen && (
                                            <ul className="dropdown-list">
                                                {accesorries.map((item, index) => (
                                                    <li key={index} onClick={() => handleAccesorriesClick(item)}>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <AccessoryPackage>
                                        <div className={`description package ${isCaretDownClicked ? 'open' : ''}`}>
                                            <p>Package :
                                                <div className='package-detail'>
                                                    <p>Modellista Package <span>-</span> ,
                                                        <ul>
                                                            <li>Front Spoiler (Modellista),</li>
                                                            <li>Side Skirt (Modellista),</li>
                                                            <li>Rear Skirt (Modellista)</li>
                                                        </ul>
                                                    </p>
                                                    <p>Modellista Package <span>-</span>
                                                        <ul>
                                                            <li>Front Spoiler (Modellista), </li>
                                                            <li>Side Skirt (Modellista), </li>
                                                            <li>Rear Skirt (Modellista)</li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </p>
                                            <p>Rp {formatAmount(129800000)} <span onClick={handleCaretDownClick}><CaretDown /></span>
                                                <div className='package-detail price'>
                                                    <p>Rp {formatAmount(64900000)}</p>
                                                    <ul>
                                                        <li>Rp {formatAmount(21633333)}</li>
                                                        <li>Rp {formatAmount(21633333)}</li>
                                                        <li>Rp {formatAmount(21633333)}</li>
                                                    </ul>
                                                    <p>Rp {formatAmount(64900000)}</p>
                                                    <ul>
                                                        <li>Rp {formatAmount(21633333)}</li>
                                                        <li>Rp {formatAmount(21633333)}</li>
                                                        <li>Rp {formatAmount(21633333)}</li>
                                                    </ul>
                                                </div>
                                            </p>
                                        </div>
                                        <div className={`description custom ${isCaretDownClicked1 ? 'open' : ''}`}>
                                            <p>Custom :
                                                <div className='package-detail'>
                                                    <p>Front Spoiler (Modellista) <span>-</span> , </p>
                                                    <p>Side Skirt (Modellista) <span>-</span> , </p>
                                                    <p>Rear Skirt (Modellista) <span>-</span></p>
                                                </div>
                                            </p>
                                            <p>Rp {formatAmount(64920000)} <span onClick={handleCaretDownClick1}><CaretDown /></span>
                                                <div className='package-detail price'>
                                                    <p>Rp {formatAmount(21640000)}</p>
                                                    <p>Rp {formatAmount(21640000)}</p>
                                                    <p>Rp {formatAmount(21640000)}</p>
                                                </div>
                                            </p>
                                        </div>
                                        <div className='total-amount'>
                                            <p>Total Amount*</p>
                                            <p>Rp {formatAmount(1421280000)}</p>
                                        </div>
                                        <div className='disclaimer'>
                                            <p>
                                                *Harga sewaktu-waktu dapat berubah
                                            </p>
                                        </div>
                                    </AccessoryPackage>
                                </div>
                            </div>
                        </div>
                        <div className="summary-price-calculation">
                            <PriceCalculation activeStepTab={activeStepTab} />
                        </div>
                    </div>
                </div>
            </SummaryWrapper>
        </>
    );
}

const SummaryWrapper = styled.div`
    max-width: 92%;
    margin: 40px auto 60px;
    padding: 30px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.60);
    transition: min-height 0.3s ease-out;
    overflow: hidden;
    @media screen and (max-width:767px){
        max-width:97%;
        margin: 40px auto 20px;
        padding:30px 5px;
    }
    .summary-price-calculation{
        .price-inner{
            max-width:100%;
            margin:0 ;
            padding:0 ;
            box-shadow:none ;
        }
        .container{
            padding:0;
        }
    }
    
    .step-selection{
        .select-wrap{
            h3{
                font-size: 18px;
                font-weight: 700;
                @media screen and (max-width:575px){
                    font-size:16px;
                }
            }
            .select-type{
                display:flex;
                justify-content:space-between;
                padding-bottom:20px;
                flex-wrap:wrap;
                p{
                    margin-bottom:0;
                    font-size:14px;
                }
            }
            .custom-dropdown{
                position:relative;
                .dropdown-header{
                    font-size:16px;
                    font-weight:400;
                    display:flex;
                    @media screen and (max-width:575px){
                        font-size:14px;
                    }
                    &.open{
                        .select-arrow{
                            svg{
                                transform:rotate(180deg);
                            }
                        }
                    }
                    .select-arrow{
                        margin-left:8px;
                        cursor:pointer;
                        font-size:14px;
                        &:hover{
                            color:#D71921;
                        }
                        svg{
                            &:hover{
                                path{
                                    fill:#D71921;
                                }
                            }
                        }
                    }
                } 
                .dropdown-list {
                    position: absolute;
                    left: 80%;
                    top: 28px;
                    border-radius: 10px;
                    background: #FFF;
                    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.60);
                    overflow:hidden;
                    z-index:1;
                    width:100%;
                    li{
                        padding:10px 7px;
                        font-size:16px;
                        cursor:pointer;
                        &:hover{
                            background:#ECEDED;
                            color:#D71921;
                        }
                    }
                }
            }  
        }
    }

`;

const AccessoryPackage = styled.div`
width:100%;
.description {
    display: flex;
    justify-content: space-between;
    padding-bottom:10px;
    & > p:first-child{
        width:80%;
        @media screen and (max-width:575px){
            width:60%;
        }
    }
    & > p:last-child{
        width:40%;
        @media screen and (max-width:575px){
            width:50%;
        }
        text-align:right;
        svg:hover{
            cursor:pointer;
            path{
                fill:#D71921;
            }
        }
    }
    .package-detail {
        display: inline;
        padding-left:10px;
        &.price{
            display:none;
        }
        p{
            margin-bottom:0;
            display: inline;
            span{
                cursor:pointer;
                font-size:20px;
                &:hover{
                    color:#D71921;
                }
            }
        }
        ul{
            display:none;
            li {
                color: #737677;
                font-size: 12px;
                line-height:25px;
            }
        }
    }
}
.description.open{
    p{
        svg{
            transform:rotateX(180deg);
        }
    }
    .package-detail {
        display: inline;
        &.price{
            display:block;
        }
        p{
            display: block;
            line-height:25px;
        }
        ul{
            display:block;
        }
    }
}
.total-amount {
    display: flex;
    justify-content: space-between;
    align-items:center;
    border-radius: 10px;
    background: #D71921;
    padding: 20px;
    @media screen and (max-width:575px){
        flex-wrap:wrap;
        flex-direction:column;
        justify-content:center;
        text-align:center;
    }
    p{
        margin-bottom:0;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
    }
    &>p:last-child{
        font-size:24px;
    }
}
.disclaimer{
    padding:10px 0 20px;
    p{
        color: #161A1D;
        font-size: 10px;
    }
}
`;
export default Summary;