import {
    createBrowserRouter,
    matchRoutes,
    useLocation
} from "react-router-dom";
import { Home } from "../Pages/Home";
import Product from "../Pages/Product";


const routes = [
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/Product/:slug",
        element: <Product />
    }
];
export const router = createBrowserRouter(routes, { basename: "/" });

export const useCurrentPath = () => {
    const location = useLocation();
    const [{ pathname }]: any = matchRoutes(routes, location);
    return pathname;
};