import React from "react";

const Times = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
            <path d="M6.48438 5.48438L9.85938 8.85938C10.0469 9.04688 10.0469 9.39062 9.85938 9.57812L9.07812 10.3594C8.89062 10.5469 8.54688 10.5469 8.35938 10.3594L5.01562 6.98438L1.64062 10.3594C1.45312 10.5469 1.10938 10.5469 0.921875 10.3594L0.140625 9.57812C-0.046875 9.39062 -0.046875 9.04688 0.140625 8.85938L3.51562 5.48438L0.140625 2.14062C-0.046875 1.95312 -0.046875 1.60938 0.140625 1.42188L0.921875 0.640625C1.10938 0.453125 1.45312 0.453125 1.64062 0.640625L5.01562 4.01562L8.35938 0.640625C8.54688 0.453125 8.89062 0.453125 9.07812 0.640625L9.85938 1.42188C10.0469 1.60938 10.0469 1.95312 9.85938 2.14062L6.48438 5.48438Z" fill="#161A1D" />
        </svg>
    );
}

export default Times;