import React, { useState, useRef, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DefaultConfig from "../../../Utils/Config";
import styled from "styled-components";
import RightArrow from "../../../Assets/Vector.svg";

const ThumbnailSlider = ({ variantImage }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const carouselRef = useRef(null);
  const thumbnailContainerRef = useRef(null);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);

    const thumbnailContainer = thumbnailContainerRef.current;
    const thumbnailElement = thumbnailContainer.childNodes[index];

    if (thumbnailElement) {
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const handleCarouselChange = (index) => {
    setSelectedImageIndex(index);

    const thumbnailContainer = thumbnailContainerRef.current;
    const thumbnailElement = thumbnailContainer.childNodes[index];

    if (thumbnailContainer && thumbnailElement) {
      const scrollLeft =
        thumbnailElement.offsetLeft -
        (thumbnailContainer.offsetWidth - thumbnailElement.offsetWidth) / 2;
      thumbnailContainer.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handleCarouselClick = (type) => {
    const totalImages = allThumbnails.length;
    let newIndex = selectedImageIndex;

    if (type === "next") {
      newIndex = (selectedImageIndex + 1) % totalImages;
    } else if (type === "prev") {
      newIndex = (selectedImageIndex - 1 + totalImages) % totalImages;
    }

    setSelectedImageIndex(newIndex);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      carousel.controlPrev = () => handleCarouselClick("prev");
      carousel.controlNext = () => handleCarouselClick("next");
    }
  }, [selectedImageIndex]);

  const exteriorThumbnails = variantImage?.exterior
    ? variantImage.exterior
        .sort((a, b) => parseInt(a.id) - parseInt(b.id))
        .map((item) => `${DefaultConfig.StorageUrl}${item.image}`)
    : [];

  const interiorThumbnails = variantImage?.interior
    ? variantImage.interior
        .sort((a, b) => parseInt(a.id) - parseInt(b.id))
        .map((item) => `${DefaultConfig.StorageUrl}${item.image}`)
    : [];

  const allThumbnails = [...exteriorThumbnails, ...interiorThumbnails];

  return (
    <ThumbnailWrapper>
      <div className="thumbnail-slider">
        <div className="main-image-container">
          <Carousel
            infiniteLoop={true}
            autoPlay={true}
            swipeable={true}
            emulateTouch={true}
            interval={2000}
            showThumbs={false}
            selectedItem={selectedImageIndex}
            onChange={handleCarouselChange}
            ref={carouselRef}
          >
            {allThumbnails.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Image ${index}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="thumbnail-container" ref={thumbnailContainerRef}>
          {allThumbnails.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={
                selectedImageIndex === index
                  ? "thumbnail selected"
                  : "thumbnail"
              }
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    </ThumbnailWrapper>
  );
};

const ThumbnailWrapper = styled.div`
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  .thumbnail-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }

  .main-image-container {
    max-width: 800px;
    margin: 60px auto 0;
    @media screen and (max-width: 991px) {
      margin-top: 20px;
    }
  }

  .thumbnail-container {
    display: flex;
    margin-top: 10px;
    width: 100%;
    overflow-x: hidden;
    position: fixed;
    bottom: 13px;
    width: 100%;
    left: 50%;
    max-width: 95%;
    transform: translateX(-50%);
  }

  .thumbnail {
    width: 200px;
    height: 120px;
    margin: 0 5px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
    position: relative;
    @media screen and (max-width: 1100px) {
      width: 100px;
      height: auto;
      object-fit: contain;
    }
    &:hover {
      background: rgba(0, 0, 0, 10%);
    }
  }

  .thumbnail.selected {
    background: rgba(0, 0, 0, 15%);
    border-color: #d71921;
  }

  .carousel-root {
    max-width: 100%;
  }
  .carousel {
    ul.control-dots {
      display: none;
    }
    .slide {
      @media screen and (max-width: 1199px) {
        height: auto;
        max-width: 90%;
      }
      img {
        height: 300px;
        object-fit: contain;
        @media screen and (max-width: 1199px) {
          max-width: 85%;
          margin: 0 auto;
        }
        @media screen and (max-width: 991px) {
          height: 200px;
        }
      }
    }
  }
  .carousel .control-prev,
  .carousel .control-next {
    font-size: 24px;
    color: #3498db;
  }
  .carousel .thumbs-wrapper {
    display: none;
  }
  .carousel .carousel-status {
    display: none;
  }
  .carousel .control-arrow:before {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url(${RightArrow}) no-repeat center center;
    border: 1px solid #161a1d;
    padding: 10px;
    border-radius: 50%;
    @media screen and (max-width: 1199px) {
      width: 40px;
      height: 40px;
    }
  }
  .carousel .control-arrow:hover:before {
    filter: invert(7%) sepia(65%) saturate(5943%) hue-rotate(354deg)
      brightness(107%) contrast(157%);
  }
  .carousel .control-prev.control-arrow:before {
    background: url(${RightArrow}) no-repeat center center;
    transform: rotate(180deg);
  }
  .carousel .control-disabled.control-arrow {
    opacity: 1;
    cursor: inherit;
    display: block;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
`;

export default ThumbnailSlider;
