import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import Check from "../../../../Assets/check";
import styled from "styled-components";
import SampleImg from "../../../../Assets/T-CARE.png";
import Popup from "../../../../Components/Popup";
import DefaultConfig from "../../../../Utils/Config";
import { formatAmount } from "../../../../Utils/Global";
import { Carousel } from "react-responsive-carousel";
import RightArrow from "../../../../Assets/Vector-white.svg";

const AfterSales = ({
  handleSelectAfterSales,
  product,
  selectedVariant,
  selectedTCare,
}) => {
  // const [selected, setSelected] = useState(null);
  const [popupItem, setPopupItem] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [TCareData, setTCareData] = useState([]);
  const openPopup = (item) => {
    setIsOpen(true);
    setPopupItem(item);
  };
  const handleClick = (item) => {
    // setSelected(item.id);
    handleSelectAfterSales(item);
  };
  const items = [{ name: "Item 1" }, { name: "Item 2" }];
  const itemsWithIds = items.map((item, index) => ({
    ...item,
    id: index + 1, // Or use a unique identifier logic suitable for your data
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${DefaultConfig.API_URL}/tcare?variant=${selectedVariant}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );

        setTCareData(response.data.data);
        if (selectedTCare == "null") {
          handleSelectAfterSales(
            response.data.data.length > 0 ? response.data.data[0] : null
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [product, selectedVariant]);

  const isMobile = window.innerWidth <= 991;
  const settings = {
    showStatus: false, // hide dots
    showThumbs: false, // hide dots
    showArrows: true, // show arrows
    infiniteLoop: true, // enable infinite loop
    useKeyboardArrows: true, // enable keyboard navigation
    emulateTouch: true, // enable touch swipe
    swipeable: true, // enable swipe
    dynamicHeight: false, // maintain the height of the carousel items
    centerMode: false, // center the current item
    centerSlidePercentage: 100, // percentage width of the centered slide (if centerMode is true)
    interval: 5000, // time between automatic transitions (set to 0 to disable auto-play)
    transitionTime: 500, // time it takes to slide to the next item (in milliseconds)
  };

  return (
    <AfterSalesWrapper>
      {isOpen && (
        <Popup
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          popupContentStyle={{
            width: "100%",
            height: "90%",
            padding: "0 20px 20px",
            maxWidth: "720px",
            borderRadius: "10px",
          }}
          closeButtonStyle={{
            padding: "5px 10px",
            border: "none",
            borderRadius: "3px",
            background: "transparent",
            zIndex: "9",
            position: "relative",
            top: "30px",
            right: "0",
            textAlign: "right",
            width: "100%",
          }}
        >
          <div className="config-popup">
            <div className="variant-inner">
              <h3>Conversion Dry Box Detail</h3>
              <Row>
                <Col xs={12} sm={8}>
                  <div className="variant-img">
                    <img
                      src={popupItem.img_used_url}
                      alt="image"
                      className="w-100"
                    />
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <p className="start-from">
                    +Rp {formatAmount(popupItem.price)} <span>msrp</span>{" "}
                  </p>
                </Col>
              </Row>
              <div className="variant-info">
                <h4>Detail Accesories</h4>
                <div className="usp-list">
                  <ul>
                    {popupItem?.items.map((listItem, index) => (
                      <li>{listItem}</li>
                    ))}
                  </ul>
                  <button
                    className={`select-btn ${
                      selectedTCare.some((item) => item.id === popupItem.id)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleSelectAfterSales(popupItem)}
                  >
                    {selectedTCare.some((item) => item.id === popupItem.id)
                      ? "Remove -"
                      : "Add +"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
      {isMobile ? (
        <div className="variant-card">
          <Carousel {...settings}>
            {TCareData.map((item, index) => (
              <Col lg={4} xl={3} className="mb-4" key={item.id}>
                <div
                  className={`variant-inner ${
                    selectedTCare.some((item2) => item2.id === item.id)
                      ? "selected"
                      : ""
                  }`}
                >
                  <div className="variant-top">
                    <p className="numbers">
                      {index + 1}/{TCareData.length}
                    </p>
                  </div>
                  <div className="variant-img">
                    <img
                      src={item.img_used_url}
                      alt="image"
                      className="w-100"
                    />
                  </div>
                  <div className="variant-info">
                    <p className="name">{item.name}</p>
                    <div className="usp-list">
                      <ul>
                        {item?.items.map((listItem, index) => (
                          <li>{listItem}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="detail-price">
                      <p className="view" onClick={() => openPopup(item)}>
                        View Details
                      </p>
                      <p className="start-from">
                        +Rp {formatAmount(item.price)} <span>msrp</span>{" "}
                      </p>
                    </div>
                    <button
                      className={`select-btn  ${
                        selectedTCare.some((item2) => item2.id === item.id)
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleClick(item)}
                    >
                      {selectedTCare.some((item2) => item2.id === item.id)
                        ? " Remove -"
                        : "Add +"}
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Carousel>
        </div>
      ) : (
        <div className="variant-card">
          <Row>
            {TCareData.map((item, index) => (
              <Col lg={4} xl={3} className="mb-4" key={item.id}>
                <div
                  className={`variant-inner ${
                    selectedTCare.some((item2) => item2.id === item.id)
                      ? "selected"
                      : ""
                  }`}
                >
                  <div className="variant-top">
                    <p className="numbers">
                      {index + 1}/{TCareData.length}
                    </p>
                  </div>
                  <div className="variant-img">
                    <img
                      src={item.img_used_url}
                      alt="image"
                      className="w-100"
                    />
                  </div>
                  <div className="variant-info">
                    <p className="name">{item.name}</p>
                    <div className="usp-list">
                      <ul>
                        {item?.items.map((listItem) => (
                          <li>{listItem}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="detail-price">
                      <p className="view" onClick={() => openPopup(item)}>
                        View Details
                      </p>
                      <p className="start-from">
                        +Rp {formatAmount(item.price)} <span>msrp</span>{" "}
                      </p>
                    </div>
                    <button
                      className={`select-btn  ${
                        selectedTCare.some((item2) => item2.id === item.id)
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleClick(item)}
                    >
                      {selectedTCare.some((item2) => item2.id === item.id)
                        ? " Remove -"
                        : "Add +"}
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </AfterSalesWrapper>
  );
};

const AfterSalesWrapper = styled.div`
.variant-card{
    position:relative;
    padding:40px 0;
    .row{
        justify-content:center;
    }
}
.variant-inner {
    border-radius: 10px;
    border: 2px solid transparent;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(162, 163, 165, 0.60);
    padding: 20px 20px 80px 20px;
    height: 100%;
    transition: all 0.4s;
    position: relative;
    &.selected{
        border: 2px solid #D71921; 
    }
    .variant-top {
        p.numbers {
            color: #A2A3A5;
            font-size: 12px;
            text-align:right;
            margin-bottom:10px;
        }
    }
    .variant-img img {
        height:200px
        object-fit: cover;
        margin:30px 0;
    }
    .variant-info {
        p.name {
            color: #161A1D;
            font-size: 28px;
            margin: 0;
        }
        .detail-price{
            display:flex;
            justify-content:space-between;
            align-items:center;
            padding:20px 0;
            flex-wrap:wrap;
            p.start-from {
                color: #161A1D;
                font-size: 16px;
                margin-bottom: 0;
                display:flex;
                align-items:center;
                span {
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 12px;
                    display:inline-block;
                    margin-left:2px;
                }
            }
            p.view {
                color: #D71921;
                font-size: 16px;
                text-decoration: none;
                display: inline-block;
                text-transform: capitalize;
                border-bottom:1px solid transparent;
                margin-bottom:0;
                cursor:pointer;
                &:hover{
                    border-bottom:1px solid #D71921;
                }
            }
        }
        .usp-list{
            ul{
                padding-top:20px;
                li{
                    color: #161A1D;
                    font-size: 16px;
                    padding: 0px 10px 0px 20px;
                    width: 100%;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 5px;
                    &:before {
                        content: "";
                        color: #D71921;
                        position: absolute;
                        left: 4px;
                        top: 10px;
                        font-size: 24px;
                        width: 5px;
                        height: 5px;
                        background: #D71921;
                        border-radius: 50%;
                    }
                }
            }
        }
        .select-btn {
            border-radius: 4px;
            border: 1px solid #161A1D;
            background: #FFF;
            padding: 12px 12px 12px 30px;
            display: block;
            text-transform: capitalize;
            width: 115px;
            transition: width ease 0.7s;
            position: relative;
            display: flex;
            align-items: center;
            bottom: 30px;
            position: absolute;
            &.active{
                width:150px;
                color:#fff;
                background: linear-gradient(0deg, #D71921 0%, #D71921 100%), #FFF;
                 border-color:#D71921;
                svg{
                    margin-left:8px;
                }
            }
        }
    }
}
.config-popup{
    .variant-inner{
        padding:0;
        .variant-img {
            img{
                object-fit:contain;
            }
        }
        .variant-info{
            h4{
                font-size:24px;
                padding-top:30px;
            }
        }
        .select-btn {
            border-radius: 4px;
            border: 1px solid #161A1D;
            background: #FFF;
            padding: 12px 12px 12px 30px;
            display: block;
            text-transform: capitalize;
            width: 115px;
            transition: width ease 0.7s;
            position: relative;
            display: flex;
            align-items: center;
            bottom: 0;
            position: unset;
            &.active{
                width:150px;
                color:#fff;
                background: linear-gradient(0deg, #D71921 0%, #D71921 100%), #FFF;
                 border-color:#D71921;
                svg{
                    margin-left:8px;
                }
            }
        }
    }
    .start-from{
        text-align:right;
        color: #161A1D;
        font-size: 24px !important;
        margin:0;
        display:block !important;
        span{
            display:block !important;
            font-size:16px !important;
        }
    }
    h3 {
        color: #161A1D;
        font-size: 18px;
        border-bottom: 1px solid #161A1D;
        padding-bottom: 15px;
        display: block;
        margin-bottom:30px;
    }
    .usp-list ul{
        margin-bottom:30px;
    }
}
.carousel {
    .slide{
        padding:0 42px;
        text-align:left;
    }
    .control-dots{
        display:none;
    }
    .control-prev, .control-next {
        font-size: 20px;
        color: #3498db;
      }
      .thumbs-wrapper {
        display: none; 
      }
      .carousel-status{
        display:none;
      }
      .control-arrow{
        opacity: 1;
      }
      .control-prev:before, .control-next:before{
        display:none;
      }
      .control-arrow {
        display: inline-block;
        width: 35px;
        height: 35px;
        background: url(${RightArrow}) no-repeat center center !important;
        padding: 10px;
        border-radius: 50%;
        background-color: #161A1D !important;
        border: none;
        background-size: 10px;
        background-position: center center;
        position:absolute;
        top:40%;
        border:1px solid #161A1D;
      }
      .control-prev.control-arrow {
        background: url(${RightArrow}) no-repeat center center !important;
        transform:rotate(180deg);
        background-color: #161A1D !important;
        background-size: 10px;
        background-position: center center;
      }
      .control-disabled.control-arrow {
        cursor: inherit;
        display: block;
      }
      &.carousel-slider .control-arrow:hover{
        background-color:#161A1D;
      }
}

`;

export default AfterSales;
