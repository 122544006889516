import styled from 'styled-components';
export const Category = styled.div`
&>.container {
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
  }
  
    padding: 60px 0;
    .category-card{
        position:relative;
        .category-card-inner > a{
           & > img{
            height: 190px;
            object-fit: contain;
            width: 100%;
            transition: 0.5s;
            &:hover{
                transform: scale(1.2);
            }
           }
        }
    }
    .category-tabs {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    //overflow-x: auto;
    @media screen and (max-width:767px){
        justify-content:flex-start;
        max-width:97%;
    }
    button {
        width: 14%;
        border: 0;
        background: transparent;
        padding: 19px 10px;
        color: #161A1D;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 1.429px;
        border-bottom:1px solid #161a1d;
        white-space:nowrap;
        &.active{
            border-bottom: 4px solid #EB0A1E;
            font-weight: 600;
        }
        &:hover{
            font-weight: 600;
        }
        @media screen and (max-width:991px){
            width:auto;
        }
    }
}

    .category-card-detail{
        padding: 20px 0;
        h3 {
        font-size: 18px;
        font-weight: 600;
        }
        p {
        font-size: 16px;
        margin-bottom: 10px;
        }
        a {
            color: #161A1D;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            text-decoration: none;
            display: inline-block;
            &:hover{
                color: #eb0a1e;
                svg path{
                    fill:#EB0A1E;
                }
            }
            svg {
                margin-left: 8px;
            }
        }
    }
        
    .category-card-inner {
    margin-bottom: 40px;
    }

    .category-container{
        position:relative;
        .next-button {
            position: absolute;
            top: 33%;
            right: 0;
            background: linear-gradient(270deg, #FFF 30%, rgba(255, 255, 255, 0.00) 100%);
            transform: translateY(-50%);
            width: 67px;
            height: 50px;
            line-height: 50px;
            text-align: right;
            padding-right: 21px;
            &:hover{
                svg{
                    path{
                        fill:#D71921;
                    }
                }
            }
        }
    }
    
`