import React from "react";

export const Whatsapp = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#161A1D" />
                <path d="M8 32.25L9.687 26.087C8.646 24.283 8.099 22.238 8.1 20.141C8.103 13.585 13.438 8.25 19.993 8.25C23.174 8.251 26.16 9.49 28.406 11.738C30.651 13.986 31.887 16.974 31.886 20.152C31.883 26.709 26.548 32.044 19.993 32.044C18.003 32.043 16.042 31.544 14.305 30.596L8 32.25ZM14.597 28.443C16.273 29.438 17.873 30.034 19.989 30.035C25.437 30.035 29.875 25.601 29.878 20.15C29.88 14.688 25.463 10.26 19.997 10.258C14.545 10.258 10.11 14.692 10.108 20.142C10.107 22.367 10.759 24.033 11.854 25.776L10.855 29.424L14.597 28.443ZM25.984 22.979C25.91 22.855 25.712 22.781 25.414 22.632C25.117 22.483 23.656 21.764 23.383 21.665C23.111 21.566 22.913 21.516 22.714 21.814C22.516 22.111 21.946 22.781 21.773 22.979C21.6 23.177 21.426 23.202 21.129 23.053C20.832 22.904 19.874 22.591 18.739 21.578C17.856 20.79 17.259 19.817 17.086 19.519C16.913 19.222 17.068 19.061 17.216 18.913C17.35 18.78 17.513 18.566 17.662 18.392C17.813 18.22 17.862 18.096 17.962 17.897C18.061 17.699 18.012 17.525 17.937 17.376C17.862 17.228 17.268 15.765 17.021 15.17C16.779 14.591 16.534 14.669 16.352 14.66L15.782 14.65C15.584 14.65 15.262 14.724 14.99 15.022C14.718 15.32 13.95 16.038 13.95 17.501C13.95 18.964 15.015 20.377 15.163 20.575C15.312 20.773 17.258 23.775 20.239 25.062C20.948 25.368 21.502 25.551 21.933 25.688C22.645 25.914 23.293 25.882 23.805 25.806C24.376 25.721 25.563 25.087 25.811 24.393C26.059 23.698 26.059 23.103 25.984 22.979Z" fill="#161A1D" />
            </svg>
        </>
    );
};