import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import DefaultConfig from '../../../Utils/Config';
import { Location } from '../../../Assets/location';
import { Dealer } from '../../../Assets/dealer';
import { CaretDown } from '../../../Assets/caret-down';

const CustomDropdown = ({ selectedDealer, handleDealerClick, isDealerOpen, setIsDealerOpen, handleItemClick, selectedLocation, isLocationOpen, setIsLocationOpen, dealerData, cityData }) => {
    //const [isLocationOpen, setIsLocationOpen] = useState(false);
    //const [isDealerOpen, setIsDealerOpen] = useState(false);
    //const [selectedDealer, setSelectedDealer] = useState(null);
    //const [selectedLocation, setSelectedLocation] = useState(null);
    //const locations = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
    //const dealers = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
    //const [dealerData, setDealerData] = useState([]);
    //const [cityData, setCityData] = useState([]);

    const toggleDropdown = () => {
        setIsLocationOpen(!isLocationOpen);
    };

    /*const handleItemClick = (item) => {
        setSelectedLocation(item);
        setIsLocationOpen(false);
    };*/

    const toggleDealerDropdown = () => {
        setIsDealerOpen(!isDealerOpen);
    };

    /*const handleDealerClick = (item) => {
        //setSelectedDealer(item);
        //setIsDealerOpen(false);
    };*/

    /* useEffect(() => {
         const fetchData = async () => {
             try {
                 const response = await axios.get(`${DefaultConfig.API_URL}/dealer`, {
                     headers: {
                         'Authorization': `Bearer ${DefaultConfig.API_TOKEN}`,
                     },
                 });
 
                 setDealerData(response.data);
 
             } catch (error) {
                 console.error(error);
 
             }
         };
 
         fetchData();
 
     }, []);
 
     useEffect(() => {
         const fetchData = async () => {
             try {
                 const response = await axios.get(`${DefaultConfig.API_URL}/city`, {
                     headers: {
                         'Authorization': `Bearer ${DefaultConfig.API_TOKEN}`,
                     },
                 });
 
                 setCityData(response.data);
 
             } catch (error) {
                 console.error(error);
 
             }
         };
 
         fetchData();
 
     }, []);*/

    return (
        <SelectBoxes>
            <div className='select-wrap'>
                {cityData?.data?.length > 0 ? <div className='select-city'>
                    <div className="custom-dropdown">
                        <div className={`dropdown-header ${isLocationOpen ? 'open' : ''}`} onClick={toggleDropdown}>
                            <Location /> {selectedLocation?.name || 'Selected City'}
                            <span className='select-arrow'><CaretDown /></span>
                        </div>
                        {isLocationOpen && (
                            <ul className="dropdown-list">
                                {cityData?.data?.map((item, index) => (
                                    <li key={index} onClick={() => handleItemClick(item)}>
                                        {item?.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div> : null}
                {dealerData?.data?.length > 0 ? <div className='select-dealer'>
                    <div className="custom-dropdown">
                        <div className={`dropdown-header ${isDealerOpen ? 'open' : ''}`} onClick={toggleDealerDropdown}>
                            <Dealer /> {selectedDealer?.name || 'Selected Dealer'}
                            <span className='select-arrow'><CaretDown /></span>
                        </div>
                        {isDealerOpen && (
                            <ul className="dropdown-list">
                                {dealerData?.data?.map((item, index) => (
                                    <li key={index} onClick={() => handleDealerClick(item)}>
                                        {item?.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div> : null}
            </div>
        </SelectBoxes>

    );
};

// Styled components
const SelectBoxes = styled.div`
.select-wrap {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    @media screen and (max-width:767px){
        flex-direction:column;
        align-items:center;
    }
    .select-city {
        margin-right: 15px;
        @media screen and (max-width:767px){
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
    & > div{
        max-width:320px;
        width:100%;
    }
    .custom-dropdown {
        position: relative;
    }
    .dropdown-header{
        border-radius: 10px;
        border: 1px solid #161A1D;
        background: #FFF;
        padding:10px 15px;
        width:100%;
        position:relative;
        cursor:pointer;
        &>svg {
            margin-right: 10px;
            margin-bottom: 5px;
            width:20px;
        }
        &.open {
            border-radius: 10px 10px 0 0;
            .select-arrow svg {
                transform: rotate(180deg);
            }
        }
        &:hover{
            color:#EB0A1E;
            border-color:#EB0A1E;
            svg path{
                fill:#EB0A1E;
            }
        }
        & .select-arrow{
            position:absolute;
            top:50%;
            right:15px;
            transform: translateY(-50%);
        }
    }
    .dropdown-list {
        border: 0px solid #161a1d;
        border-radius: 0 0 10px 10px;
        padding: 10px 0;
        margin-top: 0;
        box-shadow: 0 0 20px 0px #ddd;
        position: absolute;
        width: 100%;
        z-index: 9;
        background: #fff;
        max-height: 280px;
        overflow-y: auto;
        min-height: auto;
        li{
            padding:10px;
            cursor:pointer;
            &:hover{
                color:#D71921;
                background:#ECEDED;
            }
        }
        &::-webkit-scrollbar {
        width: 5px;
        border-radius:20px;
        }
        &::-webkit-scrollbar-track {
        background: transparent; 
        }
        &::-webkit-scrollbar-thumb {
        background: #ECEDED; 
        }
}
}
`;

export default CustomDropdown;
