import React from "react";

export const TopArrow = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
            <path d="M9.44141 6.16797L5.36641 2.0013L1.10807 6.03464" stroke="#161A1D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        </>
    );
};