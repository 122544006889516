import React from "react";
import styled from "styled-components";
import Times from "../../Assets/times";

const Popup = ({ children, isOpen, onClose, popupContentStyle, closeButtonStyle, customClass}) => {

    const handleClose = () => {
        onClose();
    };

    return (
        <>
            {isOpen && (
                <PopupWrapper className={`popup ${customClass}`}>
                    <div className="popup-content" style={popupContentStyle}>
                        <button  style={closeButtonStyle} className="close-btn" onClick={handleClose}>
                            {/*<img src={timesIcon} alt="close"/>*/}
                            <span><Times /></span>
                        </button>
                        <div className="popup-content-inner">
                            {children}
                        </div>
                    </div>
                </PopupWrapper>
            )}
        </>
    );
};
const PopupWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0 0 0 / 8%);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999998;
    height:100%;
    .popup-content {
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        overflow-y: scroll;
        @media screen and (max-width:991px){
            height:80% !important;
            max-width:94% !important;
            padding: 0px 10px 20px !important;
        }
        .popup-content-inner {
            display: block;
            overflow: hidden;
        }
        button.content-select {
            background-color: #eee;
            color: #333;
        }
        .close-btn{
            &:hover{
                svg path{
                    fill:#D71921;
                }
            }
            z-index:999999;
        }
        &::-webkit-scrollbar {
        width: 5px;
        border-radius:20px;
        }
        &::-webkit-scrollbar-track {
        background: transparent; 
        }
        &::-webkit-scrollbar-thumb {
        background: #ECEDED; 
        }
        @media screen and (max-width:575px){
            max-width:94% !important;
            margin:0 auto;
        }
    }
}
`;

export default Popup;