import React, { useState, useEffect } from "react";
import axios from "axios";
import DefaultConfig from "../../../Utils/Config";
import styled from "styled-components";
import Variants from "./Variants";
import Configuration from "./Configuration";
import Transmission from "./Transmission";
import Colors from "./Colors";
import Accessory from "./Accessory";
import PriceCalculation from "./PriceCalculation";
import Summary from "./Summary";
import AfterSales from "./AfterSales";
import { useSelectedProducts } from "../../../Utils/Global";
import Popup from "../../../Components/Popup";
import Logo from "../../../Assets/logo.png";
import { Location } from "../../../Assets/location";
import { CaretDown } from "../../../Assets/caret-down";
import { Dealer } from "../../../Assets/dealer";

const MultiStepTabs = ({
  product,
  activeStepTab,
  handleStepTab,
  handleSelectedVariant,
  selectedVariant,
  selectedConfig,
  handleSelectConfig,
  selectedTransmission,
  handleSelectTransmission,
  handleSelectColor,
  selectedColor,
  selectedPackages,
  handleSelectPackage,
  selectedCustoms,
  handleSelectCustom,
  CookieCity,
  CookieDealer,
  formData,
  setFormData,
  handleSelectAfterSales,
  hasConfiguration,
  hasAftersale,
  selectedTCare,
}) => {
  // const [activeTab, setActiveTab] = useState(1);

  const { getProductById, addProduct, removeProduct, updateProduct } =
    useSelectedProducts();

  const [proceedToSummary, setProceedToSummary] = useState(false);
  const [triggerFormVerification, setTriggerFormVerification] = useState(false);

  const CookieProduct = getProductById(product?.id);

  const currentTimestamp = new Date().toISOString();

  const tabs = [
    "Variants",
    "Configuration",
    "Transmission",
    "Colors",
    "Accessories",
    "AfterSales",
    "Price Calculation",
    "Summary",
  ];

  // const handleTabClick = (tabNumber) => {
  //     setActiveTab(tabNumber);
  // };

  // handler if dealer is not selected
  const { CreateCookie } = useSelectedProducts();

  //city-dealer popup
  const [isOpen, setIsOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isDealerOpen, setIsDealerOpen] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState(CookieDealer);
  const [selectedLocation, setSelectedLocation] = useState(CookieCity);
  const [dealerData, setDealerData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [isDpopupOpen, setIsDpopupOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${DefaultConfig.API_URL}/dealer`, {
          headers: {
            Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
          },
        });
        setDealerData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${DefaultConfig.API_URL}/city`, {
          headers: {
            Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
          },
        });

        setCityData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const toggleDropdown = () => {
    setIsLocationOpen(!isLocationOpen);
    setIsDealerOpen(false);
  };

  const handleItemClick = (item) => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${DefaultConfig.API_URL}/city/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${DefaultConfig.API_TOKEN}`,
            },
          }
        );
        if (!CookieDealer || !CookieCity) {
          setIsOpen(true);
        }
        setDealerData({ data: response.data.data.dealers });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();

    setSelectedLocation(item);
    CreateCookie("city", item);
    setIsLocationOpen(false);
  };

  const toggleDealerDropdown = () => {
    setIsDealerOpen(!isDealerOpen);
  };

  const handleDealerClick = (item) => {
    setSelectedDealer(item);
    CreateCookie("dealer", item);
    setIsDealerOpen(false);
  };

  const DpopupOpen = () => {
    setIsDpopupOpen(true);
  };

  const handleNext = () => {
    if (!CookieDealer) {
      alert("Please select your dealer");
      // show dealer popup
      return (
        <DealerPopup>
          {isDpopupOpen && (
            <Popup
              isOpen={isDpopupOpen}
              onClose={() => setIsDpopupOpen(false)}
              popupContentStyle={{
                width: "100%",
                height: "auto",
                padding: "0 20px 20px",
                maxWidth: "450px",
                borderRadius: "10px",
                overflow: "auto",
              }}
              closeButtonStyle={{
                padding: "5px 10px",
                border: "none",
                borderRadius: "3px",
                background: "transparent",
                zIndex: "9",
                position: "relative",
                top: "0",
                right: "-15px",
                textAlign: "right",
                width: "100%",
              }}
            >
              <div className="home-popup">
                <img
                  src={Logo}
                  alt="Logo"
                  onClick={() => {
                    window.location.href = `${DefaultConfig.BASE_URL}`;
                  }}
                />
                <h2>SELECT YOUR NEAREST DEALER</h2>
                <SelectBoxes>
                  <div className="select-wrap">
                    {cityData?.data?.length > 0 && (
                      <div className="select-city">
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${
                              isLocationOpen ? "open" : ""
                            }`}
                            onClick={toggleDropdown}
                          >
                            <Location />{" "}
                            {selectedLocation?.name || "Selected City"}
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          {isLocationOpen && (
                            <ul className="dropdown-list">
                              {cityData?.data?.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={() => handleItemClick(item)}
                                >
                                  {item?.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                    {dealerData?.data?.length > 0 && (
                      <div className="select-dealer">
                        <div className="custom-dropdown">
                          <div
                            className={`dropdown-header ${
                              isDealerOpen ? "open" : ""
                            }`}
                            onClick={toggleDealerDropdown}
                          >
                            <Dealer />{" "}
                            {selectedDealer?.name || "Selected Dealer"}
                            <span className="select-arrow">
                              <CaretDown />
                            </span>
                          </div>
                          {isDealerOpen && (
                            <ul className="dropdown-list">
                              {dealerData?.data?.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={() => handleDealerClick(item)}
                                >
                                  {item?.name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </SelectBoxes>
              </div>
            </Popup>
          )}
        </DealerPopup>
      );
    } else {
      if (
        activeStepTab === 8 &&
        (!formData.budgetSimulation ||
          !formData.formName ||
          !formData.formPhone ||
          !formData.formAddress ||
          !formData.formEmail)
      ) {
        setTriggerFormVerification(true);
      } else {
        if (!CookieProduct || CookieProduct.length === 0) {
          addProduct({
            id: product.id,
            selectedVariant: selectedVariant.id,
            lastUpdated: currentTimestamp,
            completedTab: activeStepTab,
            nextTab: activeStepTab + 1,
          });
        } else {
          let additionalData = {
            selectedVariant: selectedVariant.id,
            lastUpdated: currentTimestamp,
          };
          let oldData = CookieProduct;
          Object.assign(oldData, additionalData);
          updateProduct(product.id, oldData);
        }
        if (activeStepTab < tabs?.length) {
          if (
            (!hasConfiguration && activeStepTab === 1) ||
            (!hasAftersale && activeStepTab === 5)
          ) {
            handleStepTab(activeStepTab + 2);
          } else {
            handleStepTab(activeStepTab + 1);
          }
        }
      }
    }
  };

  const handlePrev = () => {
    if (activeStepTab > 1) {
      if (
        (!hasConfiguration && activeStepTab === 3) ||
        (!hasAftersale && activeStepTab === 7)
      ) {
        handleStepTab(activeStepTab - 2);
      } else {
        handleStepTab(activeStepTab - 1);
      }
    }
  };

  const renderTabContent = () => {
    switch (activeStepTab) {
      case 1:
        return (
          <Variants
            product={product}
            handleSelectedVariant={handleSelectedVariant}
            selectedVariant={selectedVariant}
            CookieDealer={CookieDealer}
          />
        );
      case 2:
        return (
          <Configuration
            product={product}
            selectedVariant={selectedVariant}
            selectedConfig={selectedConfig}
            handleSelectConfig={handleSelectConfig}
          />
        );
      case 3:
        return (
          <Transmission
            product={product}
            selectedVariant={selectedVariant}
            selectedConfig={selectedConfig}
            selectedTransmission={selectedTransmission}
            handleSelectTransmission={handleSelectTransmission}
          />
        );
      case 4:
        return (
          <Colors
            handleSelectColor={handleSelectColor}
            selectedVariant={selectedVariant}
            selectedColor={selectedColor}
            product={product}
          />
        );
      case 5:
        return (
          <Accessory
            selectedVariant={selectedVariant}
            selectedColor={selectedColor}
            product={product}
            selectedPackages={selectedPackages}
            handleSelectPackage={handleSelectPackage}
            selectedCustoms={selectedCustoms}
            handleSelectCustom={handleSelectCustom}
          />
        );
      case 6:
        return (
          <AfterSales
            handleSelectAfterSales={handleSelectAfterSales}
            product={product}
            selectedVariant={selectedVariant}
            selectedTCare={selectedTCare}
          />
        );
      case 7:
        return (
          <PriceCalculation
            product={product}
            selectedVariant={selectedVariant}
            CookieCity={CookieCity}
            CookieDealer={CookieDealer}
            selectedConfig={selectedConfig}
            selectedTransmission={selectedTransmission}
            selectedColor={selectedColor}
            selectedPackages={selectedPackages}
            selectedCustoms={selectedCustoms}
            activeStepTab={activeStepTab}
            triggerFormVerification={triggerFormVerification}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 8:
        return <Summary activeStepTab={activeStepTab} />;
      default:
        return null;
    }
  };

  return (
    <ProductTab>
      <div className="multistep-sec">
        <div className="product-tabs">
          {tabs?.map((tabText, index) =>
            (tabText !== "Configuration" && tabText !== "AfterSales") ||
            (tabText === "Configuration" && hasConfiguration) ||
            (tabText === "AfterSales" && hasAftersale) ? (
              <div
                key={index + 1}
                className={`tab 
                ${activeStepTab === index + 1 ? "active" : ""} 
                ${activeStepTab > index + 1 ? "visited" : ""} 
                ${
                  (activeStepTab === 1 && index === 2) ||
                  activeStepTab === index
                    ? "next"
                    : ""
                }`}
              >
                {tabText}
              </div>
            ) : null
          )}
        </div>
        <div className="product-tab-content">{renderTabContent()}</div>
        {activeStepTab !== 8 ? (
          <div className="buttons">
            <button
              className="prev"
              onClick={handlePrev}
              disabled={activeStepTab === 1}
            >
              Previous Step
            </button>
            <button
              className="next"
              onClick={handleNext}
              disabled={
                (activeStepTab === 1 && selectedVariant.length === 0) ||
                (activeStepTab === 4 && selectedColor.length === 0)
              }
            >
              Next Step
            </button>
          </div>
        ) : null}
      </div>
    </ProductTab>
  );
};

const ProductTab = styled.div`
  .multistep-sec {
    padding-bottom: 60px;
    .product-tabs {
      display: flex;
      justify-content: center;
      overflow-x: hidden;
      white-space: nowrap;
      @media screen and (max-width: 991px) {
        justify-content: flex-start;
      }
      .tab {
        border-radius: 5px;
        background: #d9dadb;
        padding: 16px 32px;
        margin: 0 5px 10px;
        color: #fff;
        text-align: center;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        border: 2px solid #d9dadb;
        @media screen and (max-width: 850px) {
          display: none;
        }
        &.active,
        &.next,
        &.visited {
          @media screen and (max-width: 850px) {
            display: block;
            flex: 1;
          }
          @media screen and (max-width: 850px) {
            display: block;
          }
        }
        &.visited {
          @media screen and (max-width: 740px) {
            display: none;
          }
        }
        @media screen and (max-width: 1199px) {
          padding: 10px 5px;
          font-size: 14px;
        }
        &:last-child {
          background: #d71921;
          border: 2px solid #d71921;
        }
        &.active {
          background: #fff;
          border: 2px solid #d71921;
          color: #161a1d;
        }
        &.visited {
          background: #161a1d;
          border: 2px solid #161a1d;
          color: #fff;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: center;
      button {
        border-radius: 4px;
        padding: 14px 32px;
        margin: 0 5px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        border: 0;
      }
      .prev {
        background: #fff;
        color: #161a1d;
        transition: 0.5s;
        &:hover {
          background: #f7d1d3;
          color: #d71921;
        }
      }
      .next {
        background: #161a1d;
        color: #fff;
        transition: 0.5s;
        &:hover {
          background: #d71921;
        }
      }
    }
    .product-tab-content {
    }
  }
`;

const DealerPopup = styled.div`
  text-align: center;
  h2 {
    color: #161a1d;
    font-size: 24px;
    margin: 40px 0;
    @media screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
  .popup {
    background: rgba(0, 0, 0, 0.8) !important;
    .popup-content {
      overflow: visible !important;
      @media screen and (max-width: 991px) {
        height: auto !important;
      }
      .popup-content-inner {
        overflow: visible !important;
      }
    }
  }
`;

const SelectBoxes = styled.div`
  .select-wrap {
    display: block;
    .select-city {
      margin-right: auto;
    }
    & > div {
      max-width: 320px;
      width: 100%;
      margin: 10px auto;
    }
    .custom-dropdown {
      position: relative;
    }
    .dropdown-header {
      border-radius: 10px;
      border: 1px solid #737677;
      background: #fff;
      padding: 10px 15px;
      width: 100%;
      position: relative;
      cursor: pointer;
      text-align: left;
      color: #a2a3a5;
      & > svg {
        margin-right: 10px;
        margin-bottom: 5px;
        width: 20px;
        path {
          fill: #a2a3a5;
        }
      }
      &.open {
        border-radius: 10px 10px 0 0;
        .select-arrow svg {
          transform: rotate(180deg);
        }
      }
      &:hover {
        color: #eb0a1e;
        border-color: #eb0a1e;
        svg path {
          fill: #eb0a1e;
        }
      }
      & .select-arrow {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        svg {
          path {
            fill: #a2a3a5;
          }
        }
      }
    }
    .dropdown-list {
      border: 0px solid #161a1d;
      border-radius: 0 0 10px 10px;
      padding: 10px 0;
      margin-top: 0;
      box-shadow: 0 0 5px 0px #ddd;
      position: absolute;
      width: 100%;
      z-index: 9;
      background: #fff;
      max-height: 180px;
      overflow-y: auto;
      min-height: auto;
      li {
        padding: 10px;
        cursor: pointer;
        text-align: left;
        &:hover {
          color: #d71921;
          background: #eceded;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #eceded;
      }
    }
  }
`;
export default MultiStepTabs;
